import React from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  TimePicker,
  Checkbox,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { valueType } from "antd/lib/statistic/utils";
import { Moment } from "moment";
import { FullInputNumber } from "../components/StyledComponents";
import {
  educationOptions,
  expenses,
  ExpenseType,
  finances,
  homeServices,
  HomeServiceType,
  houseAmenities,
  HouseAmenityType,
  houseMaterialOptions,
  housingRightsOptions,
  housingTypeOptions,
  locationTypeOptions,
  medStartOptions,
  numRoomsOptions,
  occupationCategoryOptions,
  peoplePerRoomOptions,
  prepInitialValues,
  publicServices,
  PublicServicetype,
  sickFamilyOptions,
  submitSWForm,
  SWFormType,
  SexOptions,
  fatherEducationOptions,
  motherEducationOptions,
} from "../util/SWFormUtil";
import { useEffect } from "react";
import GeneralInfo from "../components/GeneralInfo";
import CenterSpinner from "../components/CenterSpinner";
import { FormByIdParams, getFormById, PrevDoctor } from "../util/misc";
import FormSkeleton from "../components/FormSkeleton";
import { OptionsField } from "../components/OptionsField";

const SWFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [hasTempAddr, setHasTempAddr] = useState(false);
  const [hasAdditionalProblem, setHasAdditionalProblems] = useState(false);
  const [hasSickFamily, setHasSickFamily] = useState("");
  const [isRightholderOrBen, setisRightholderOrBen] = useState(false);
  const [totalMonthlySalary, setTotalMonthlySalary] = useState(0);
  const [hasPhysicalCogLimits, setHasPhysicalCogLimits] = useState(false);
  const formName = "SWForm"

  const [form] = Form.useForm();
  const [livesRuralPermanent, setLivesRuralPermanent] = useState(
    !!form.getFieldsValue(["permanentSuburbOrRural"])["permanentSuburbOrRural"]
  );
  const [livesRuralTemp, setLivesRuralTemp] = useState(
    !!form.getFieldsValue(["tempSuburbOrRural"])["tempSuburbOrRural"]
  );

  useEffect(() => {
    setLivesRuralPermanent(
      !!form.getFieldsValue(["permanentSuburbOrRural"])[
        "permanentSuburbOrRural"
      ]
    );
    setLivesRuralTemp(
      !!form.getFieldsValue(["tempSuburbOrRural"])["tempSuburbOrRural"]
    );
  }, [setLivesRuralPermanent, setLivesRuralTemp, form]);

  const [totalExpenses, setTotalExpenses] = useState(0);
  const history = useHistory();

  const handleExpenseChange = (e: valueType, expense: ExpenseType) => {
    const value = e ? (e as number) : 0;
    expenses[expense] = value;
    const total = Object.values(expenses).reduce(
      (prev, curr) => prev + curr,
      0
    );
    setTotalExpenses(total);
    form.setFieldsValue({
      totalMonthlyExpenses: total,
      deficit: total - totalMonthlySalary,
      surplus: totalMonthlySalary - total,
      costIncomePerc: totalMonthlySalary
        ? parseFloat(((total / totalMonthlySalary) * 100).toFixed(2))
        : 0,
    });
  };

  const updateFinances = (e: valueType, idx: number) => {
    finances[idx].monthlySalary = e as number;
  };

  const handleIncomeChange = () => {
    const total = finances
      .map((row) => row.monthlySalary)
      .reduce((prev, curr) => prev + curr, 0);
    setTotalMonthlySalary(total);
    form.setFieldsValue({
      totalMonthlyIncome: total,
      deficit: totalExpenses - total,
      surplus: total - totalExpenses,
      costIncomePerc: total
        ? parseFloat(((totalExpenses / total) * 100).toFixed(2))
        : 0,
    });
  };

  const onFinish = async (values: SWFormType) => {
    if (isSubmitting) return
    else setIsSubmitting(true)
    setIsLoading(true);
    console.log('values',values)
    const newValues: SWFormType = {
      ...values,
      foodExp: values.foodExp ? values.foodExp : 0,
      gasExp: values.gasExp ? values.gasExp : 0,
      lightExp: values.lightExp ? values.lightExp : 0,
      phoneExp: values.phoneExp ? values.phoneExp : 0,
      schoolExp: values.schoolExp ? values.schoolExp : 0,
      domesticExp: values.domesticExp ? values.domesticExp : 0,
      additionalExp: values.additionalExp ? values.additionalExp : 0,
      startTime: values.startTime ? (values.startTime as Moment).format("HH:mm") : null,
      endTime: values.endTime ? (values.endTime as Moment).format("HH:mm") : null,
      date: values.date ? (values.date as Moment)?.format("YYYY-MM-DD") : null,
      patientId: patientId,
      formId: formId,
    };

    let placeholder: any = {};
    if (values.houseAmenities)
      (values.houseAmenities as HouseAmenityType[]).forEach(
        (amenityK) => (placeholder[amenityK] = true)
      );
    newValues.houseAmenities = placeholder;

    placeholder = {};
    if (values.homeServices)
      (values.homeServices as HomeServiceType[]).forEach(
        (homeServiceK) => (placeholder[homeServiceK] = true)
      );
    newValues.homeServices = placeholder;

    placeholder = {};
    if (values.publicServices)
      (values.publicServices as PublicServicetype[]).forEach(
        (publicServiceK) => (placeholder[publicServiceK] = true)
      );
    newValues.publicServices = placeholder;

    console.log(JSON.stringify(newValues));
    await submitSWForm(newValues);
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data["form"]) {
        setIsLoading(false);
        return;
      }
      const values = data.form;
      const prevDoctorValues = data.prev_doctor;
      setPrevDoctor(prevDoctorValues);
      const newValues = await prepInitialValues(values);
      setInitialValues(newValues);
      newValues.familyFinanceForm?.forEach((finance) => {
        if ("relationship" in finance) {
          finances.push(finance);
        }
      });
      setIsLoading(false);
      handleIncomeChange();
      form.resetFields(Object.keys(newValues));
    })();
  }, []);

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form<SWFormType>
        form={form}
        className="data-form"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Formulario de Trabajador Social"
        />
        <Form.Item
          key="Service"
          name="service"
          label="SERVICIO"
          rules={[{ required: false , message: "Requerido" }]} //required
        >
          <Input type="text" />
        </Form.Item>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Form.Item
              key="startTime"
              name="startTime"
              label="Hora de inicio"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <TimePicker
                style={{ width: "100%" }}
                use12Hours
                format="h:mm A"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="endTime"
              name="endTime"
              label="Hora de termino"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <TimePicker
                style={{ width: "100%" }}
                use12Hours
                format="h:mm A"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Form.Item
            key="collectionDate"
            name="collectionDate"
            label="Fecha"
            rules={[{ required: false, message: "Requerido" }]} //required
          >
            <DatePicker />
          </Form.Item>
        </Row>
        <Form.Item
          key="refInstitution"
          name="refInstitution"
          label="Institución que lo refirió"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input type="text" />
        </Form.Item>
        <Row justify="center">
          <Divider orientation="left">
            Datos del informante.
          </Divider>
        </Row>

        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Form.Item
              key="sourceName"
              name="sourceName"
              label="Nombre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input type="text" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="sourceRelationship"
              name="sourceRelationship"
              label="Parentesco con el paciente."
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input type="text" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="center">DATOS GENERALES DEL PACIENTE</Divider>

        <Form.Item
          key="age"
          name="age"
          label="Edad"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <FullInputNumber />
        </Form.Item>

        <Divider orientation="left">Lugar de Nacimiento</Divider>
        <Form.Item
          key="birthPlace"
          name="birthPlace"
          label="Delegación o Municipio"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="state"
          name="state"
          label="Estado"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>

        <Divider />

        <OptionsField formOptions={SexOptions} presentation="radio" required={false}/> {/* required */}

        <Form.Item
          key="nationality"
          name="nationality"
          label="Nacionalidad"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="deliveryMethod"
          name="deliveryMethod"
          label="Parto atendido en"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>

        <OptionsField formOptions={educationOptions} presentation="select" required={false}/> {/* required */}

        <Form.Item
          key="isCivillyRegistered"
          name="isCivillyRegistered"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>Registrado civilmente</Checkbox>
        </Form.Item>

        <Form.Item
          key="language"
          name="language"
          label='Lengua(s)/idioma(s) *si hay varios idiomas, sepárelos con ","'
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>

        <Form.Item
          key="religion"
          name="religion"
          label="Religión"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>

        <Row justify="center">
          <Space direction="horizontal">
            <Form.Item
              key="fatherName"
              name="fatherName"
              label="Nombre del padre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="fatherSurnameFather"
              name="fatherSurnameFather"
              label="Primer apellido del padre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="fatherSurnameMother"
              name="fatherSurnameMother"
              label="Segundo apellido del padre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
          </Space>
        </Row>

        <OptionsField
          formOptions={fatherEducationOptions}
          presentation="select"
          required={false} 
        /> {/* required */}

        <Form.Item
          key="fatherCivilStatus"
          name="fatherCivilStatus"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>Registrado civilmente (padre)</Checkbox>
        </Form.Item>

        <Row justify="center">
          <Space direction="horizontal">
            <Form.Item
              key="motherName"
              name="motherName"
              label="Nombre del madre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="motherSurnameFather"
              name="motherSurnameFather"
              label="Primer apellido del madre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="motherSurnameMother"
              name="motherSurnameMother"
              label="Primer apellido del madre"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
          </Space>
        </Row>
        <OptionsField
          formOptions={motherEducationOptions}
          presentation="select"
          required={false}
        />  {/* required */}
        <Form.Item
          key="motherCivilStatus"
          name="motherCivilStatus"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>Registrado civilmente (madre)</Checkbox>
        </Form.Item>

        <Divider orientation="left">Domicilio Permanente</Divider>
        <Form.Item
          key="permanentSuburbOrRural"
          name="permanentSuburbOrRural"
          label="Colonia o ranchería"
        >
          <Input
            onChange={(event) => {
              if (event.target.value !== "") {
                setLivesRuralPermanent(true);
                return;
              }
              setLivesRuralPermanent(false);
            }}
          />
        </Form.Item>
        <Form.Item
          key="permanentStreet"
          name="permanentStreet"
          label="Calle"
          rules={[{ required: livesRuralPermanent, message: "Requerido" }]}
        >
          <Input disabled={!livesRuralPermanent} />
        </Form.Item>
        <Form.Item
          key="permanentNumExteriorInterior"
          name="permanentNumExteriorInterior"
          label="No. exterior/interior"
          rules={[{ required: livesRuralPermanent, message: "Requerido" }]}
        >
          <InputNumber disabled={!livesRuralPermanent} />
        </Form.Item>
        <Form.Item
          key="permanentPostalCode"
          name="permanentPostalCode"
          label="C.P."
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="permanentDelegationOrMunicipality"
          name="permanentDelegationOrMunicipality"
          label="Delegación o Municipio"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="permanentState"
          name="permanentState"
          label="Estado"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="permanentPersonalPhone"
          name="permanentPersonalPhone"
          label="Teléfono particular"
          rules={[{ required: false, message: "Requerido" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="permanentCellPhone"
          name="permanentCellPhone"
          label="Teléfono celular"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="permanentMessagePhone"
          name="permanentMessagePhone"
          label="Teléfono para recado"
          rules={[{ required: false, message: "Requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          initialValue={false}
        >
          <Checkbox
            checked={hasTempAddr}
            onChange={() => setHasTempAddr((old) => !old)}
          >
            ¿Tiene el paciente una dirección temporal?
          </Checkbox>
        </Form.Item>

        {hasTempAddr ? (
          <>
            <Divider orientation="left">Domicilio Temporal</Divider>
            <Form.Item
              key="tempSuburbOrRural"
              name="tempSuburbOrRural"
              label="Colonia o ranchería"
            >
              <Input
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setLivesRuralTemp(true);
                    return;
                  }
                  setLivesRuralTemp(false);
                }}
              />
            </Form.Item>
            <Form.Item
              key="tempStreet"
              name="tempStreet"
              label="Calle"
              rules={[{ required: livesRuralTemp, message: "Requerido" }]}
            >
              <Input disabled={!livesRuralTemp} />
            </Form.Item>
            <Form.Item
              key="tempNumExteriorInterior"
              name="tempNumExteriorInterior"
              label="No. exterior/interior"
              rules={[{ required: livesRuralTemp, message: "Requerido" }]}
            >
              <InputNumber disabled={!livesRuralTemp} />
            </Form.Item>
            <Form.Item
              key="tempPostalCode"
              name="tempPostalCode"
              label="C.P."
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="tempDelegationOrMunicipality"
              name="tempDelegationOrMunicipality"
              label="Delegación o Municipio"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="tempState"
              name="tempState"
              label="Estado"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Form.Item
              key="tempPhone"
              name="tempPhone"
              label="Teléfono"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <Input />
            </Form.Item>
            <Divider></Divider>
          </>
        ) : null}
        <Form.Item
          key="accommodatorNameAndRelationship"
          label="Nombre de quien brinda alojamiento y parentesco"
        >
          <Form.Item
            key="landlordName"
            name="landlordName"
            rules={[{ required: false, message: "Requerido" }]}
            style={{ display: "inline-block", width: "50%" }}
          >
            <Input placeholder="Nombre" />
          </Form.Item>
          <Form.Item
            key="landlordRelationship"
            name="landlordRelationship"
            rules={[{ required: false, message: "Requerido" }]}
            style={{ display: "inline-block", width: "50%" }}
          >
            <Input placeholder="Parentesco con el paciente." />
          </Form.Item>
        </Form.Item>

        <Form.Item
          key="medicalDiagnoses"
          name="medicalDiagnoses"
          label="Diagnóstico(s) médico(s) del paciente"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>

        <Row justify="center">
          <Col>
            <OptionsField formOptions={medStartOptions} presentation="radio" required={false}/>  {/* required */}
          </Col>
        </Row>

        <Form.Item
          key="hasOtherDiseasesAtOtherInstitutions"
          name="hasOtherDiseasesAtOtherInstitutions"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox
            checked={hasAdditionalProblem}
            onChange={() => setHasAdditionalProblems((old) => !old)}
          >
            ¿Además del problema de salud que presenta el paciente, ¿tiene otra
            enfermedad por la que se atienda en otra Institución?
          </Checkbox>
        </Form.Item>
        {hasAdditionalProblem ? (
          <Form.List name="additionalProblemsForm">
            {(fields, { add, remove }) => (
              <>
                <Row justify="center">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "illness"]}
                        fieldKey={[fieldKey, "illness"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input placeholder="¿Cuales?" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "location"]}
                        fieldKey={[fieldKey, "location"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input placeholder="¿Dónde se atiende?" />
                      </Form.Item>
                      <Button type="dashed" onClick={() => remove(name)}>
                        Retirar
                      </Button>
                    </Space>
                  ))}
                </Row>
                <Row justify="center">
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()}>
                      Agregar
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        ) : null}
          <OptionsField
            formOptions={sickFamilyOptions}
            presentation="radio"
            onChange={(e) => {
              setHasSickFamily(e!.target!.value);
            }}
            required={false}
          />  {/* required */}
        {hasSickFamily !== "none_sick" ? (
          <Form.List name="familySicknessForm">
            {(fields, { add, remove }) => (
              <>
                <Row justify="center">
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "relationship"]}
                        fieldKey={[fieldKey, "relationship"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input placeholder="Parentesco con el paciente." />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "diagnosis"]}
                        fieldKey={[fieldKey, "diagnosis"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input placeholder="Diagnóstico" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "placeOfTreatment"]}
                        fieldKey={[fieldKey, "placeOfTreatment"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input placeholder="Lugar de tratamiento" />
                      </Form.Item>
                      <Button type="dashed" onClick={() => remove(name)}>
                        Retirar
                      </Button>
                    </Space>
                  ))}
                </Row>
                <Row justify="center">
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()}>
                      Agregar
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        ) : null}
        <Form.Item
          key="rightHolderOrBeneficiary"
          name="rightHolderOrBeneficiary"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox
            checked={isRightholderOrBen}
            onChange={() => setisRightholderOrBen((old) => !old)}
          >
            Derechohabiente o beneficiario
          </Checkbox>
        </Form.Item>
        <Form.Item key="rightBenDetails" name="rightBenDetails">
          <Input placeholder="¿Cual?" />
        </Form.Item>
        <Divider orientation="center">
          CONDICIONES ECONOMICAS Y DE TRABAJO
        </Divider>
        <Form.List name="familyFinanceForm">
          {(fields, { add, remove }) => (
            <>
              <Row justify="center">
                {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                  return (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Nombre"
                          onChange={(e) =>
                            (finances[idx].name = e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "relationship"]}
                        fieldKey={[fieldKey, "relationship"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Parentesco con el paciente."
                          onChange={(e) =>
                            (finances[idx].relationship = e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "monthlySalary"]}
                        fieldKey={[fieldKey, "monthlySalary"]}
                        rules={[
                          {
                            required: false, //required
                            message: "Requerido",
                          },
                        ]}
                      >
                        <FullInputNumber
                          placeholder="Ingreso Familiar Mensual"
                          type="number"
                          onChange={(e) => {
                            updateFinances(e, idx);
                            handleIncomeChange();
                          }}
                        />
                      </Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          if (!finances[idx]) {
                            remove(name);
                            finances.splice(idx, 1);
                            return;
                          }
                          const val = finances[idx].monthlySalary;
                          setTotalMonthlySalary((prev) => {
                            form.setFieldsValue({
                              totalMonthlyIncome: prev - val,
                              deficit: totalExpenses - (prev - val),
                              surplus: prev - val - totalExpenses,
                              costIncomePerc:
                                prev - val
                                  ? parseFloat(
                                      (
                                        (totalExpenses / (prev - val)) *
                                        100
                                      ).toFixed(2)
                                    )
                                  : 0,
                            });
                            return prev - val;
                          });
                          console.log(finances);
                          remove(name);
                          finances.splice(idx, 1);
                        }}
                      >
                        Retirar
                      </Button>
                    </Space>
                  );
                })}
              </Row>
              <Row justify="center">
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                      finances.push({
                        name: "",
                        relationship: "",
                        monthlySalary: 0,
                      });
                    }}
                  >
                    Agregar
                  </Button>
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
        <Form.Item
          key="totalMonthlyIncome"
          name="totalMonthlyIncome"
          label="Total ingreso familiar mensual"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          key="numDependents"
          name="numDependents"
          label="No. de dependientes económicos"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <InputNumber />
        </Form.Item>

        <Divider orientation="left">
          Relación Ingreso-Número de Dependientes Económicos
        </Divider>
        <Form.Item
          key="mainEconProviderOccupation"
          name="mainEconProviderOccupation"
          label="Ocupación del principal proveedor económico"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Divider orientation="left">Trabajo</Divider>
        <Form.Item
          key="workplace"
          name="workplace"
          label="Lugar"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="workSchedule"
          name="workSchedule"
          label="Horarío"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="workPhone"
          name="workPhone"
          label="Teléfono"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="workYears"
          name="workYears"
          label="Antigüedad"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>

        <OptionsField
          formOptions={occupationCategoryOptions}
          presentation="select"
          required={false}
        /> {/* required */}

        <Divider orientation="left">Egreso Mensual</Divider>
        <Row justify="center" gutter={16}>
          <Col span={8}>
            <Form.Item
              key="foodExp"
              name="foodExp"
              label="Alimentación y despensa ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "foodExp")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="rentExp"
              name="rentExp"
              label="Renta, crédito hipotecario, predial ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "rentExp")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="waterExp"
              name="waterExp"
              label="Agua ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "waterExp")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={16}>
          <Col span={8}>
            <Form.Item
              key="lightExp"
              name="lightExp"
              label="Luz ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => {
                  handleExpenseChange(e, "lightExp");
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="gasExp"
              name="gasExp"
              label="Gas ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "gasExp")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="phoneExp"
              name="phoneExp"
              label="Teléfono (incluir teléfono móvil) ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "phoneExp")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={8}>
            <Form.Item
              key="transportExp"
              name="transportExp"
              label="Transporte o gasolina ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "transportExp")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="schoolExp"
              name="schoolExp"
              label="Gastos escolares ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "schoolExp")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="healthExp"
              name="healthExp"
              label="Gastos en salud ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "healthExp")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Form.Item
              key="domesticExp"
              name="domesticExp"
              label="Servicios domésticos ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "domesticExp")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="additionalExp"
              name="additionalExp"
              label="Gastos adicionales ($)"
              initialValue={0}
            >
              <FullInputNumber
                placeholder="$"
                onChange={(e) => handleExpenseChange(e, "additionalExp")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          key="totalMonthlyExpenses"
          name="totalMonthlyExpenses"
          label="Gastos mensuales totales ($)"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <InputNumber />
        </Form.Item>

        <Divider orientation="left">Situación Económica</Divider>
        <Row justify="center" gutter={16}>
          <Col span={8}>
            <Form.Item
              key="deficit"
              name="deficit"
              label="Déficit ($)"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="surplus"
              name="surplus"
              label="Superávit ($)"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              key="costIncomePerc"
              name="costIncomePerc"
              label="Equilibrio (%)"
              rules={[{ required: false, message: "Requerido" }]} //required
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="center">CONDICIONES DE LA VIVIENDA</Divider>

        <OptionsField
          formOptions={housingRightsOptions}
          presentation="select" required={false}
        /> {/* required */}

        <OptionsField formOptions={housingTypeOptions} presentation="select" required={false} /> {/* required */}
        <OptionsField formOptions={numRoomsOptions} presentation="radio" required={false} /> {/* required */}

        <Form.Item
          key="houseAmenities"
          name="houseAmenities"
          label="La vivienda dispone de"
        >
          <Checkbox.Group options={houseAmenities}></Checkbox.Group>
        </Form.Item>

        <OptionsField formOptions={peoplePerRoomOptions} presentation="radio" required={false} /> {/* required */}

        <Form.Item
          key="numFamiliesInProperty"
          name="numFamiliesInProperty"
          label="No. de familias que habitan el predio"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>

        <OptionsField formOptions={houseMaterialOptions} presentation="radio" required={false} /> {/* required */}

        <Form.Item
          key="wallMaterial"
          name="wallMaterial"
          label="Paredes"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="floorMaterial"
          name="floorMaterial"
          label="Piso"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <Input />
        </Form.Item>
        <Form.Item
          key="homeServices"
          name="homeServices"
          label="Servicios intradomiciliarios (para clasificación socioeconómica)"
        >
          <Checkbox.Group options={homeServices}></Checkbox.Group>
        </Form.Item>
        <Form.Item
          key="publicServices"
          name="publicServices"
          label="Servicios Públicos (para clasificación socioeconómica)"
        >
          <Checkbox.Group options={publicServices}></Checkbox.Group>
        </Form.Item>

        <OptionsField formOptions={locationTypeOptions} presentation="radio" required={false}/> {/* required */}

        <Form.Item
          key="physicalCogLimits"
          name="physicalCogLimits"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox
            checked={hasPhysicalCogLimits}
            onChange={() => setHasPhysicalCogLimits((old) => !old)}
          >
            El paciente o algún miembro de la familia presenta limitaciones
            fisicas o cognitivas?
          </Checkbox>
        </Form.Item>
        {hasPhysicalCogLimits ? (
          <Form.Item
            key="physicalCogLimitsDetails"
            name="physicalCogLimitsDetails"
            label="Cuáles?"
          >
            <Input />
          </Form.Item>
        ) : null}
        <Form.Item
          key="socialDiagnosis"
          name="socialDiagnosis"
          label="Diagnóstico Social Inicial"
          rules={[{ required: false, message: "Requerido" }]} //required
        >
          <TextArea style={{ height: "20vh" }} />
        </Form.Item>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  );
};

export default SWFormPage;
