import { Row } from "antd";
import heroImg from "../images/HIMFG.png"

export interface LoginForm {
  username: string;
  password: string;
  role: string;
}

const LoginPage = () => {

  return (
    <Row justify="center" align="middle">
      <div className="heroImg" >
        <img src={heroImg} alt="HIMFG" />
      </div>
    </Row>
  ) 
};

export default LoginPage;
