import { message } from "antd";

export const SUCCESS_MSGS = Object.freeze({
  LOGIN_SUCCESS: "Inicio de sesión exitoso",
  LOGOUT_SUCCESS: "Cierre de sesión exitoso",
  NEW_PATIENT_SUCCESS: "Nuevo paciente registrado con éxito",
  NEW_STAFF_SUCCESS:
    "Nuevo personal creado con éxito; deben configurar su cuenta",
  FORM_SUBMITTED_SUCCESS: "Formulario enviado con éxito",
  CONFIGURE_SUCCESS:
    "Cuenta configurada; ingrese su clave de confirmación enviada a su correo electrónico",
  PW_RESET_SUCCESS:
    "Contraseña restablecida correctamente. Por favor Iniciar sesión.",
  PW_RESET_REQUEST_SUCCESS:
    "Email con el enlace de restablecimiento de contraseña enviado",
  CONFIGURE_SENT_SUCCESS: "Email de Confirmación Mandado",
});

export const FAILURE_MSGS: Record<string, string> = Object.freeze({
  NEW_STAFF_FAILURE: "No se pudo crear personal nuevo",
  CONFIGURE_FAILED:
    "No se pudo configurar la cuenta; contactar a un administrador",
  ACCOUNT_ALREADY_EXISTS: "La cuenta ya existe",
  TOKEN_EXPIRED: "Inicia sesión de nuevo",
  LOGIN_FAILURE: "Error de inicio de sesion",
  NEW_PATIENT_FAILURE: "No se pudo crear un nuevo paciente",
  FORM_SUBMIT_FAILED: "Hubo un problema enviando el formulario",
  ACCOUNT_NOT_CONFIRMED: "Aún no confirmado",
  DB_ERROR: "Hubo un problema con la base de datos",
  WRONG_LOGIN_CREDENTIALS: "Usuario / contraseña incorrecta",
  ACCOUNT_DOES_NOT_EXIST: "La cuenta no existe",
  RESET_PW_FAILED:
    "El enlace de restablecimiento de contraseña no es válido o ha caducado",
  CONFIRM_FAILED: "Mala ficha; reenviar correo electrónico de confirmación",
  ALREADY_CONFIRMED: "Cuenta ya confirmada",
  EMAIL_NO_MATCH: "No existe una cuenta con este email",
});

export const success_message = (content: string) => {
  message.success({ content: content, duration: 8 });
};
export const error_message = (err: any) => {
  message.error({ content: `${err}`, duration: 8 });
};
