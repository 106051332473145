import { formatMultiselect } from "./misc";
import { formBuilderField } from "./formBuilder";

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
    smartPhoneDetails: formatMultiselect(values.smartPhoneDetails),
    adequateSpaceDetails: formatMultiselect(values.adequateSpaceDetails),
    medAttentionBarriersList: formatMultiselect(values.medAttentionBarriersList),
    medAttentionPositivesList: formatMultiselect(values.medAttentionPositivesList),
  };
  return newValues;
};

const foodSecOptions = [
  { label: "Sí", value: "true" },
  { label: "No", value: "false" },
  { label: "No sabe", value: "unknown"},
]

export const SEIFields: formBuilderField[] = [
  {
    keyName: "foodInsecurity",
    type: "divider",
    label: "Inseguridad Alimentaria"
  },
  {
    keyName: "foodSecWorry",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez usted se preocupó porque los alimentos se acabaran en su hogar?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecStayHome",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez en su hogar se quedaron sin alimentos?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecAdultNoHealthyDiet",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez en su hogar dejaron de tener una alimentación saludable*?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecAdultLittleVariety",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez usted o algún adulto en su hogar tuvo una alimentación basada en poca variedad de alimentos?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecAdultStoppedMeal",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez usted o algún adulto en su hogar dejó de desayunar, almorzar o cenar?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecAdultAteLess",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez usted o algún adulto en su hogar comió menos de lo que debía comer?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecAdultNotEatWhenHungry",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez usted o algún adulto en su hogar sintió hambre pero no comió?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSecAdultAteOnceOrLess",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez usted o algún adulto en su hogar solo comió una vez al día o dejó de comer durante todo un día?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18NoHealthyDiet",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez algún menor de 18 años en su hogar dejó de tener una alimentación saludable?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18LittleVariety",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez algún menor de 18 años en su hogar tuvo una alimentación basada en poca variedad de alimentos?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18StoppedMeal",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez algún menor de 18 años en su hogar dejó de desayunar, almorzar o cenar?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18AteLess",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez algún menor de 18 años en su hogar comió menos de lo que debía?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18DecreaseAmountServed",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez tuvieron que disminuir la cantidad servida en las comidas a algún menor de 18 años en su hogar?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18NotEatWhenHungry",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez algún menor de 18 años en su hogar sintió hambre pero no comió?",
    options: foodSecOptions,
  },
  {
    keyName: "foodSec18AteOnceOrLess",
    type: "select",
    label: "En los últimos 3 meses, por falta de  dinero u otros recursos, ¿alguna vez algún menor de 18 años en su hogar solo comió una vez al día o dejó de comer durante todo un día?",
    options: foodSecOptions,
  },
  {
    keyName: "patientDivider",
    label: "Información del Paciente",
    type: "divider"
  },
  {
    keyName: "primaryInformer",
    label: "¿Quién en el informante?",
    type: "string",
    span: 12,
  },
  {
    keyName: "primaryInformerRelationship",
    label: "¿Cuál es el parentesco con el paciente?",
    type: "string",
    span: 12,
  },
  {
    keyName: "primaryCaregiver",
    label: "¿Quién es el cuidador principal?",
    type: "string",
    span: 12,
  },
  {
    keyName: "primaryCaregiverRelationship",
    label: "¿Cuál es el parentesco con el paciente?",
    type: "string",
    span: 12,
  },
  {
    keyName: "timeSinceDiagnosis",
    label: "¿Cuánto tiempo ha pasado desde que diagnosticaron a su hijo/hija su problema de salud?",
    type: "number",
    span: 18,
  },
  {
    keyName: "timeSinceDiagnosisType",
    label: "",
    type: "select",
    span: 6,
    options: [
      { value: "days", label: "Días" },
      { value: "months", label: "Meses" },
      { value: "years", label: "Años" },
    ]
  },
  {
    keyName: "diseaseStage",
    label: "¿En qué etapa de la enfermedad se encuentra?",
    type: "string",
  },
  {
    keyName: "courseOfTreatment",
    label: "¿En qué curso del tratamiento se encuentra?",
    type: "string",
  },
  {
    keyName: "medAttentionDivider",
    label: "Atención Médica",
    type: "divider",
  },
  {
    keyName: "medAttentionPositives",
    label: "Una vez que se detectó los signos de alarma de fiebre y neutropenia, cuáles han sido las mayores dificualtades para que (nombre) reciba atención médica?",
    type: "boolean",
    furtherOptions: {
      optionsKey: "medAttentionPositivesList",
      type: "select",
      selectMode: "multiple",
      options: [
        {
          label: "Administrativas",
          value: "administrative",
        },
        {
          label: "Referencia",
          value: "reference",
        },
        {
          label: "Económicas",
          value: "economic",
        },
        {
          label: "De transporte",
          value: "transport",
        },
        {
          label: "De manejo del paciente",
          value: "patientManagement",
        },
      ]
    }
  },
  {
    keyName: "medAttentionPositivesOther",
    label: "Otro",
    type: "string",
  },
  {
    keyName: "actionsTakenAfterWarningSigns",
    label: "¿Qué acciones realizó una vez que se detectaron los signos de alarma?",
    type: "string",
  },
  {
    keyName: "willEnterMedicalCare",
    label: "¿Durante su última visita, se presentaron demoras administrativas para que (Nombre) ingresara o recibiera la atención médica?",
    type: "boolean",
  },
  {
    keyName: "patientAccompaniedByCaregivers",
    label: "¿Durante su última visita, se dio acompañamiento a los cuidadores para el ingreso y la documentacion?",
    type: "boolean",
  },
  {
    keyName: "hospitalizeAfterFeverAndNeutropenia",
    label: "¿Fué necesario hospitalizar a (nombre) después de atender la urgencia por fiebre y neutropenia?",
    type: "boolean",
  },
  {
    keyName: "hospitalizationLength",
    label: "¿Cuánto tiempo duró la hospitalización (días)?",
    type: "number",
  },
  {
    keyName: "hadResourcesToMaintainHospitalization",
    label: "Contaron con los recursos disponibles para mantener hospitlalizado a (nombre) el tiempo que duró la hospitalización?",
    type: "boolean",
  },
  {
    keyName: "respondedPositivelyToTreatment",
    label: "¿(Nombre) ha respondido positiviamente al tratamiento?",
    type: "boolean",
  },
  {
    keyName: "financialConcerns",
    label: "¿Los cuidadores expresaron tener un riesgo financiero durante la atención? (percepción del encuestador)",
    type: "string",
  },
  {
    keyName: "supportNetworkDivider",
    label: "Red de soporte",
    type: "divider",
  },
  {
    keyName: "emergencyContact",
    label: "En caso de una urgencia, quién es la persona que atiende a (nombre). Especificar parentesco y forma de prestar apoyo o atención",
    type: "string",
  },
  {
    keyName: "supportPeople",
    label: "Quienes son las personas que la apoyan en el proceso de la enfermedad de (nombre) (pueden ser familiares o no)",
    type: "string",
  },
  {
    keyName: "externalFinancialSupport",
    label: "Recibe apoyo económico de algún familiar, vecino, amigo u otra persona cercana a usted, fuera del domicilio? Indagar el monto aproximado y frecuencia",
    type: "string",
  },
  {
    keyName: "psychologicalSupport",
    label: "Durante todo este proceso, a quien recurre cuando se siente cansado o agobiado por la situación general de la enfermedad? (Indagar en todo el contexto de la enfermedad - apoyo psicológico,  ONG, etc)",
    type: "string",
  },
  {
    keyName: "psychologicalAccompaniment",
    label: "Ha tenido acompañamiento psicológico en durante el proceso de la enfermedad? (Indagar quién da el serivicio, desde cuándo y en cuántas ocasiones)",
    type: "string",
  },
  {
    keyName: "additionalNotes",
    label: "Notas adicionales",
    type: "string",
  },
];
