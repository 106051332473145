import { Form, Checkbox, Input, Select, Col, Slider, Divider, DatePicker, Popover } from "antd";
import { FormInstance } from "rc-field-form";
import { useEffect, useState } from "react";
import { 
  formBuilderField,
  getMarks 
} from "../../util/formBuilder";
import { FullInputNumber } from "../StyledComponents";
import InfPopover from "../infectiology-form/InfPopover";

const FormBuilderFieldComp: React.FC<{
  formField: formBuilderField;
  form: FormInstance;
}> = ({ formField, form }) => {
  const [condition, setCondition] = useState(
    form.getFieldValue([formField.keyName])
  );
  const [seeOtherPopover, setSeeOtherPopover] = useState(false)

  const updateField = (thisForm: any, thisFormField: any) => (value: any) => {
    const fieldToAssign: Record<string, any> = {}
    const key = thisFormField.keyName
    fieldToAssign[key] = value[1]
    thisForm.setFieldsValue(fieldToAssign)
  }

  const onSelectChange = (value: any) => {
    if (value == "other") {
      setSeeOtherPopover(true)
    } else {
      setSeeOtherPopover(false)
    }
  }

  return (
    <Col span={formField.span ? formField.span : 24}>
      {formField.type === "divider" ? <Divider>
        {formField.label}
      </Divider>
      : <>
        <Popover
          placement="bottom"
          title="Otros"
          content={
            <InfPopover
              set={updateField(form, formField)}
              toggle={setSeeOtherPopover}
            />
          }
          visible={seeOtherPopover}
        />
        <Form.Item
          key={formField.keyName}
          name={formField.keyName}
          valuePropName={formField.type === "boolean" ? "checked" : "value"}
          label={formField.type !== "boolean" ? formField.label : ""}
          initialValue={formField.type == "boolean" ? false : undefined}
          rules={[{ required: formField.required ? true : false}]}
        >
          {formField.type === "boolean" ? <Checkbox
            onChange={(value) => {
              setCondition(value.target.checked);
            }}
          >
            {formField.label}
          </Checkbox>
          : formField.type === "number" ? <FullInputNumber />
          : formField.type === "string" ? <Input type="text" />
          : formField.type === "scale" ? <Slider 
            defaultValue={formField.scale ? formField.scale/2 : 1}
            min={0}
            max={formField.scale ? formField.scale - 1 : 2}
            marks={getMarks(formField.scale ? formField.scale : 0)} 
          /> : formField.type === "select" && formField.options ? <Select
            options={formField.options}
            mode={formField.selectMode}
            onChange={onSelectChange}
          /> : formField.type === "date" ? <DatePicker />
          : <div/>}
        </Form.Item>
      </>}
      {formField.furtherOptions ? (
        <Col span={24}>
          <Form.Item
            key={formField.furtherOptions.optionsKey}
            name={formField.furtherOptions.optionsKey}
            rules={[{ required: condition }]}
          >
            {formField.furtherOptions.type === "select" ? <Select
              options={formField.furtherOptions.options}
              disabled={!condition}
              mode={formField.furtherOptions.selectMode}
            /> : formField.furtherOptions.type === "number" ? <FullInputNumber
              disabled={!condition}
            /> : null}
          </Form.Item> 
        </Col>) 
      : null}
    </Col>
  );
};
export default FormBuilderFieldComp;
