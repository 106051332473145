import { restApi } from "./api";
import { error_message } from "./messages";
import { NewPatientForm } from "../pages/RegisterPatientPage";
import { FormResource, Patient } from "./misc";

export const getPatients = async (): Promise<Patient[]> => {
  try {
    const { data } = await restApi().get<Record<"patients", Patient[]>>(
      "/allPatients"
    );
    return data.patients;
  } catch (e: any) {
    error_message(e.response.data.reason);
    Promise.reject(e);
  }
  return [];
};

export const createPatient = async (
  values: NewPatientForm,
  patientId: string | undefined
) => {
  try {
    const { data } = await restApi().post("/patient", {values, patientId});
    return data;
  } catch (e: any) {
    error_message(e.response.data.reason);
    Promise.reject(e);
    return [];
  }
};

export const getPatientById = async (
  id: string
): Promise<Patient | undefined> => {
  try {
    const { data } = await restApi().get<Record<"patient", Patient>>("/patient", {
      params: { patientId: id },
    });
    // console.log('patient',data.patient)
    return data.patient;
  } catch (e: any) {
    error_message(e.response.data.reason);
    Promise.reject(e);
    return;
  }
};

export const getFormsByPatientId = async (
  id: string,
  formType: FormResource
): Promise<any> => {
  let resource = "all" + formType;
  try {
    const { data } = await restApi().get<Record<"forms", any[]>>(`/${resource}`, {
      params: { patientId: id },
    });
    return data.forms;
  } catch (e: any) {
    error_message(e.response.data.reason);
    Promise.reject(e);
    return;
  }
};
