import React, { useEffect } from "react";
import { Menu } from "antd";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMainStore } from "../state/mainStore";

const essentialHeaders = [
  <Menu.Item key="/newPatient">
    <Link to="/">Registrar niño nuevo</Link>
  </Menu.Item>,
  <Menu.Item key="patientList">
    <Link to="/patientList">Lista de pacientes</Link>
  </Menu.Item>,
  <Menu.Item key="activityLog">
    <Link to="/activityLog">Registro de actividades</Link>
  </Menu.Item>,
  <Menu.Item key="reports">
    <Link to="/reports">Informes</Link>
  </Menu.Item>,
];

// const authHeaders = [
//   <Menu.Item key="/">
//     <Link to="/">Entrar</Link>
//   </Menu.Item>,
//   <Menu.Item key="/configure">
//     <Link to="/configure">Configrar cuenta</Link>
//   </Menu.Item>,
// ];

const HeaderMenu: React.FC = () => {
  const history = useHistory();
  const [key, setKey] = useState(`${history.location.pathname}`);
  const handleClick = (e: any) => {
    setKey(e.key);
  };
  const { role } = useMainStore();
  
  useEffect(() => {
    const updateNav = history.listen((e) => {
      //listen to pathname change to update header menu on programatic nav
      if (e.pathname !== key) {
        setKey(e.pathname);
      }
    });
    return updateNav;
  }, [history, key]);

  return (
    <Menu onClick={handleClick} selectedKeys={[key]} mode="horizontal">
      {/* {role && role.length && role.includes("administrator") ? (
          <>
            {[
              essentialHeaders[0],
              // <Menu.Item key="/newStaff">
              //   <Link to="/newStaff">Registrar personal nuevo</Link>
              // </Menu.Item>,
              // <Menu.Item key="/listStaff">
              //   <Link to="/listStaff">Administración de usuarios</Link>
              // </Menu.Item>,
              essentialHeaders[1],
            ]}
          </>
        ) : role && role.length && role.some(role => ResearcherRoles.includes(role)) ? (
          <>{essentialHeaders}</>
        ) : null
      } */}
      { role && role.length && !role.includes("none") ? (
        <>{essentialHeaders}</>
      ) : null}
      
    </Menu>
  );
};
export default HeaderMenu;
