import { Form, Checkbox, Row, DatePicker, Select, Col } from "antd";
import { FormInstance } from "rc-field-form";
import { useState } from "react";
import { OutcomeField } from "../../util/InfFormUtil";

const OutcomeFields: React.FC<{
  outcomeField: OutcomeField;
  form: FormInstance;
}> = ({ outcomeField, form }) => {
  const [condition, setCondition] = useState(
    form.getFieldValue([outcomeField.keyName])
  );

  return (
    <Row align="bottom">
      <Col span={8}>
        <Form.Item
          key={outcomeField.keyName}
          name={outcomeField.keyName}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox
            onChange={(value) => {
              if (!value.target.checked) {
                form.setFieldsValue({ [outcomeField.dateName]: null });
              }
              setCondition(value.target.checked);
              if (outcomeField.furtherOptions) {
                form.setFieldsValue({
                  [outcomeField.furtherOptions?.optionsKey]: null,
                });
              }
            }}
          >
            {outcomeField.label}
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          key={outcomeField.dateName}
          name={outcomeField.dateName}
          label={outcomeField.endDateName ? "Inicial" : "Fecha"}
          rules={[{ required: condition }]}
        >
          <DatePicker disabled={!condition} />
        </Form.Item>
      </Col>
      <Col span={8}> {/* only room for one of furtherOptions or endDateName */}
        {outcomeField.furtherOptions ? (
          <Form.Item
            key={outcomeField.furtherOptions.optionsKey}
            name={outcomeField.furtherOptions.optionsKey}
            rules={[{ required: condition }]}
          >
            <Select
              options={outcomeField.furtherOptions.options}
              disabled={!condition}
              style={{ width: "150%", marginLeft: 15 }}
            />
          </Form.Item>
        ) : outcomeField.endDateName ? (
          <Form.Item
            key={outcomeField.endDateName}
            name={outcomeField.endDateName}
            label="Ultima"
            rules={[{ required: condition }]}
          >
            <DatePicker disabled={!condition} />
          </Form.Item>
        ) : null}
      </Col>
    </Row>
  );
};
export default OutcomeFields;
