import create from "zustand";
import { Patient } from "../util/misc";

type PatientStore = {
  patients: Patient[];
  resetPatients: () => void;
  setPatients: (patients: Patient[]) => void;
};

export const usePatientStore = create<PatientStore>((set) => ({
  patients: [],
  resetPatients: () => set((state) => ({ ...state, patients: [] })),
  setPatients: (patients: Patient[]) =>
    set((state) => ({ ...state, patients: [...patients] })),
}));
