import "../App.css";
import React, { useState, useEffect } from "react";
import CenterSpinner from "../components/CenterSpinner";
import { Button, Col, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons"
import { restApi } from "../util/api"
import { error_message } from "../util/messages";
import json2csv from 'json2csv'
import { saveAs } from 'file-saver'

const ActivityLogPage: React.FC = () => {

  const reports = [
    { label: "Signos y sintomas", id: "signs-and-symptoms"},
    { label: "Indicadores 1", id: "indicators-1"},
    { label: "Indicadores 2", id: "indicators-2"},
    { label: "Salud mental", id: "mental-health"},
    { label: "Pacientes sin formularios de trabajador social", id: "patients-without-swforms"},
  ]

  const downloadReport = async (reportId: string) => {
    const reportData = await getReportData(reportId)
    if (!reportData.length) return alert("No se recuperaron datos de la base de datos.")

    // make sure identifier fields are at the left of the csv
    const idFields = ['id', 'curp', 'himfgid', 'name', 'family_name_father']
    const dataFields = Object.keys(reportData[0]).filter(key => !idFields.includes(key))
    const fields = [...idFields, ...dataFields]
    // create csv
    const csv = json2csv.parse(reportData, {fields})
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    // vars for file name
    const today = new Date()
    const dateStr = today.toLocaleDateString().replaceAll("/","-")
    const report = reports.find(report => reportId == report.id)
    // download file
    saveAs(blob, `${report?.label} ${dateStr}.csv`)
  }

  const getReportData = async (reportId: string) => {
    try {
      const { data } = await restApi().get<Record<"report", any[]>>(
        `/report/${reportId}`
      );
      return data.report;
    } catch (e: any) {
      error_message(e.response.data.reason);
      Promise.reject(e);
    }
    return [];
  }

  return (
    <>
      <div
        style={{
          margin: "2rem auto",
          width: "calc(90% + 24px)",
          backgroundColor: "white",
          borderRadius: "0.25rem",
          padding: "1rem 1rem"
        }} >
          <Row>
            <Col span={24}>
              <h2>Reports</h2>
              <p>Seleccione un informe para descargar</p>
            </Col>
            {reports.map(report => 
              <Col 
                span={24}
                style={{marginBottom: "1rem"}}
              >
                <Button 
                  type="primary"
                  onClick={() => downloadReport(report.id)}
                  icon={<DownloadOutlined />}
                >
                  Descargar
                </Button>
                <span style={{marginLeft: "1rem"}}>{report.label}</span>
              </Col>
            )}
          </Row>
      </div>
    </>
  )
};

export default ActivityLogPage;
