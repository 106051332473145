import { Row, Button, Table, Input, Col, Form, DatePicker, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import "../App.css";
import React, { useState, useEffect } from "react";
import CenterSpinner from "../components/CenterSpinner";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useActivityStore } from "../state/activityStore";
import { useActivityLog } from "../hooks/dataHooks";
import { activityTypeDesc } from "../util/activityLog";
import { ReactComponent as RefreshSVG } from "../icons/iconmonstr-refresh-1.svg";
import { FormLabels } from "../util/misc";
import moment from "moment";


const ActivityLogPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<Record<string, boolean>>();
  const [currActivities, setCurrActivities] = useState<any[]>()

  // pass in yesterday as default startDate for activity log
  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  const defaultFilters = {
    startDate: yesterday.toISOString().slice(0,10),
    endDate: today.toISOString().slice(0,10),
    activityType: "all",
    formType: "all",
  }
  const [filters, setFilters] = useState<{[key: string]: string}>(defaultFilters)

  const { isLoading, refreshActivities } = useActivityLog(filters)
  const { activities } = useActivityStore()

  const activityTypeOptions = Object.keys(activityTypeDesc)
                                .map(key => {return {label: activityTypeDesc[key], value: key}})
  const formTypeOptions = Object.keys(FormLabels)
                            .map(key => {return {label: FormLabels[key], value: key}})
  const [filterForm] = Form.useForm();

  type ActivityColumn = {
    activityType: string,
    patientId: string,
    date: string,
    patientName: string,
    formId?: string,
    formType?: string,
  }

  useEffect(() => {
    setCurrActivities(activities);
  }, [activities]);

  useEffect(() => {
    //refresh after filters change
    handleRefresh()
  }, [filters])

  const handleSearch = (e: string) => {
    if (!e) setCurrActivities(activities);
    const filtered = activities!.filter((activity) => {
      return Object.values(activity).some((property:any) => {
        if (!property) return false;
        return property.toString().toLowerCase().includes(e.toLowerCase());
      });
    });
    setCurrActivities(filtered);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) return;
    setCurrActivities(activities);
  };

  const handleRefresh = () => {
    refreshActivities(filters)
  }

  const applyFilters = () => {
    const newFilters = filterForm.getFieldsValue()
    setFilters(newFilters)
  }

  const activityColumns: ColumnsType<ActivityColumn> = [
    {
      title: "Actividad",
      dataIndex: "activityDesc",
      width: "25%",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      width: "25%",
      sorter: (activity1, activity2) => activity1.date.localeCompare(activity2.date)
    },
    {
      title: "Pacientes",
      width: "25%",
      render: (activity) => {
        return activity.patientId 
          ? (<Button style={{width: "100%"}}>
              <Link to={{
                pathname: `/patientInfo/${activity.patientId}`,
                state: { patient: true},
              }}>{activity.patientName}</Link>
            </Button>) 
          : "n/a"
      }
    },
    {
      title: "Formulario",
      width: "25%",
      render: (activity) => {
        return activity.formType && activity.formId 
          ? (<Button style={{width: "100%"}}>
              <Link to={`/${activity.formType}/${activity.patientId}/${activity.formId}`}
              >Ver formulario</Link>
            </Button>)
          : ""
      }
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button onClick={handleRefresh} className="svg">
            <RefreshSVG />
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <Row 
        justify="space-around"
        gutter={24}
        align="top"
      >
        <Input.Search
          style={{ width: "90%", marginBottom: "20px" }}
          placeholder="Buscar..."
          onSearch={handleSearch}
          onChange={handleChange}
          enterButton
        />
      </Row>
      <Form 
        form={filterForm}
        style={{
          margin: "2rem auto",
          width: "calc(90% + 24px)",
          backgroundColor: "white",
          borderRadius: "0.25rem",
          padding: "1rem 1rem"
        }} 
      >
        <Row 
          justify="end"
          gutter={24}
          align="top"
        >
          <Col span={24}>
            <h1>Filtros</h1>
          </Col>
          <Col span={12}>
            <Form.Item
              key="startDate"
              name="startDate"
              label="Fecha de inicio"
              initialValue={moment(yesterday)}
            >
              <DatePicker/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="endDate"
              name="endDate"
              label="Fecha final"
              initialValue={moment(today)}
            >
              <DatePicker/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="activityType"
              name="activityType"
              label="Tipo de actividad"
              initialValue={"all"}
            >
              <Select
                options={[
                  {label: "Todos", value: "all"},
                  ...activityTypeOptions
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="formType"
              name="formType"
              label="Tipo de formulario"
              initialValue={"all"}
            >
              <Select 
                options={[
                  {label: "Todos", value: "all"},
                  ...formTypeOptions
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button 
              type="primary"
              onClick={applyFilters}
            >
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
      
      <Row
        justify="space-around"
        gutter={24}
        align="top"
        style={{position: "relative"}}
      >
        {!isLoading ? (
          <Table
            columns={activityColumns}
            rowKey={(form) => form.key}
            dataSource={currActivities}
            style={{margin: "0 auto", width: "90%"}}
          ></Table>
        ) : (
          <CenterSpinner />
        )}
      </Row>
    </>
  )
};

export default ActivityLogPage;
