import { ConfigureFormType } from "../pages/ConfigurePage";
import { NewStaffForm } from "../pages/RegisterStaff";
import { restApi } from "./api";
import {
  error_message,
  FAILURE_MSGS,
  success_message,
  SUCCESS_MSGS,
} from "./messages";
import { Staff } from "./misc";

export const createStaff = async (values: NewStaffForm) => {
  try {
    const { data } = await restApi().post("/user", values);
    success_message(SUCCESS_MSGS.NEW_STAFF_SUCCESS);
    return data;
  } catch (e: any) {
    error_message(
      `${FAILURE_MSGS.NEW_STAFF_FAILURE}: ${e.response.data.reason}`
    );
    Promise.reject(e);
  }
};

export const configureStaff = async (values: ConfigureFormType) => {
  try {
    const { data } = await restApi().post("/configureStaff", values);
    success_message(SUCCESS_MSGS.CONFIGURE_SUCCESS);
    return data;
  } catch (e: any) {
    error_message(
      `${FAILURE_MSGS.CONFIGURE_FAILED}: ${e.response.data.reason}`
    );
    Promise.reject(e);
  }
};

export const fetchStaff = async (): Promise<Staff[]> => {
  try {
    const { data } = await restApi().get<{ [key in "staff"]: any[] }>(
      "/allStaff"
    );
    const staff = data.staff.map(s => {return {id: s.id, ...s.profile}})
    return staff;
  } catch (e: any) {
    error_message(`${e}`);
    Promise.reject(e);
    return [];
  }
};

export const deleteStaff = async (staffId: string): Promise<boolean> => {
  try {
    await restApi().delete("/user", { params: { id: staffId } });
    return true;
  } catch (e: any) {
    error_message(`${e.response.data.reason}`);
    Promise.reject(e);
    return false;
  }
};
