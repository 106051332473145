const baseUrl = 'https://okta.nd.edu'
const issuerId = 'ausxosq06SDdaFNMB356'
const issuerUrl = `${baseUrl}/oauth2/${issuerId}`
const clientId = window.location.origin === "https://healthapp.himfg.aws.nd.edu" ?  "0oaijl07u5JNIunN8357" : "0oahug2s3qiSmUtQH357"
const callbackUrl = `${window.location.origin}/login/callback`

const oktaConfig = {
  issuer: issuerUrl,
  clientId: clientId,
  redirectUri: callbackUrl,
  scopes: ['openid', 'profile', 'roles'],
};

export default oktaConfig