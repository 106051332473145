import React, { useState, useCallback } from "react";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import { Security, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import oktaConfig from './okta-config';

// Components & pages
import Header from "./components/Header";
import LoginPage from "./pages/LoginPage";
import LoginCallbackPage from "./pages/LoginCallback";
import RegisterPatientPage from "./pages/RegisterPatientPage";
import PatientInfoPage from "./pages/PatientInfoPage";
import NotFoundPage from "./pages/404";
import ListPatientsPage from "./pages/ListPatientsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import ActivityLogPage from "./pages/ActivityLogPage";
import ReportsPage from "./pages/ReportsPage"
// form pages
import SWFormPage from "./forms/SWFormPage";
import InfectiologyFormPage from "./forms/InfectiologyFormPage";
import EmergencyFormPage from "./forms/EmergencyFormPage";
import OncologyFormPage from "./forms/OncologyFormPage";
import TrackingFormPage from "./forms/TrackingFormPage";
import SEIFormPage from "./forms/SEIFormPage";
import HistoryFormPage from "./forms/HistoryFormPage";

// misc
import CenterSpinner from "./components/CenterSpinner";
import { Layout } from "antd";
import { useMainStore } from "./state/mainStore";
import RegisterStaff from "./pages/RegisterStaff";
import ListStaffPage from "./pages/ListStaffPage";
import { useRole } from "./hooks/authHooks";
import ViewHistoryPage from "./pages/ViewHistoryPage";
// import { Permissions } from "./util/misc";
import OutcomeFormPage from "./forms/OutcomeFormPage";
import SignsSymptomsFormPage from "./forms/SignsSymptomsFormPage";
import MobileSymptomFormPage from "./forms/MobileSymptomFormPage";
import MobileSEIFormPage from "./forms/MobileSEIFormPage";
import MobileGeneralFormPage from "./forms/MobileGeneralFormPage";
import MobileSubstanceFormPage from "./forms/MobileSubstanceFormPage";

const oktaAuth = new OktaAuth(oktaConfig)

const App: React.FC = () => {
  const { isLoading } = useRole();
  const { isLoggedIn } = useMainStore();
  const [loadPatientsOnLoad, setLoadPatientsOnLoad] = useState(false);

  // okta restore uri
  const history = useHistory();
  const restoreOriginalUri = useCallback(async (_oktaAuth: any, originalUri: any) => {
    if (history) history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  }, [history]);

  return !isLoading ? (
    <Layout style={{ minHeight: "100%", backgroundColor: "white" }}>
      <BrowserRouter>
        <Security 
          restoreOriginalUri={restoreOriginalUri}
          oktaAuth={oktaAuth}
        >
          <Header isLoggedIn={isLoggedIn} />
          <Layout.Content>
            {!isLoggedIn ? (
              <>
                <Route exact path="/" component={LoginPage} />
                <Route exact path="/login/callback" component={LoginCallbackPage} />
              </>
            ) : isLoggedIn ? (
              <>
                <SecureRoute
                  exact
                  path="/"
                  component={() => (
                    <RegisterPatientPage
                      setLoadPatientsOnLoad={setLoadPatientsOnLoad}
                    />
                  )}
                />
                <SecureRoute
                  exact
                  path="/newStaff"
                  component={RegisterStaff}
                />
                <SecureRoute
                  exact
                  path="/listStaff"
                  component={ListStaffPage}
                />
                <SecureRoute
                  path="/patientList"
                  component={() => (
                    <ListPatientsPage />
                  )}
                />
                <SecureRoute
                  exact
                  path="/editPatient/:patientId"
                  component={() => (
                    <RegisterPatientPage
                      setLoadPatientsOnLoad={setLoadPatientsOnLoad}
                    />
                  )}
                />
                <SecureRoute
                  exact
                  path="/patientInfo/:patientId"
                  component={PatientInfoPage}
                />
                <SecureRoute
                  exact
                  path="/activityLog"
                  component={ActivityLogPage}
                />
                <SecureRoute
                  exact
                  path="/reports"
                  component={ReportsPage}
                />
                <SecureRoute
                  exact
                  path="/SWForm/:patientId"
                  component={() => <ViewHistoryPage formType="SWForm" />}
                />
                <SecureRoute
                  exact
                  path="/SWForm/:patientId/:formId"
                  component={SWFormPage}
                />
                <SecureRoute
                  exact
                  path="/InfForm/:patientId"
                  component={() => <ViewHistoryPage formType="InfForm" />}
                />
                <SecureRoute
                  exact
                  path="/InfForm/:patientId/:formId"
                  component={InfectiologyFormPage}
                />
                <SecureRoute
                  exact
                  path="/emergencyForm/:patientId"
                  component={() => <ViewHistoryPage formType="EmergencyForm" />}
                />
                <SecureRoute
                  exact
                  path="/emergencyForm/:patientId/:formId"
                  component={EmergencyFormPage}
                />
                <SecureRoute
                  exact
                  path="/oncologyForm/:patientId"
                  component={() => <ViewHistoryPage formType="OncologyForm" />}
                />
                <SecureRoute
                  exact
                  path="/oncologyForm/:patientId/:formId"
                  component={OncologyFormPage}
                />
                <SecureRoute
                  exact
                  path="/outcomeForm/:patientId"
                  component={() => <ViewHistoryPage formType="OutcomeForm" />}
                />
                <SecureRoute
                  exact
                  path="/OutcomeForm/:patientId/:formId"
                  component={OutcomeFormPage}
                />
                <SecureRoute
                  exact
                  path="/SignsSymptomsForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="SignsSymptomsForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/SignsSymptomsForm/:patientId/:formId"
                  component={SignsSymptomsFormPage}
                />
                <SecureRoute
                  exact
                  path="/TrackingForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="TrackingForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/TrackingForm/:patientId/:formId"
                  component={TrackingFormPage}
                />
                <SecureRoute
                  exact
                  path="/SEIForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="SEIForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/SEIForm/:patientId/:formId"
                  component={SEIFormPage}
                />
                <SecureRoute
                  exact
                  path="/HistoryForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="HistoryForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/HistoryForm/:patientId/:formId"
                  component={HistoryFormPage}
                />
                <SecureRoute
                  exact
                  path="/MobileSymptomForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="MobileSymptomForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/MobileSymptomForm/:patientId/:formId"
                  component={MobileSymptomFormPage}
                />
                <SecureRoute
                  exact
                  path="/MobileSEIForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="MobileSEIForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/MobileSEIForm/:patientId/:formId"
                  component={MobileSEIFormPage}
                />
                <SecureRoute
                  exact
                  path="/MobileSubstanceForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="MobileSubstanceForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/MobileSubstanceForm/:patientId/:formId"
                  component={MobileSubstanceFormPage}
                />
                <SecureRoute
                  exact
                  path="/MobileDepressionForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="MobileDepressionForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/MobileFamilyForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="MobileFamilyForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/MobileStressForm/:patientId"
                  component={() => (
                    <ViewHistoryPage formType="MobileStressForm" />
                  )}
                />
                <SecureRoute
                  exact
                  path="/mobile/:formName/:patientId/:formId"
                  component={MobileGeneralFormPage}
                />
              </>
            ) : (
              <CenterSpinner />
            )}
            <Route exact path="/404" component={NotFoundPage} />
            <Route exact path="/privacypolicy" component={PrivacyPolicyPage} />
          </Layout.Content>
        </Security>
      </BrowserRouter>
    </Layout>
  ) : (
    <div style={{ marginTop: "30%" }}>
      <CenterSpinner />
    </div>
  );
};

export default App;
