import { formBuilderField } from './formBuilder'
import { RelationshipToPatientField } from './MobileGeneralFormUtil'

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values
  }
  return newValues
}

const answerYesNo = [
  {label: "Sí", value: "Sí"}, 
  {label: "No", value: "No"},
]
const answerSet1 = [
  {label: "Nunca", value: "Nunca" },
  {label: "Una ó dos veces", value: "Una ó dos veces"},
  {label: "Mensualmente", value: "Mensualmente"},
  {label: "Semanalmente", value: "Semanalmente"},
  {label: "A diario o casi a diario", value: "A diario o casi a diario"}
]
const answerSet2 = [
  {label: "Nunca", value: "Nunca"},
  {label: "Sí, en los últimos tres meses", value: "Sí, en los últimos tres meses"},
  {label: "Sí, pero no en los últimos tres meses", value: "Sí, pero no en los últimos tres meses"} 
]

const substanceTypes = [
  "Tobacco",
  "Alcohol",
  "Cannabis",
  "Cocaine",
  "Antephamine",
  "Inhalant",
  "Sedative",
  "Hallucinogen",
  "Opioid",
  "Other",
]

const substanceLabels: {[key: string]: any} = {
  Tobacco: "Tabaco (cigarrillos, cigarros habanos, tabaco de mascar, pipa, etc.)",
  Alcohol: "Bebidas alcohólicas (cerveza, vino, licores, destilados, etc.)",
  Cannabis: "Cannabis (marihuana, hierba, hashish, etc.)",
  Cocaine: "Cocaína (coca, farlopa, crack, base, etc.)",
  Antephamine: "Estimulantes de tipo anfetamina (speed, éxtasis, píldoras adelgazantes, etc.)",
  Inhalant: "Inhalantes (colas, gasolina/nafta, pegamento, etc.)",
  Sedative: "Sedantes o pastillas para dormir (valium/diazepam, Trankimazin/Alprazolam/Xanax, Orfidal/Lorazepam, Rohipnol,etc.)",
  Hallucinogen: "Alucinógenos (LSD, ácidos, ketamina, PCP, etc.)",
  Opioid: "Opiáceos (heroína, metadona, codeína, morfina, dolantina/petidina, etc.)",
  Other: "Otro:"
}

const generateFields = (category: string, answers: {label: string, value: string}[]) => {
  // map substance types to fields
  const fields = substanceTypes.map(type => {
    // some substance labels omit the examples within () in the use3months category
    const noExamples = ['Sedative', 'Hallucinogen', 'Opioid']
    const substanceLabel = category == 'Use3Months' && noExamples.includes(type)
      ? substanceLabels[type].split("(")[0] 
      : substanceLabels[type]

    const field: formBuilderField = {
      keyName: `${type}${category}`,
      type: "select",
      label: substanceLabel,
      options: answers,
      required: true,
    }
    return field
  })

  // add specifiy other field
  const otherSpecify: formBuilderField = {
    keyName: `Other${category}Specify`,
    type: "string",
    label: "Otro (por favor, especifique cual)",
  }
  fields.push(otherSpecify)

  // add YesOrNo field if needed
  if (['UseEver','Use3Months'].includes(category)) {
    const yesOrNoField: formBuilderField = {
      keyName: `YesOrNo${category}`,
      type: "select",
      label: `¿Usted respondió ${category === "UseEver" ? "que NO a todas las opciones anteriores" : "NUNCA para todas las sustancias"}?`,
      options: answerYesNo,
      required: true
    }
    fields.push(yesOrNoField)
  }

  return fields
}

const notEvenWhenStudentFields: formBuilderField[] = [
  {
    keyName: "NotEvenWhenStudent",
    type: "select",
    label: "¿Tampoco cuando era estudiante?",
    options: answerYesNo,
  }
]

const injectionFields: formBuilderField[] = [
  {
    keyName: "AttemptedDrugByInjection",
    type: "select",
    label: "Marque la casilla correspondiente",
    options: answerSet2,
    required: true,
  },
  {
    keyName: "DrugInjection3MonthsFrequency",
    type: "select",
    label: "Hábitos de inyección:",
    options: [
      {label: "Nunca", value: "Nunca"},
      {label: "Cuatro días o menos por mes en promedio", value: "Cuatro días o menos por mes en promedio"}, 
      {label: "Más de cuatro días por mesen promedio", value: "Más de cuatro días por mesen promedio"},
    ],
  },
]

export const MobileSubstanceFormFields: {[key: string]: any} = {
  general: {
    fields: RelationshipToPatientField,
    title: ""
  },
  useEver: {
    fields: generateFields("UseEver", answerYesNo),
    title: "¿Alguna vez en su vida ha consumido…?",
  },
  notEvenWhenStudent: {
    fields: notEvenWhenStudentFields,
    title: ""
  },
  use3Months: {
    fields: generateFields("Use3Months", answerSet1),
    title: "En los últimos tres meses, ¿con qué frecuencia ha consumido las sustancias que mencionó?",
  },
  desire3Months: {
    fields: generateFields("Desire3Months", answerSet1),
    title: "En los últimos tres meses, ¿con quéfrecuencia ha sentido un fuerte deseo o ansia de consumir?",
  },
  causingProblems: {
    fields: generateFields("CausingProblems", answerSet1),
    title: "En los últimos tres meses, ¿con qué frecuencia el consumo de sustancias le ha causado problemas de salud, sociales, legales o económicos?",
  },
  affectingExpectedTasks: {
    fields: generateFields("AffectingExpectedTasks", answerSet1),
    title: "En los últimos tres meses, ¿con qué frecuencia dejó de hacer lo que habitualmente se esperaba de usted por el consumo de las siguientes sustancias?",
  },
  relativesConcerned: {
    fields: generateFields("RelativesConcerned", answerSet2),
    title: "Un amigo, un familiar o alguien más alguna vez ha mostrado preocupación por sus hábitos de consumo de?",
  },
  attemptedToQuit: {
    fields: generateFields("AttemptedToQuit", answerSet2),
    title: "¿Ha intentado alguna vez reducir o eliminar el consumo de?",
  },
  injection: {
    fields: injectionFields,
    title: "¿Alguna vez ha consumido alguna droga por vía inyectada?",
  }
}

const substanceTypesActive = (values: any): any[] => {
  let useEver: any[] = [];
  substanceTypes.forEach(type => {
    const key = `${type}UseEver`;
    if (values[key] === "Sí") {
      useEver.push(type);
    }
  });

  return useEver;
}

const filterFieldsSubstanceTypeUseEver = (fields: any[], is_other_active: boolean, is_empty: boolean): any[] => {
  let useEver: any[] = substanceTypes.map(type => `${type}UseEver`);
  if (is_other_active) {
    useEver.push("OtherUseEverSpecify");
  }

  if (is_empty) {
    useEver.push("YesOrNoUseEver");
  }

  return fields.filter(field => useEver.includes(field.keyName));
};

const filterSubstanceTypes = (list: any[], prefixes: string[]): any[] => {
  return list.filter(item => 
      prefixes.some(prefix => item.keyName.startsWith(prefix))
  );
};

const findByKeyNameSubstanceTypes = (list: any[], keyName: string): any | undefined => {
  return list.find(item => item.keyName === keyName);
};

const getFilterFieldsSubstanceTypes = (key: string, useEver: any[], no_fields: string): any | undefined => {
  let filteredList = filterSubstanceTypes(MobileSubstanceFormFields[key].fields, useEver)
  if (filteredList.length === 0) {
    filteredList = [];
    const yesOrNoField = findByKeyNameSubstanceTypes(MobileSubstanceFormFields[key].fields, no_fields);
    if (yesOrNoField) {
      filteredList.push(yesOrNoField);
    }
  }

  return filteredList;
};

const isActiveSectionSubstanceType = (fields: any[]): boolean => {
  if (fields.length === 0) {
    return false;
  }

  return true;
};

const substanceTypeUse3MonthsActive = (useEver: any[], values: any): any[] => {
  let use3Months: any[] = [];
  useEver.forEach(type => {
    const key = `${type}Use3Months`;
    if (values[key] !== "Nunca") {
      use3Months.push(type);
    }
  });

  return use3Months;
}

export const setSubstanceTypesActive = (values: any) => {
  values = (typeof values === 'object' && values !== null) ? values : {};
  let useEver: any[] = substanceTypesActive(values);

  const dataTemp = JSON.parse(JSON.stringify(MobileSubstanceFormFields));
  dataTemp.useEver.fields = filterFieldsSubstanceTypeUseEver(dataTemp.useEver.fields, values.OtherUseEver == "Sí", useEver.length === 0);
  dataTemp.use3Months.fields = getFilterFieldsSubstanceTypes("use3Months", useEver, "YesOrNoUse3Months");
  dataTemp.desire3Months.fields = getFilterFieldsSubstanceTypes("desire3Months", useEver, "-");
  dataTemp.causingProblems.fields = getFilterFieldsSubstanceTypes("causingProblems", useEver, "--");
  dataTemp.affectingExpectedTasks.fields = getFilterFieldsSubstanceTypes("affectingExpectedTasks", useEver, "--");
  dataTemp.relativesConcerned.fields = getFilterFieldsSubstanceTypes("relativesConcerned", useEver, "--");
  dataTemp.attemptedToQuit.fields = getFilterFieldsSubstanceTypes("attemptedToQuit", useEver, "--");

  dataTemp.general.active = true;
  dataTemp.useEver.active = true;
  dataTemp.notEvenWhenStudent.active = false;
  dataTemp.use3Months.active = isActiveSectionSubstanceType(dataTemp.use3Months.fields);
  dataTemp.desire3Months.active = isActiveSectionSubstanceType(dataTemp.desire3Months.fields);
  dataTemp.causingProblems.active = isActiveSectionSubstanceType(dataTemp.causingProblems.fields);
  dataTemp.affectingExpectedTasks.active = isActiveSectionSubstanceType(dataTemp.affectingExpectedTasks.fields);
  dataTemp.relativesConcerned.active = isActiveSectionSubstanceType(dataTemp.relativesConcerned.fields);
  dataTemp.attemptedToQuit.active = isActiveSectionSubstanceType(dataTemp.attemptedToQuit.fields);
  dataTemp.injection.active = true;

  if (useEver.length === 0) {
    dataTemp.notEvenWhenStudent.active = true;
    dataTemp.use3Months.active = false;
    dataTemp.desire3Months.active = false;
    dataTemp.causingProblems.active = false;
    dataTemp.affectingExpectedTasks.active = false;
    dataTemp.relativesConcerned.active = false;
    dataTemp.attemptedToQuit.active = false;
    dataTemp.injection.active = false;
    return dataTemp;
  }

  let use3Months: any[] = substanceTypeUse3MonthsActive(useEver, values)
  if (use3Months.length === 0) {
    dataTemp.desire3Months.active = false;
    dataTemp.causingProblems.active = false;
    dataTemp.affectingExpectedTasks.active = false;
  }

  return dataTemp;
}

const defaultFiledValues = {
  "relationship_to_patient": null,
  "TobaccoUseEver": null,
  "AlcoholUseEver": null,
  "CannabisUseEver": null,
  "CocaineUseEver": null,
  "AntephamineUseEver": null,
  "InhalantUseEver": null,
  "SedativeUseEver": null,
  "HallucinogenUseEver": null,
  "OpioidUseEver": null,
  "OtherUseEver": null,
  "OtherUseEverSpecify": null,
  "YesOrNoUseEver": null,
  "NotEvenWhenStudent": null,
  "TobaccoUse3Months": null,
  "AlcoholUse3Months": null,
  "CannabisUse3Months": null,
  "CocaineUse3Months": null,
  "AntephamineUse3Months": null,
  "InhalantUse3Months": null,
  "SedativeUse3Months": null,
  "HallucinogenUse3Months": null,
  "OpioidUse3Months": null,
  "OtherUse3Months": null,
  "OtherUse3MonthsSpecify": null,
  "YesOrNoUse3Months": null,
  "TobaccoDesire3Months": null,
  "AlcoholDesire3Months": null,
  "CannabisDesire3Months": null,
  "CocaineDesire3Months": null,
  "AntephamineDesire3Months": null,
  "InhalantDesire3Months": null,
  "SedativeDesire3Months": null,
  "HallucinogenDesire3Months": null,
  "OpioidDesire3Months": null,
  "OtherDesire3Months": null,
  "OtherDesire3MonthsSpecify": null,
  "TobaccoCausingProblems": null,
  "AlcoholCausingProblems": null,
  "CannabisCausingProblems": null,
  "CocaineCausingProblems": null,
  "AntephamineCausingProblems": null,
  "InhalantCausingProblems": null,
  "SedativeCausingProblems": null,
  "HallucinogenCausingProblems": null,
  "OpioidCausingProblems": null,
  "OtherCausingProblems": null,
  "OtherCausingProblemsSpecify": null,
  "TobaccoAffectingExpectedTasks": null,
  "AlcoholAffectingExpectedTasks": null,
  "CannabisAffectingExpectedTasks": null,
  "CocaineAffectingExpectedTasks": null,
  "AntephamineAffectingExpectedTasks": null,
  "InhalantAffectingExpectedTasks": null,
  "SedativeAffectingExpectedTasks": null,
  "HallucinogenAffectingExpectedTasks": null,
  "OpioidAffectingExpectedTasks": null,
  "OtherAffectingExpectedTasks": null,
  "OtherAffectingExpectedTasksSpecify": null,
  "TobaccoRelativesConcerned": null,
  "AlcoholRelativesConcerned": null,
  "CannabisRelativesConcerned": null,
  "CocaineRelativesConcerned": null,
  "AntephamineRelativesConcerned": null,
  "InhalantRelativesConcerned": null,
  "SedativeRelativesConcerned": null,
  "HallucinogenRelativesConcerned": null,
  "OpioidRelativesConcerned": null,
  "OtherRelativesConcerned": null,
  "OtherRelativesConcernedSpecify": null,
  "TobaccoAttemptedToQuit": null,
  "AlcoholAttemptedToQuit": null,
  "CannabisAttemptedToQuit": null,
  "CocaineAttemptedToQuit": null,
  "AntephamineAttemptedToQuit": null,
  "InhalantAttemptedToQuit": null,
  "SedativeAttemptedToQuit": null,
  "HallucinogenAttemptedToQuit": null,
  "OpioidAttemptedToQuit": null,
  "OtherAttemptedToQuit": null,
  "OtherAttemptedToQuitSpecify": null,
  "AttemptedDrugByInjection": null,
  "DrugInjection3MonthsFrequency": null
};

export const getAllFieldsValues = (values: any) => {
    const validInput = (typeof values === 'object' && values !== null) ? values : {};
    const mergedJson: typeof defaultFiledValues = { ...defaultFiledValues, ...validInput };
    return mergedJson;
}