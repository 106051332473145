import React from "react";
import { Col, Row, Space } from "antd";
import { useHistory, useLocation, useParams } from "react-router";
import { BackButton, ButtonItem, RoundedButton } from "./StyledComponents";
import { FormByIdParams } from "../util/misc";

type FormSkeletonProps = {
  onSubmit: (values: any) => void;
  disableCallback?: () => boolean;
};
const FormSkeleton: React.FC<FormSkeletonProps> = ({
  children,
  onSubmit,
  disableCallback,
}) => {
  const history = useHistory();
  const disabled = disableCallback ? disableCallback() : false;
  const location = useLocation<Record<"viewOnly", boolean>>();
  const viewOnly = location.state ? location.state.viewOnly : false;
  const { patientId } = useParams<FormByIdParams>();
  const formType = window.location.pathname.split('/')[1]
  // const viewOnly = false;

  return (
    <>
      <div className="form-container">
        <Row
          justify="start"
          align="top"
          style={{ width: "100%", margin: "0 auto" }}
        >
          <BackButton onClick={() => history.goBack()}>
            &#8592; Regresa
          </BackButton>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            {children}
            <Space
              size="large"
              direction="vertical"
              style={{ width: "100%", paddingBottom: "1rem" }}
            >
              <ButtonItem>
                {!viewOnly ? (
                  <RoundedButton
                    type="primary"
                    onClick={onSubmit}
                    disabled={disabled}
                  >
                    Enviar
                  </RoundedButton>
                ) : (
                  <Space>Este formulario solo es editable por el administrador.</Space>
                )}
              </ButtonItem>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FormSkeleton;
