import {
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Input,
} from "antd";
import React from "react";
import { OptionsField } from "../components/OptionsField";
import { FullInputNumber } from "../components/StyledComponents";
import {
  BiochemicalIndicators,
  CropSiteOptions,
  ImagingOptions,
  ChestXrayOptions,
  AbdomenPlateOptions,
} from "../util/EmergencyFormUtil";

type multiselectWatchers = {
  setAbdomenPlateArray: React.Dispatch<React.SetStateAction<string[]>>,
  abdomenPlateArray: string[],
  setXrayArray: React.Dispatch<React.SetStateAction<string[]>>,
  xrayArray: string[],
}

const EmergencyFormPart3: React.FC<multiselectWatchers> = ({ setAbdomenPlateArray, abdomenPlateArray, setXrayArray, xrayArray}) => (
  <>
    <Divider></Divider>
    <Row justify="center" gutter={16}>
      <Col span={12}>
        <Form.Item
          key="cultivationDate" 
          name="cultivationDate"
          label="Fecha del cultivo"
          rules={[{ required: false, message: "Requerido" }]}
        >
          <DatePicker />
        </Form.Item>
      </Col>
      <Col span={12}>
        <OptionsField formOptions={CropSiteOptions} presentation="multiselect" required={false}/>
      </Col>
    </Row>
    <Divider></Divider>
    <Row justify="start" gutter={16}>
      {BiochemicalIndicators.map((item, idx) => (
        <Col span={12}>
          <Form.Item
            key={item.formKey}
            name={item.formKey}
            label={item.label}
            rules={[{ required: false, message: "Requerido"}]}
            >
              <FullInputNumber />
            </Form.Item>
        </Col>
      ))}
      <Col span={12}>
        <Form.Item
          key="urea"
          name="urea"
          label="Urea (mg/dL)"
          rules={[{ required: false, message: "Requerido" }]}
        >
          <FullInputNumber />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          key="creatinine"
          name="creatinine"
          label="Creatinina (mg/dL)"
          rules={[{ required: false, message: "Requerido" }]}
        >
          <FullInputNumber />
        </Form.Item>
      </Col>
    </Row>
    <Divider></Divider>
    <Row justify="center">
      <Col span={24}>
        <OptionsField 
        formOptions={ImagingOptions} 
        presentation="multiselect" 
        onChange={(e) => setXrayArray(e)}
        required={false}/>
      </Col>
      <Col span={24}>
        <Form.Item
          key="imagingOther"
          name="imagingOther"
          label="Si es otro, explique"
          rules={[{ required: false}]}
        >
          <Input disabled={!xrayArray.includes("others")}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <OptionsField 
        formOptions={ChestXrayOptions} 
        presentation="multiselect" 
        onChange={(e) => setXrayArray(e)}
        required={false}/>
      </Col>
      <Col span={24}>
        <Form.Item
          key="chestXrayOther"
          name="chestXrayOther"
          label="Si es otro, explique"
          rules={[{ required: false}]}
        >
          <Input disabled={!xrayArray.includes("others")}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <OptionsField 
          formOptions={AbdomenPlateOptions} 
          presentation="multiselect" 
          onChange={(e) => setAbdomenPlateArray(e)} 
          required={false}/>
      </Col>
      <Col span={24}>
        <Form.Item
          key="abdomenPlateOther"
          name="abdomenPlateOther"
          label="Si es otro, explique"
          rules={[{ required: false}]}
        >
          <Input disabled={!abdomenPlateArray.includes("others")} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          key="feverClass"
          name="feverClass"
          label="Clasificación del evento de fiebre y neutropenia"
        >
          <Radio.Group defaultValue={"no-data"} buttonStyle="solid">
            <Radio.Button value={"no-data"}>
              Sin datos
            </Radio.Button>
            <Radio.Button value={"high-risk"}>
              Alto riesgo
            </Radio.Button>
            <Radio.Button value={"low-risk"}>
              Bajo riesgo
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default EmergencyFormPart3;