import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import CenterSpinner from "../components/CenterSpinner";
import { useAllStaff } from "../hooks/dataHooks";
import { RoleToLabel, Staff } from "../util/misc";
import { deleteStaff } from "../util/staff";
import { useStaffStore } from "../state/staffStore";
import { ReactComponent as RefreshSVG } from "../icons/iconmonstr-refresh-1.svg";
import { ReactComponent as DeleteSVG } from "../icons/iconmonstr-trash-can-1.svg";

const ListStaffPage: React.FC = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteStaff = async (staff: Staff) => {
    setDeleteLoading(true);
    await deleteStaff(staff.id);
    setDeleteLoading(false);
  };
  const { isLoading, refreshStaff } = useAllStaff();
  const [staffToPresent, setStaffToPresent] = useState<Staff[]>();
  const { staff } = useStaffStore();

  useEffect(() => {
    setStaffToPresent(staff);
  }, [staff, refreshStaff]);

  const showDeleteModal = (staffToDel: Staff) => {
    return () =>
      !deleteLoading ? (
        Modal.confirm({
          title: "Confirmar",
          content: (
            <Row justify="space-around">
              ¿Está seguro de que desea eliminar a este miembro del personal?
            </Row>
          ),
          onOk: async () => {
            return new Promise<void>(async (resolve, reject) => {
              await handleDeleteStaff(staffToDel).catch(() => reject());
              await refreshStaff().catch(() => reject());
              resolve();
            });
          },
        })
      ) : (
        <CenterSpinner />
      );
  };
  const columns: ColumnsType<Staff> = [
    {
      title: "Nombre",
      dataIndex: "name",
      width: "20%",
      render: (_, staff) => `${staff.firstName} ${staff.lastName}`
    },
    {
      title: "Número de teléfono",
      width: "20%",
      render: (_, staff) => staff.mobilePhone,
    },
    {
      title: "Perfil",
      dataIndex: "role",
      width: "20%",
      render: (_, record) => `${RoleToLabel[record.role]}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Email confirmado",
      dataIndex: "email_confirmed",
      render: (isConfirmed) => (isConfirmed ? "Si" : "No"),
      width: "20%",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button onClick={refreshStaff} className="svg">
            <RefreshSVG />
          </Button>
        </div>
      ),
      width: "20%",
      render: (staff) => (
        <>
          <Button danger onClick={showDeleteModal(staff)} className="svg">
            <DeleteSVG />
          </Button>
        </>
      ),
    },
  ];

  const handleSearch = (e: string) => {
    if (!e) setStaffToPresent(staff);
    const filtered = staff!.filter((staff) => {
      return Object.values(staff).some((property) => {
        if (!property) return false;
        return property.toString().toLowerCase().includes(e.toLowerCase());
      });
    });
    setStaffToPresent(filtered);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) return;
    setStaffToPresent(staff);
  };

  return (
    <>
      <Row justify="space-around" gutter={24} align="top">
        <Input.Search
          style={{ width: "90%", marginBottom: "20px" }}
          placeholder="Buscar..."
          onSearch={handleSearch}
          onChange={handleChange}
          enterButton
        />
      </Row>
      {!isLoading ? (
        <Row justify="space-around" gutter={24} align="top">
          <Table
            columns={columns}
            dataSource={staffToPresent}
            style={{
              margin: "0 auto",
              width: "90%",
            }}
          ></Table>
        </Row>
      ) : (
        <CenterSpinner />
      )}
    </>
  );
};
export default ListStaffPage;
