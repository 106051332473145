import { useEffect, useState } from "react";
import { NewPatientForm } from "../pages/RegisterPatientPage";
import { NewStaffForm } from "../pages/RegisterStaff";
import { createPatient } from "../util/patients";
import { createStaff } from "../util/staff";
import { Entity } from "../util/misc";

export const useRegister = (
  entity: Entity,
  values: NewPatientForm | NewStaffForm | undefined,
  id: string | undefined,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [returnedId, setReturnedId] = useState();

  useEffect(() => {
    (async () => {
      if (!values) return;
      setIsLoading(true);
      switch (entity) {
        case "administrator":
        case "staff":
          await createStaff(values as NewStaffForm);
          break;
        case "patient":
          const patientInfo = await createPatient(values as NewPatientForm, id as string | undefined);
          setReturnedId(patientInfo.newId)
          break;
        default:
          await createStaff(values as NewStaffForm);
      }
      setIsLoading(false);
    })();
  }, [entity, values]);

  return { isLoading, returnedId};
};
