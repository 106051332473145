import CenterSpinner from "../components/CenterSpinner";
export interface LoginForm {
  username: string;
  password: string;
  role: string;
}

const LoginCallbackPage = () => {
  // handling authentication and setting user in store from header
  // this component is just to display a spinner in case that takes a moment

  return (
    <CenterSpinner />
  );
};

export default LoginCallbackPage;
