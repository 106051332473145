import {
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Select,
  Popover,
  Cascader,
  Space,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import React, { useEffect, useState } from "react";
import {
  cultivationSiteOptions,
  GramNegMicroorgOptions,
  gramNegMicroorgsSet,
  GramPosMicroorgOptions,
  gramPosMicroorgsSet,
  MiscMicroorgOptions,
  newNegMicroorgObj,
  newNoneMicroorgObj,
  newPosMicroorgObj,
  RespiratoryVirusesOptions,
  updateCropReport,
  updateCultivationSite,
} from "../../util/InfFormUtil";
import "../../index.css";
import InfPopover from "./InfPopover";
import MicroOrgSensitivities from "./MicroOrgSensitivities";

const CultureDetails: React.FC<{
  field: FormListFieldData;
  form: FormInstance;
  fieldPath: any[];
}> = ({ field, form, fieldPath }) => {
  const [prevValue, setPrevValue] = useState<any>();
  const [otherCultSiteToggle, setCultSiteToggle] = useState(false);
  const [gramType, setGramType] = useState<any>("none");
  const [oherCultReportToggle, setOtherCultReportToggle] = useState(false);
  const [gramPosMicroogs, setGramPosMicroorgsSet] =
    useState<Set<string>>(gramPosMicroorgsSet);
  const [gramNegMicroorgs, setGramNegMicroorgsSet] =
    useState<Set<string>>(gramNegMicroorgsSet);

  const [microOrgInfo, setMicroOrgInfo] = useState<any>();
  const [cultureValue, setCultureValue] = useState<any>(
    form.getFieldsValue()["infections"][fieldPath[1]]["cultures"][
      fieldPath.slice(-1)[0]
    ]
  );

  useEffect(() => {
    if (!cultureValue) {
      return;
    }

    let newValue =
      typeof cultureValue === "string"
        ? cultureValue
        : "cropReport" in cultureValue
        ? cultureValue["cropReport"]
        : cultureValue[1];

    if (Array.isArray(newValue)) {
      newValue = newValue[1];
    }

    const prevMicroorgSet =
      prevValue?.microOrgGram === "positive"
        ? gramPosMicroogs
        : prevValue?.microOrgGram === "negative"
        ? gramNegMicroorgs
        : null;

    if (prevValue && prevMicroorgSet) {
      prevMicroorgSet.delete(prevValue.microOrgName);
    }

    let gramType = gramPosMicroogs.has(newValue)
      ? "positive"
      : gramNegMicroorgs.has(newValue)
      ? "negative"
      : "none";

    if (typeof cultureValue !== "string" && "cropReport" in cultureValue) {
      gramType = cultureValue["gramType"];
    }
    setGramType(gramType);
    if (gramType === "none" && newValue === "other") {
      setOtherCultReportToggle(true);
    }
    const sensitivities =
      gramType === "positive"
        ? newPosMicroorgObj()
        : gramType === "negative"
        ? newNegMicroorgObj()
        : newNoneMicroorgObj();
    const newMicroOrg = {
      microOrgGram: gramType,
      microOrgName: newValue,
      sensitivity: sensitivities,
    };
    setMicroOrgInfo((prev: any) => ({
      ...prev,
      ...newMicroOrg,
    }));
    setPrevValue(newMicroOrg);
  }, [cultureValue, gramPosMicroogs, gramNegMicroorgs]);

  useEffect(() => {
    // for some reason on load the culture value can get set as the whole cropReport
    // here we make sure that we only pass in the cropReport array when updating cropReport
    let checkCultureValue = cultureValue
    if (checkCultureValue?.cropReport)
      checkCultureValue = checkCultureValue.cropReport
    updateCropReport(form, fieldPath, checkCultureValue, gramType);
  }, [cultureValue, gramType]);

  const filter = (inputValue: any, path: any) => {
    return path.some(
      (option: any) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  return (
    <Card>
      <Row>
        <Col span={12}>
          <Form.Item
            name={[field.name, "dateOfTakingCulture"]}
            label="Fecha de la toma del cultivo"
            required={true}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[field.name, "dateOfCultureResults"]}
            label="Fecha de resultado del cultivo"
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Popover
        placement="bottom"
        title="Otro sitio del cultivo"
        content={
          <InfPopover
            set={updateCultivationSite(form, fieldPath)}
            toggle={setCultSiteToggle}
          />
        }
        visible={otherCultSiteToggle}
      />
      <Form.Item
        name={[field.name, "cultivationSite"]}
        fieldKey={[field.fieldKey, "cultivationSite"]}
        label="Sitio del cultivo"
      >
        <Select
          options={cultivationSiteOptions}
          onChange={(value) => {
            if (value === "other") {
              setCultSiteToggle(true);
            } else {
              setCultSiteToggle(false);
            }
          }}
        />
      </Form.Item>
      <Popover
        placement="bottom"
        title="Otro reporte del cultivo"
        content={
          <InfPopover
            set={(value: any) => {
              setCultureValue(value);
            }}
            toggle={setOtherCultReportToggle}
            isGramMenu={true}
            setGram={{
              setGramPosMicroorgsSet: setGramPosMicroorgsSet,
              setGramNegMicroorgsSet: setGramNegMicroorgsSet,
            }}
          />
        }
        visible={oherCultReportToggle}
      >
        <Form.Item
          name={[field.name, "cropReportName"]}
          fieldKey={[field.fieldKey, "cropReportKey"]}
          label="Reporte del cultivo"
        >
          <Cascader
            options={[
              {
                value: "Gram positive",
                label: "Gram positivo",
                children: GramPosMicroorgOptions,
              },
              {
                value: "Gram negative",
                label: "Gram negativo",
                children: GramNegMicroorgOptions,
              },
              {
                value: "Respiratory Virus",
                label: "Virus Respiratorio",
                children: RespiratoryVirusesOptions,
              },
              {
                value: "Other",
                label: "Otro",
                children: MiscMicroorgOptions,
              },
            ]}
            placeholder="Seleccionar un microorganismo"
            expandTrigger="hover"
            showSearch={{ filter }}
            onChange={(value: any) => setCultureValue(value)}
            displayRender={(label, selectedOptions) => {
              return typeof label === "string" ? [label] : label[1];
            }}
          />
        </Form.Item>
      </Popover>
      <Space direction="vertical" size={16}>
        Microorganismo Sensibilidad
      </Space>
      <MicroOrgSensitivities field={field} microOrgInfo={microOrgInfo} />
    </Card>
  );
};

export default CultureDetails;
