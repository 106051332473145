import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
} from "../util/misc";
import { Form, Row, Space, Col, Checkbox, Input } from "antd";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import FormSkeleton from "../components/FormSkeleton";
import { OutcomeOutcomeFields } from "../util/OutcomeFormUtil";
import OutcomeFields from "../components/outcome-form/OutcomeFields";
import moment from "moment";

const OutcomeFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isAbandoned, setIsAbandoned] = useState(false)
  const [form] = Form.useForm();
  const history = useHistory();
  const formName = "OutcomeForm"

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data.form) {
        setIsLoading(false);
        return;
      }
      const values = data["form"];
      const prevDoctorValues = data["prev_doctor"];
      setPrevDoctor(prevDoctorValues);
      OutcomeOutcomeFields.forEach((outcomeField) => {
        const date = values[outcomeField.dateName];
        values[outcomeField.dateName] = moment(date).utc();
      });
      setInitialValues(values);
      setIsLoading(false);
      form.resetFields(Object.keys(values));
    })();
  }, []);

  const onFinish = async (values: any) => {
    if (!values) return;
    if (isSubmitting) return
    else setIsSubmitting(true)
    setIsLoading(true);
    await submitForm({ values: values, patientId: patientId, formId: formId  }, formName);
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="data-form"
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Formulario de Desenlaces"
        />
        <Row>
          <Row style={{ display: "inline-block", width: "83%"}}>
            {OutcomeOutcomeFields.map((outcomeField) => (
              <OutcomeFields outcomeField={outcomeField} form={form} />
            ))}
          </Row>
          <Row style={{ width: "83%"}} align="bottom">
            <Col span={8}>
              <Form.Item
                key={"abandoned"}
                name={"abandoned"}
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox
                  onChange={(value) => {
                    if (!value.target.checked) {
                      form.setFieldsValue({ ["abandonedReason"]: null });
                    }
                    setIsAbandoned(value.target.checked);
                  }}
                > Abandono
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                key={"abandonedReason"}
                name={"abandonedReason"}
                label={"Razón"}
                rules={[{ required: isAbandoned }]}
              >
                <Input type="text" disabled={!isAbandoned} />
              </Form.Item>
            </Col>
          </Row>
        </Row>
        <Row justify="center" align="middle">
          <Space size={30}>
            <Form.Item key="date"></Form.Item>
          </Space>
        </Row>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  );
};

export default OutcomeFormPage;
