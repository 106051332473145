import { formBuilderField } from "./formBuilder";

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
  };
  return newValues;
};

const depressionOptions = [
  { label: "0: Rara vez o nunca (menos de un día)", value: "0: Rara vez o nunca (menos de un día)"},
  { label: "1: Pocas veces o alguna vez (uno o dos días)", value: "1: Pocas veces o alguna vez (uno o dos días)"},
  { label: "2: Un número de veces considerable (tres o cuatro días)", value: "2: Un número de veces considerable (tres o cuatro días)"},
  { label: "3: Todo el tiempo o la mayoría del tiempo (cinco a siete días)", value: "3: Todo el tiempo o la mayoría del tiempo (cinco a siete días)"},
]

const mobileFamilyOptions = [
  { label: "0: Nunca", value: "0: Nunca"},
  { label: "1: Rara vez", value: "1: Rara vez"},
  { label: "2: Algunas veces", value: "2: Algunas veces"},
  { label: "3: Bastantes veces", value: "3: Bastantes veces"},
  { label: "4: Casi siempre", value: "4: Casi siempre"},
]
const mobileStressOptions = [
  { label: "0: Nunca", value: "0: Nunca"},
  { label: "1: Casi Nunca", value: "1: Casi Nunca"},
  { label: "2: De vez en cuando", value: "2: De vez en cuando"},
  { label: "3: A menudo", value: "3: A menudo"},
  { label: "4: Muy a menudo", value: "4: Muy a menudo"},
]

export const RelationshipToPatientField: formBuilderField[] = [
  {
    keyName: "relationship_to_patient",
    type: "select",
    label: "¿Cuál es la relación con el paciente?",
    options: [
      { label: 'Madre', value: 'Madre'},
      { label: 'Padre', value: 'Padre'},
      { label: 'Hermano/Hermana', value: 'Hermano/Hermana'},
      { label: 'Tía/Tío', value: 'Tía/Tío'},
      { label: 'Abuela/Abuelo', value: 'Abuela/Abuelo'},
      { label: 'Amigos', value: 'Amigos'},
      { label: 'Vecinos', value: 'Vecinos'},
      { label: 'Otro', value: 'other'},
    ]
  }
]

export const MobileDepressionFormFields: formBuilderField[] = [
  ...RelationshipToPatientField,
  {
    keyName: "OverwhelmedBySadness",
    type: "select",
    label: "¿Sentía como si no pudiera quitarse de encima la tristeza?",
    options: depressionOptions,
  },
  {
    keyName: "DifficultyConcentrating",
    type: "select",
    label: "¿Le costaba concentrarse en lo que estaba haciendo?",
    options: depressionOptions,
  },
  {
    keyName: "FeelingDepressed",
    type: "select",
    label: "¿Se sintió deprimido?",
    options: depressionOptions,
  },
  {
    keyName: "EverythingIsAnEffort",
    type: "select",
    label: "¿Le parecía que todo lo que hacía era un esfuerzo?",
    options: depressionOptions,
  },
  {
    keyName: "SleepWellness",
    type: "select",
    label: "¿No durmió bien",
    options: depressionOptions,
  },
  {
    keyName: "EnjoyingLife",
    type: "select",
    label: "¿Disfruto de la vida",
    options: depressionOptions,
  },
  {
    keyName: "FeelingSad",
    type: "select",
    label: "¿Se sintió triste",
    options: depressionOptions,
  },
];

export const MobileFamilyFormFields: formBuilderField[] = [
  ...RelationshipToPatientField,
  {
    keyName: "MoreHelp",
    type: "select",
    label: "¿Piensa que su familiar le pide más ayuda de la que realmente necesita?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "NoPersonalTime",
    type: "select",
    label: "¿Sientes que debido al tiempo que dedica a su familiar no tiene suficiente tiempo para usted?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "TensionWhenCaring",
    type: "select",
    label: "¿Te sientes tenso/acuando tienes que cuidar a tu familiar y además atender otras responsabilidades?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "FamilyShame",
    type: "select",
    label: "¿Te sientes avergonzado/a por la conducta de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "FamilyAnger",
    type: "select",
    label: "¿Te sientes enfadado/a cuando estás cerca de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "NegativeRelations",
    type: "select",
    label: "¿Crees que la situación actual afecta de manera negativa a tu relación con amigos y otros miembros de tu familia?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "ScaredOfFuture",
    type: "select",
    label: "¿Tienes miedo por el futuro que le espera a tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "Depends",
    type: "select",
    label: "¿Sientes que tu familiar depende de tí?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "Overwhelmed",
    type: "select",
    label: "¿Te sientes agobiado/a cuando tienes que estar cerca de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "Unhealthy",
    type: "select",
    label: "¿Sientes que tu salud se ha resentido por cuidar de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "Privacy",
    type: "select",
    label: "¿Sientes que no tienes la vida privada que desearías debido a tener que cuidar de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "SocialLife",
    type: "select",
    label: "¿Crees que tu vida social se ha visto afectada por tener que cuidar de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "UncomfortableInviting",
    type: "select",
    label: "¿Te sientes incómodo/a para invitar amigos a casa, a causa de tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "OnlyCaretaker",
    type: "select",
    label: "¿Crees que tu familiar espera que tú le cuides, como si fueras la única persona con la que puede contar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "NotEnoughMoney",
    type: "select",
    label: "¿Crees que no dispones de dinero suficiente para cuidar a tu familiar, además de tus otros gastos?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "UnableToCare",
    type: "select",
    label: "¿Sientes que serás incapaz de cuidar a tu familiar por mucho más tiempo?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "NoControl",
    type: "select",
    label: "¿Sientes que has perdido el control sobre tu vida desde que la enfermedad de tu familiar se manifestó?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "ChangeCaregiver",
    type: "select",
    label: "¿Desearías poder encargar el cuidado de tu familiar a otras personas?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "Insecure",
    type: "select",
    label: "¿Te sientes inseguro acerca de lo que debes hacer con tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "MoreEffort",
    type: "select",
    label: "¿Sientes que deberías hacer más de lo que haces por tu familiar?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "BetterCare",
    type: "select",
    label: "¿Crees que podrías cuidar de tu familiar mejor de lo que haces?",
    options: mobileFamilyOptions,
  },
  {
    keyName: "DegreeOfBurden",
    type: "select",
    label: "En general, ¿qué grado de carga experimentas por el hecho de cuidar a tu familiar?",
    options: [
      { label: "0: Nada", value: "0: Nada"},
      { label: "1: Poca", value: "1: Poca"},
      { label: "2: Algo", value: "2: Algo"},
      { label: "3: Mucha", value: "3: Mucha"},
      { label: "4: Demasiada", value: "4: Demasiada"},
    ],
  },
];

export const MobileStressFormFields: formBuilderField[] = [
  ...RelationshipToPatientField,
  {
    keyName: "FrequencyUnexpected",
    type: "select",
    label: "¿Con qué frecuencia has estado afectado/a por algo que ha ocurrido inesperadamente?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyInability",
    type: "select",
    label: "¿Con qué frecuencia te has sentido incapaz de controlar las cosas importantes de tu vida?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyNervious",
    type: "select",
    label: "¿Con qué frecuencia te has sentido nervioso/a o estresado/a (lleno de tensión)?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyCantDeal",
    type: "select",
    label: "¿Con qué frecuencia has sentido que no podías afrontar todas las cosas que tenías que hacer?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyAngerNoControl",
    type: "select",
    label: "¿Con qué frecuencia has estado enfadado/a porque las cosas que te han ocurrido estaban fuera de tu control?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyUnfinishedThings",
    type: "select",
    label: "¿Con qué frecuencia has pensado sobre las cosas que no has terminado (pendientes de hacer)?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyAccumulateIssues",
    type: "select",
    label: "¿Con qué frecuencia has sentido que las dificultades se acumulan tanto que no puedes superarlas?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencySuccessDealing",
    type: "select",
    label: "¿Con qué frecuencia has manejado con éxito los pequeños problemas irritantes de la vida?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyEffectiveDealingChanges",
    type: "select",
    label: "¿Con qué frecuencia has sentido que has afrontado efectivamente los cambios importantes que han estado ocurriendo en tu vida?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyCapacityConfidence",
    type: "select",
    label: "¿Con qué frecuencia has estado seguro/a sobre tu capacidad de manejar tus problemas personales?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyThingsGoWell",
    type: "select",
    label: "¿Con qué frecuencia has sentido que las cosas te van bien?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyControlDifficulties",
    type: "select",
    label: "¿Con qué frecuencia has podido controlar las dificultades de tu vida?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyControlEverything",
    type: "select",
    label: "¿Con qué frecuencia has sentido que tienes el control de todo?",
    options: mobileStressOptions,
  },
  {
    keyName: "FrequencyControlPasstime",
    type: "select",
    label: "¿Con qué frecuencia has podido controlar la forma de pasar el tiempo (organizar)?",
    options: mobileStressOptions,
  },
]