import { Spin } from "antd";

const CenterSpinner = () => {
  return <Spin size="large" style={{ 
    display: "block", 
    margin: "0 auto", 
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%)"
  }} />;
};

export default CenterSpinner;
