import { Row, Col } from "antd";
import heroImg from "../images/HIMFG.png"

const PrivacyPolicyPage = () => {

  return (
    <>
      <Row justify="center" align="middle">
        <div className="heroImg privacy" >
          <img src={heroImg} alt="HIMFG" />
        </div>
      </Row>
      <Row justify="center" align="middle">
        <Col className="privacyContainer" span={20}>
          <h1>HealthAppMD – family</h1>
          <h1>Política de privacidad para interfase familiar</h1>
          <h3>Política de privacidad</h3>
          <p>Nuestra Política de privacidad se actualizó por última vez el 12 de julio de 2022.</p>
          <p>Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.</p>
          <p>Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de Privacidad.</p>
          <h4>Interpretación y Definiciones</h4>
          <h5>Interpretación</h5>
          <p>Las palabras en las que la letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</p>
          <h5>Definiciones</h5>
          <p>A los efectos de esta Política de Privacidad:</p>
          <ul>
            <li><b>" Cuenta "</b> significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.</li>
            <li><b>" Aplicación "</b> significa el programa de software proporcionado por LFIDS descargado por Usted en cualquier dispositivo electrónico, denominado HealthAppMD - Family</li>
            <li><b>" LFIDS "</b> (referido como "el Instituto", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere al Lucy Family Institute for Data and Society en la Universidad de Notre Dame Du Lac</li>
            <li><b>" País "</b> se refiere a Estados Unidos de Norte América (por sus siglas en inglés, EE.UU).</li>
            <li><b>" cookies "</b> son pequeños archivos que un sitio web coloca en su computadora, dispositivo móvil o cualquier otro dispositivo y que contienen los detalles de su historial de navegación en ese sitio web, entre sus múltiples usos.</li>
            <li><b>" Dispositivo "</b> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</li>
            <li><b>" Datos personales "</b> es cualquier información que se relaciona con un individuo identificado o identificable.</li>
            <li><b>" Servicio "</b> se refiere a la Plataforma de Salud Hospital Infantil de México Federico Gómez (HIMFG).</li>
            <li><b>" Proveedor de Servicios "</b> significa cualquier persona natural o jurídica que trate los datos por cuenta del HIMFG. Se refiere a empresas de terceros o personas empleadas por la Empresa para facilitar el Servicio, prestar el Servicio en nombre de la Empresa, realizar servicios relacionados con el Servicio o ayudar al Instituto a analizar cómo se utiliza el Servicio.</li>
            <li><b>" Datos de uso "</b> se refiere a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).</li>
            <li><b>" Usted "</b> significa la persona que accede o usa el Servicio, o el HIMFG, u otra entidad legal en nombre de la cual dicha persona accede o usa el Servicio, según corresponda.</li>
          </ul>
          <h3>Recopilación y uso de sus datos personales</h3>
          <h4>Tipos de datos recopilados</h4>
          <h5>Información personal</h5>
          <p>Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede usar para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:</p>
          <ul>
            <li>Dirección de correo electrónico.</li>
            <li>Nombre y apellidos.</li>
            <li>CURP.</li>
            <li>Número de teléfono.</li>
            <li>Dirección, estado, ciudad, código postal.</li>
            <li>Datos de uso.</li>
          </ul>
          <h5>Datos de uso</h5>
          <p>Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</p>
          <p>Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo (por ejemplo , la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, dispositivo único identificadores y otros datos de diagnóstico.</p>
          <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de diagnóstico.</p>
          <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</p>
          <h5>Información recopilada durante el uso de la aplicación</h5>
          <p>Al utilizar Nuestra Aplicación, con el fin de proporcionar características de Nuestra Aplicación, podemos recopilar, con Su permiso previo:</p>
          <ul>
            <li>Información sobre su ubicación.
              <br></br><br></br>
              Utilizamos esta información para proporcionar características de Nuestro Servicio, y para mejorar y personalizar Nuestro Servicio. La información se puede cargar en los servidores de la Compañía y/o en el servidor de un Proveedor de servicios o simplemente se puede almacenar en Su dispositivo.
            </li>
          </ul>
          <p>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de Su dispositivo.</p>
          <h3>Uso de sus datos personales</h3>
          <p>El Instituto podrá utilizar los Datos Personales para las siguientes finalidades:</p>
          <ul>
            <li><b>Para proporcionar y mantener nuestro Servicio,</b> incluso para monitorear el uso de nuestro Servicio.</li>
            <li><b>Para gestionar Su Cuenta:</b> para gestionar Su registro como usuario del Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para Usted como usuario registrado.</li>
            <li><b>Para contactarlo:</b> para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones automáticas de una aplicación móvil con respecto a actualizaciones o comunicaciones informativas relacionadas con las funcionalidades del Servicio, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable. para su implementación.</li>
            <li><b>Para brindar servicios de atención médica:</b> la información recopilada a través de esta aplicación se comparte con proveedores de atención médica (HIMFG) y, en algunos casos, puede usarse para estimar el riesgo de desarrollar trastornos mentales. Es posible que el personal de atención médica se comunique con usted y lo derive a un hospital especializado para que reciba atención médica, manteniendo la confidencialidad de su información.</li>
            <li><b>Para gestionar Sus solicitudes:</b> Para atender y gestionar Sus solicitudes hacia Nosotros.</li>
            <li><b>Para otros fines:</b> podemos utilizar su información para otros fines, como el análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de las campañas promocionales de nuestro Servicio y la evaluación y mejora de nuestros servicios, marketing y su experiencia.</li>
          </ul>
          <p>Podemos compartir su información personal en las siguientes situaciones:</p>
          <ul>
            <li><b>Con Proveedores de Servicios:</b> Podemos compartir Su información personal con Proveedores de Servicios para monitorear y analizar el uso de nuestro Servicio, y para contactarlo.</li>
            <li><b>Para transferencias comerciales:</b> Podemos compartir o transferir Su información personal en relación con, o durante las negociaciones de, cualquier fusión, financiación o adquisición de todo o parte de Nuestro Servicio a otra empresa.</li>
            <li><b>Con afiliados:</b> podemos compartir su información con nuestros afiliados, en cuyo caso exigiremos a esos afiliados que respeten esta Política de privacidad. Los afiliados incluyen la Universidad de Notre Dame Du Lac (Nuestra Universidad) y cualquier otra subsidiaria, u otras organizaciones que Nosotros controlamos o que están bajo control común con Nosotros.</li>
            <li><b>Con sus proveedores de servicios médicos:</b> Podemos compartir Su información con sus proveedores de servicios médicos.</li>
            <li><b>Con otros usuarios:</b> cuando comparte información personal o interactúa de otra manera en el Servicio, dicha información puede ser vista por otros usuarios autorizados, como sus proveedores médicos y trabajadores de la salud, y puede distribuirse fuera del Servicio dentro del sistema de información de salud de su proveedor.</li>
            <li><b>Con su consentimiento:</b> podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</li>
          </ul>
          <h3>Retención de sus datos personales</h3>
          <p>El Instituto conservará Sus Datos Personales únicamente durante el tiempo que sea necesario para los fines establecidos en esta Política de Privacidad. Conservaremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas, hacer cumplir nuestras políticas y acuerdos legales.</p>
          <p>El Instituto también conservará los Datos de uso para fines de análisis interno. Los datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se usan para fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>
          <h3>Transferencia de sus datos personales</h3>
          <p>Su información, incluidos los Datos personales, se procesa en las oficinas operativas del Instituto en los Estados Unidos y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de Su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de Su jurisdicción.</p>
          <p>Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su acuerdo con esa transferencia.</p>
          <p>El Instituto tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus datos personales a una organización o país a menos que existan controles adecuados establecidos, incluida la seguridad de Sus datos y otra información personal.</p>
          <h3>Divulgación de sus datos personales</h3>
          <h4>Transacciones de negocios</h4>
          <p>Si el Servicio está involucrado en una fusión, adquisición o venta de activos, Sus Datos personales pueden transferirse. Le enviaremos un aviso antes de que sus Datos personales se transfieran y queden sujetos a una Política de privacidad diferente.</p>
          <h4>Cumplimiento de la ley</h4>
          <p>En determinadas circunstancias, es posible que el Instituto deba divulgar sus Datos personales si así lo requiere la ley o en respuesta a solicitudes válidas de autoridades (por ejemplo, un tribunal o una agencia gubernamental).</p>
          <h4>Otros requisitos legales</h4>
          <p>El Instituto puede divulgar sus datos personales de buena fe cuando considere que esta acción es necesaria para lo siguiente:</p>
          <ul>
            <li>Cumplir con una obligación legal.</li>
            <li>Proteger y defender los derechos o bienes del Instituto.</li>
            <li>Prevenir o investigar posibles irregularidades en relación con el Servicio.</li>
            <li>Proteger la seguridad personal de los Usuarios del Servicio o del público.</li>
            <li>Protéjase contra la responsabilidad legal.</li>
          </ul>
          <h3>Seguridad de sus datos personales</h3>
          <p>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</p>
          <h3>Información detallada sobre el procesamiento de sus datos personales</h3>
          <p>Los proveedores de servicios en la nube que utilizamos pueden tener acceso a sus datos personales. Estos proveedores externos recopilan, almacenan, usan, procesan y transfieren información sobre Su actividad en Nuestro Servicio de acuerdo con sus Políticas de privacidad.</p>
          <h4>Analítica</h4>
          <p>Podemos utilizar proveedores de Servicios de terceros para monitorear y analizar el uso de nuestro Servicio.</p>
          <h3>Privacidad de los niños</h3>
          <p>Nuestro Servicio puede tratar los datos de menores de edad. Si necesitamos basarnos en el consentimiento y asentimiento, cuando aplique, como base legal para procesar su información y su país requiere el consentimiento de uno de sus padres, podemos solicitar el consentimiento de su padre antes de recopilar y usar esa información.</p>
          <h3>Enlaces a otros sitios web</h3>
          <p>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</p>
          <p>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.</p>
          <h3>Cambios a esta Política de Privacidad</h3>
          <p>Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.</p>
          <p>Le informaremos por correo electrónico y/o un aviso destacado en Nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de privacidad.</p>
          <p>Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.</p>
          <h3>Contáctenos</h3>
          <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</p>
          <ul>
            <li>Enviándonos un correo electrónico: aetl-list@nd.edu</li>
          </ul>
        </Col>
      </Row>
    </>
  ) 
};

export default PrivacyPolicyPage;
