import { FormInstance } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import moment from "moment";
import { Moment } from "moment";
import { OptionType } from "../components/OptionsField";

export const calcAge = (dob: Moment) => {
  return moment().diff(dob, "years");
};
export const calcDays = (day1: Moment, day2: Moment) => {
  if (!day1 || !day2) {
    return 0;
  }
  return Math.abs(day1.diff(day2, "days"));
};
export const DischargeStatusOptions: OptionType<"alive" | "dead">[] = [
  { label: "Vivo", value: "alive" },
  { label: "Muerto", value: "dead" },
];

export const YesNoDescOptions: OptionType<"yes" | "no" | "unknown">[] = [
  { label: "Si", value: "yes" },
  { label: "No", value: "no" },
  { label: "Desc", value: "unknown" },
];

export const TreatmentPhaseOptions: OptionType<
  "induction" | "consolidation" | "maintenance" | "relapse"
>[] = [
  { label: "Inducción", value: "induction" },
  { label: "Consolidación", value: "consolidation" },
  { label: "Mantenimiento", value: "maintenance" },
  { label: "Recaída", value: "relapse" },
];

export const CancerTypeOptions: OptionType<
  | "acute lymphoblastic leukemia"
  | "acute myeloid leukemia"
  | "another leukemia"
  | "hodgkin's lymphoma"
  | "non-hodgkin lymphoma"
  | "low-grade brain tumor (i-ii)"
  | "high-grade brain tumor (iii-iv)"
  | "high risk neuroblastoma"
  | "another neuroblastoma"
  | "wilms tumor"
  | "another kidney tumor"
  | "hepatoblastoma"
  | "another liver tumor"
  | "osteosarcoma"
  | "ewing's sarcoma"
  | "rhabdomyosarcoma"
  | "another sarcoma"
  | "germ / gonadal cell neoplasia"
  | "carcinoma / melanoma"
  | "lch"
  | "other"
  | "unknown"
>[] = [
  {
    label: "Leucemia linfoblástica aguda",
    value: "acute lymphoblastic leukemia",
  },
  { label: "Leucemia mieloide aguda", value: "acute myeloid leukemia" },
  { label: "Otra leucemia", value: "another leukemia" },
  { label: "Linfoma de Hodgkin", value: "hodgkin's lymphoma" },
  { label: "Linfoma No-Hodgkin", value: "non-hodgkin lymphoma" },
  {
    label: "Tumor cerebral de bajo grado (I-II)",
    value: "low-grade brain tumor (i-ii)",
  },
  {
    label: "Tumor cerebral de Alto grado (III-IV)",
    value: "high-grade brain tumor (iii-iv)",
  },
  {
    label: "Neuroblastoma de ‘alto riesgo’",
    value: "high risk neuroblastoma",
  },
  { label: "Otro neuroblastoma", value: "another neuroblastoma" },
  { label: "Tumor de Wilms", value: "wilms tumor" },
  { label: "Otro tumor renal", value: "another kidney tumor" },
  { label: "Hepatoblastoma", value: "hepatoblastoma" },
  { label: "Otro tumor hepático", value: "another liver tumor" },
  { label: "Osteosarcoma", value: "osteosarcoma" },
  { label: "Sarcoma de Ewing", value: "ewing's sarcoma" },
  { label: "Rabdomiosarcoma", value: "rhabdomyosarcoma" },
  { label: "Otro sarcoma", value: "another sarcoma" },
  {
    label: "Neoplasia de células germinales/gonadales",
    value: "germ / gonadal cell neoplasia",
  },
  { label: "Carcinoma/melanoma", value: "carcinoma / melanoma" },
  { label: "LCH", value: "lch" },
  { label: "Otro", value: "other" },
  { label: "Desconocido ", value: "unknown" },
];

export type ChemoMedTypes =
  | "actinomycin"
  | "doxorubicin"
  | "cytarabine"
  | "L-asparaginase"
  | "cyclophosphamide"
  | "cisplatin"
  | "daunorubicin"
  | "high doses of methotrexate"
  | "mercaptopurine"
  | "prednisone"
  | "vincristine"
  | "etoposide"
  | "ifosfamide"
  | "carboplatin";
export const ChemoMedOptions: OptionType<ChemoMedTypes>[] = [
  { label: "ACT: actinomicina", value: "actinomycin" },
  { label: "ADR: doxorrubicina", value: "doxorubicin" },
  { label: "ARA C: citarabina", value: "cytarabine" },
  { label: "L-ASP: L-asparginasa", value: "L-asparaginase" },
  { label: "CFS: ciclofosfamida", value: "cyclophosphamide" },
  { label: "CISP: cisplatino", value: "cisplatin" },
  { label: "DNR: daunorrubicina", value: "daunorubicin" },
  {
    label: "AD-MTX: altas dosis de metotrexate",
    value: "high doses of methotrexate",
  },
  { label: "6-MP: mercaptopurina", value: "mercaptopurine" },
  { label: "PRD: prednisona", value: "prednisone" },
  { label: "VCR: vincristina", value: "vincristine" },
  { label: "VP-16: etopósido", value: "etoposide" },
  { label: "IFO: ifosfamida", value: "ifosfamide" },
  { label: "CAR: carboplatino", value: "carboplatin" },
];

export const medsInSlice = (
  chemoMedications: Set<ChemoMedTypes | CheckboxValueType>,
  begin: number,
  end: number
) => {
  const options = ChemoMedOptions.slice(begin, end).map(
    (option) => option.value
  );
  const medsInSlice: ChemoMedTypes[] = [];
  chemoMedications.forEach((med) => {
    if (options.indexOf(med as ChemoMedTypes) >= 0)
      medsInSlice.push(med as ChemoMedTypes);
  });
  return medsInSlice;
};

export const onChemoMedChange = (
  values: CheckboxValueType[],
  chemoMedications: Set<ChemoMedTypes | CheckboxValueType>,
  begin: number,
  end: number
) => {
  values.forEach((value) => {
    if (!((value as string) in chemoMedications)) {
      chemoMedications.add(value);
    }
    ChemoMedOptions.slice(begin, end).forEach((option) => {
      if ((values as string[]).indexOf(option.value) < 0) {
        chemoMedications.delete(option.value);
      }
    });
  });
};

export const AntiBacterialOptions: OptionType<"tmp/sms" | "levofloxacin">[] = [
  { label: "TMP/SMX", value: "tmp/sms" },
  { label: "Levofloxacino", value: "levofloxacin" },
];

export const AntiFungalOptions: OptionType<"nystatin" | "fluconazole">[] = [
  { label: "Nistatina", value: "nystatin" },
  { label: "Fluconazol", value: "fluconazole" },
];

export const CatheterTypeOptions: OptionType<
  "peripheral" | "central" | "port" | "picc"
>[] = [
  { label: "Periférico", value: "peripheral" },
  { label: "Central", value: "central" },
  { label: "Puerto", value: "port" },
  { label: "PICC", value: "picc" },
];
export const DiagnosisOptions: OptionType<
  | "fever and neutropenia without focus"
  | "pneumonia"
  | "neutropenic colitis"
  | "infectious gastroenteritis"
  | "septic shock"
  | "uti"
  | "skin and soft tissue infection"
  | "proctitis"
  | "meningitis"
  | "ventriculitis"
  | "varicella"
  | "bacteremia confirmed"
  | "cvc-associated infection"
  | "IVAS"
  | "Sinusitis"
  | "otitis"
  | "mastoiditis"
  | "influenza"
  | "nosocomial sepsis"
  | "covid-19"
  | "herpes zoster"
  | "invasive fungal infection"
  | "other"
>[] = [
  {
    label: "Fiebre y neutropenia sin foco",
    value: "fever and neutropenia without focus",
  },
  { label: "Neumonía", value: "pneumonia" },
  { label: "Colitis neutropénica", value: "neutropenic colitis" },
  { label: "Gastroenteritis infecciosa", value: "infectious gastroenteritis" },
  { label: "Choque séptico", value: "septic shock" },
  { label: "IVU", value: "uti" },
  {
    label: "Infección de piel y tejidos blandos",
    value: "skin and soft tissue infection",
  },
  { label: "Proctitis", value: "proctitis" },
  { label: "Meningitis", value: "meningitis" },
  { label: "Ventriculitis", value: "ventriculitis" },
  { label: "Varicela", value: "varicella" },
  { label: "Bacteriemia corroborada", value: "bacteremia confirmed" },
  { label: "Infección asociada a CVC", value: "cvc-associated infection" },
  { label: "IVAS", value: "IVAS" },
  { label: "Otitis", value: "Sinusitis" },
  { label: "Sinusitis", value: "otitis" },
  { label: "Mastoiditis", value: "mastoiditis" },
  { label: "Influenza", value: "influenza" },
  { label: "Sepsis nosocomial", value: "nosocomial sepsis" },
  { label: "COVID-19", value: "covid-19" },
  { label: "Infección fúngica invasora", value: "herpes zoster" },
  { label: "Herpes zoster", value: "invasive fungal infection" },
  { label: "Otro", value: "other" },
];

export const PcrOptions: OptionType<
  | "adenovirus"
  | "bocavirus"
  | "coronavirus 229E"
  | "coronavirus OC43"
  | "coronavirus NL63"
  | "coronavirus HKU1"
  | "rinovirus/Enterovirus"
  | "influenza A"
  | "influenza A H1"
  | "influenza A H3"
  | "influenza B"
  | "metapneumovirus"
  | "parainfluenza 1"
  | "parainfluenza 2"
  | "parainfluenza 3"
  | "parainfluenza 4"
  | "vsr A"
  | "vsr B"
  | "mycoplasma pneumoniae"
  | "chlamydophila pneumoniae"
  | "legionella pneumpphila"
>[] = [
  { label: "Adenovirus", value: "adenovirus" },
  { label: "Bocavirus", value: "bocavirus" },
  { label: "Coronavirus 229E", value: "coronavirus 229E" },
  { label: "Coronavirus OC43", value: "coronavirus OC43" },
  { label: "Coronavirus NL63", value: "coronavirus NL63" },
  { label: "Coronavirus HKU1", value: "coronavirus HKU1" },
  { label: "Rinovirus/Enterovirus", value: "rinovirus/Enterovirus" },
  { label: "Influenza A", value: "influenza A" },
  { label: "Influenza A H1", value: "influenza A H1" },
  { label: "Influenza A H3", value: "influenza A H3" },
  { label: "Influenza B", value: "influenza B" },
  { label: "Metapneumovirus", value: "metapneumovirus" },
  { label: "Parainfluenza 1", value: "parainfluenza 1" },
  { label: "Parainfluenza 2", value: "parainfluenza 2" },
  { label: "Parainfluenza 3", value: "parainfluenza 3" },
  { label: "Parainfluenza 4", value: "parainfluenza 4" },
  { label: "VSR A", value: "vsr A" },
  { label: "VSR B", value: "vsr B" },
  { label: "Mycoplasma pneumoniae", value: "mycoplasma pneumoniae" },
  { label: "Chlamydophila pneumoniae", value: "chlamydophila pneumoniae" },
  { label: "Legionella pneumpphila", value: "legionella pneumpphila" },
];

export type AntiMicroData = {
  key: string;
  antibiotic: Antibiotics;
  editableInput: string;
  startDate: Moment | string | null;
  endDate: Moment | string | null;
  duration: number;
  administration: "oral" | "intravenous";
  dose: number;
};

export const InfectionFindingsOptions: OptionType<
  | "nodules"
  | "cavitation"
  | "consolidation"
  | "interstitial infiltrate"
  | "alveolar infiltrate"
  | "polished glass"
  | "halo sign"
>[] = [
  { label: "Nódulos", value: "nodules" },
  { label: "Cavitaciones", value: "cavitation" },
  { label: "Consolidación", value: "consolidation" },
  { label: "Infiltrado intersticial", value: "interstitial infiltrate" },
  { label: "Infiltrado alveolar", value: "alveolar infiltrate" },
  { label: "Vidrio despulido", value: "polished glass" },
  { label: "Signo del halo", value: "halo sign" },
];

export type SampleType =
  | "peripheral blood culture"
  | "central blood culture"
  | "mid-stream urine culture"
  | "catheter urine culture"
  | "collection bag urine culture"
  | "endotracheal aspirate"
  | "expectoration"
  | "puncture-aspiration"
  | "drainage of abscess"
  | "stool culture"
  | "catheter tip"
  | "pleural fluid"
  | "other"
  | "negative"
  | string;

export const SampleTypeOptions: OptionType<SampleType>[] = [
  { label: "Hemocultivos periféricos", value: "peripheral blood culture" },
  { label: "Hemocultivo central", value: "central blood culture" },
  { label: "Urocultivo por chorro medio", value: "mid-stream urine culture" },
  { label: "Urocultivo por sonda", value: "catheter urine culture" },
  {
    label: "Urocultivo por bolsa colectora",
    value: "collection bag urine culture",
  },
  { label: "Aspirado endotraqueal", value: "endotracheal aspirate" },
  { label: "Expectoración", value: "expectoration" },
  { label: "Punción-aspiración", value: "puncture-aspiration" },
  { label: "Drenaje de absceso", value: "drainage of abscess" },
  { label: "Coprocultivo", value: "stool culture" },
  { label: "Punta de catéter", value: "catheter tip" },
  { label: "Líquido pleural", value: "pleural fluid" },
  { label: "Otro", value: "other" },
  { label: "Negativo", value: "negative" },
];

const gramPosMicroorgs = [
  "staphylococcus aureus",
  "staphylococcus epidermidis",
  "staphylococcus hominis",
  "streptococcus pyogenes",
  "streptococcus viridans",
] as const;

export const gramPosMicroorgsSet = new Set(gramPosMicroorgs);

const gramNegMicroorgs = [
  "enterobacter cloacae",
  "enterobacter aerogenes",
  "e. coli",
  "klebsiella pneumoniae",
  "klebsiella oxytoca",
  "pseudomoas aeruginosa",
  "pseudomoas putida",
  "proteus mirabilis",
  "serratia marcescens",
  "acinetobacter baumannii",
  "acinetobacter iwoffi",
  "stenotrophomonas maltofilia",
  "achromobacter xylosoxidans",
  "salmonella spp",
  "citrobacter",
  "morganella morganii",
  "campylobacter jejuni",
  "aeromonas spp",
  "enterococcus faecium",
  "enterococcus fecalis",
  "candida sp",
  "listeria monocytogenes",
] as const;

export const gramNegMicroorgsSet = new Set(gramNegMicroorgs);

const respiratoryViruses = [
  "influenza",
  "parainfluenza",
  "coronavirus",
  "adenovirus",
  "rinovirus",
  "respiratory syncytial virus",
  "other",
] as const;

export const respiratoryVirusesSet = new Set(respiratoryViruses)

const miscMicroorgs = ["yeasts", "fusarium sp", "other", "negative"];
export const miscMicroorgsSet = new Set(miscMicroorgs);

export const allMicroorgsSet = new Set([
  ...miscMicroorgs,
  ...[gramNegMicroorgsSet],
  ...[gramPosMicroorgsSet],
  ...[respiratoryVirusesSet],
]);

const allMicroorgs = [
  ...gramPosMicroorgs,
  ...gramNegMicroorgs,
  ...respiratoryViruses,
  ...miscMicroorgs,
] as const;

export type Microorganisms = typeof allMicroorgs[number];
export type GramPosMicroorganisms = typeof gramPosMicroorgs[number];
export type GramNegMicroorganisms = typeof gramNegMicroorgs[number];
export type RespiratoryViruses = typeof respiratoryViruses[number];
export type MiscMicroorgs = typeof miscMicroorgs[number];
export type AllMicroOrgs = typeof allMicroorgs[number];

export const GramPosMicroorgOptions: OptionType<GramPosMicroorganisms>[] = [
  { label: "Staphylococcus aureus", value: "staphylococcus aureus" },
  { label: "Staphylococcus epidermidis", value: "staphylococcus epidermidis" },
  { label: "Staphylococcus hominis", value: "staphylococcus hominis" },
  { label: "Streptococcus pyogenes", value: "streptococcus pyogenes" },
  { label: "Streptococcus viridans", value: "streptococcus viridans" },
];

export const GramNegMicroorgOptions: OptionType<GramNegMicroorganisms>[] = [
  { label: "Enterobacter cloacae", value: "enterobacter cloacae" },
  { label: "Enterobacter aerogenes", value: "enterobacter aerogenes" },
  { label: "Enterococcus faecium", value: "enterococcus faecium" },
  { label: "Enterococcus fecalis", value: "enterococcus fecalis" },
  { label: "E. coli", value: "e. coli" },
  { label: "Klebsiella pneumoniae", value: "klebsiella pneumoniae" },
  { label: "Klebsiella oxytoca", value: "klebsiella oxytoca" },
  { label: "Pseudomoas aeruginosa", value: "pseudomoas aeruginosa" },
  { label: "Pseudomoas putida", value: "pseudomoas putida" },
  { label: "Proteus mirabilis", value: "proteus mirabilis" },
  { label: "Serratia marcescens", value: "serratia marcescens" },
  { label: "Acinetobacter baumannii", value: "acinetobacter baumannii" },
  { label: "Acinetobacter iwoffi", value: "acinetobacter iwoffi" },
  {
    label: "Stenotrophomonas maltofilia",
    value: "stenotrophomonas maltofilia",
  },
  {
    label: "Achromobacter xylosoxidans",
    value: "achromobacter xylosoxidans",
  },
  { label: "Salmonella spp", value: "salmonella spp" },
  { label: "Citrobacter", value: "citrobacter" },
  { label: "Morganella morganii", value: "morganella morganii" },
  { label: "Campylobacter jejuni", value: "campylobacter jejuni" },
  { label: "Aeromonas spp", value: "aeromonas spp" },
  { label: "Candida sp", value: "candida sp" },
  { label: "Listeria monocytogenes", value: "listeria monocytogenes" },
];

export const RespiratoryVirusesOptions: OptionType<RespiratoryViruses>[] = [
  { label: "Influenza", value: "influenza" },
  { label: "Parainfluenza", value: "parainfluenza" },
  { label: "Coronavirus", value: "coronavirus" },
  { label: "Rinovirus", value: "rinovirus" },
  { label: "Virus sincitial respiratorio", value: "respiratory syncytial virus" },
  { label: "Otro", value: "other" },
]

export const MiscMicroorgOptions: OptionType<MiscMicroorgs>[] = [
  { label: "Fusarium sp", value: "fusarium sp" },
  { label: "Levaduras", value: "yeasts" },
  { label: "Otro", value: "other" },
  { label: "Negativo", value: "negative" },
];

export const AllMicroorgOptions = [
  ...GramPosMicroorgOptions,
  ...GramNegMicroorgOptions,
  ...RespiratoryVirusesOptions,
  ...MiscMicroorgOptions,
];

export const getMicroOrgTranslations = () => {
  const translations: any = {};
  AllMicroorgOptions.forEach((microOrg) => {
    translations[microOrg.value] = microOrg.label;
  });
  return translations;
};

export type CultureData = {
  key: string;
  sampleType: SampleType | string;
  dateTaken: Moment | string | null;
  microorganism: Microorganisms;
};

type AntiMicrobialResponse = "sensible" | "resistant" | "not performed";
export const antiMicrobialResponseOptions: OptionType<AntiMicrobialResponse>[] =
  [
    { label: "Sensible", value: "sensible" },
    { label: "Resistente", value: "resistant" },
    { label: "No Realizado", value: "not performed" },
  ];

export const negResponseKeys = [
  "AN",
  "SAM",
  "BLEE",
  "FEP",
  "FOX",
  "CAZ",
  "CRO",
  "CIP",
  "CS",
  "DOR",
  "ERT",
  "GM",
  "IMP",
  "MEM",
  "TZP",
  "TGC",
  "LEVO",
  "SXT",
] as const;

export type GramNegAntimicrobialResponse = {
  // microorganism: OptionType<string>;
  AN: AntiMicrobialResponse;
  SAM: AntiMicrobialResponse;
  BLEE: AntiMicrobialResponse;
  FEP: AntiMicrobialResponse;
  FOX: AntiMicrobialResponse;
  CAZ: AntiMicrobialResponse;
  CRO: AntiMicrobialResponse;
  CIP: AntiMicrobialResponse;
  CS: AntiMicrobialResponse;
  DOR: AntiMicrobialResponse;
  ERT: AntiMicrobialResponse;
  GM: AntiMicrobialResponse;
  IMP: AntiMicrobialResponse;
  MEM: AntiMicrobialResponse;
  TZP: AntiMicrobialResponse;
  TGC: AntiMicrobialResponse;
  LEVO: AntiMicrobialResponse;
  SXT: AntiMicrobialResponse;
};

export const posResponseKeys = [
  "AM",
  "ST 1000",
  "GM 500",
  "CIP",
  "CM",
  "DAP",
  "SFOX",
  "DO",
  "E",
  "GM",
  "LEVO",
  "LNZ",
  "MXF",
  "FT",
  "OXA",
  "RA",
  "TE",
  "TGL",
  "SXT",
  "VA",
] as const;

export type PosKeys = typeof posResponseKeys[number];
export type NegKeys = typeof negResponseKeys[number];

export type GramPosAntimicrobialResponse = {
  // microorganism: OptionType<string>;
  AM: AntiMicrobialResponse;
  "ST 1000": AntiMicrobialResponse;
  "GM 500": AntiMicrobialResponse;
  CIP: AntiMicrobialResponse;
  CM: AntiMicrobialResponse;
  DAP: AntiMicrobialResponse;
  SFOX: AntiMicrobialResponse;
  DO: AntiMicrobialResponse;
  E: AntiMicrobialResponse;
  GM: AntiMicrobialResponse;
  LEVO: AntiMicrobialResponse;
  LNZ: AntiMicrobialResponse;
  MXF: AntiMicrobialResponse;
  FT: AntiMicrobialResponse;
  OXA: AntiMicrobialResponse;
  RA: AntiMicrobialResponse;
  TE: AntiMicrobialResponse;
  TGL: AntiMicrobialResponse;
  SXT: AntiMicrobialResponse;
  VA: AntiMicrobialResponse;
};

export const newNegMicroorgObj = (): // microorganism: OptionType<string>
GramNegAntimicrobialResponse => {
  return {
    // microorganism: { label: microorganism.label, value: microorganism.value },
    AN: "not performed",
    SAM: "not performed",
    BLEE: "not performed",
    FEP: "not performed",
    FOX: "not performed",
    CAZ: "not performed",
    CRO: "not performed",
    CIP: "not performed",
    CS: "not performed",
    DOR: "not performed",
    ERT: "not performed",
    GM: "not performed",
    IMP: "not performed",
    MEM: "not performed",
    TZP: "not performed",
    TGC: "not performed",
    LEVO: "not performed",
    SXT: "not performed",
  };
};

type GramNoneAntiMicrobialResponse = "";
export const newNoneMicroorgObj = (): GramNoneAntiMicrobialResponse => "";

export const newPosMicroorgObj = (): // microorganism: OptionType<string>
GramPosAntimicrobialResponse => {
  return {
    // microorganism: { label: microorganism.label, value: microorganism.value },
    AM: "not performed",
    "ST 1000": "not performed",
    "GM 500": "not performed",
    CIP: "not performed",
    CM: "not performed",
    DAP: "not performed",
    SFOX: "not performed",
    DO: "not performed",
    E: "not performed",
    GM: "not performed",
    LEVO: "not performed",
    LNZ: "not performed",
    MXF: "not performed",
    FT: "not performed",
    OXA: "not performed",
    RA: "not performed",
    TE: "not performed",
    TGL: "not performed",
    SXT: "not performed",
    VA: "not performed",
  };
};

export type Antibiotics =
  | "cefotaxime"
  | "ceftriaxone"
  | "ceftazidime"
  | "cefepime"
  | "amikacin"
  | "gentamicin"
  | "meropenem"
  | "imipenem"
  | "piperacillin / tazobactam"
  | "amoxicillin / sulbactam"
  | "ciprofloxacin"
  | "colistin"
  | "tmp / smx"
  | "vancomycin"
  | "linezolid"
  | "dicloxacillin"
  | "clindamycin"
  | "fluconazole"
  | "amphotericin b deoxycholate"
  | "amphotericin b com. lip"
  | "caspofungin"
  | "voriconazole"
  | "posaconazole"
  | "acyclovir"
  | "ganciclovir"
  | "oseltamivir"
  | "ampicillin"
  | "ertapenem"
  | "metronidazole"
  | "cefixime"
  | "clarithromycin"
  | "posaconazole"
  | "other"
  | "penicillin"
  | "amoxicillin"
  | "amoxicillin with clavulanic acid"
  | "oxacillin"
  | "trimethoprim with sulfamethoxazole"
  | "cephalexin"
  | "cephalothin"
  | "cefazolin"
  | "cefuroxime"
  | "cefaclor"
  | "ofloxacin"
  | "norfloxacin"
  | "levofloxacin"
  | "gatifloxacin"
  | "moxifloxacin"
  | "rifampicin"
  | "isoniazid"
  | "pyrazinamide"
  | "streptomycin"
  | "ethambutol"
  | "dotbal"
  | "nystatin"
  | "amphotericin b deoxycholate"
  | "miconazole"
  | "itraconazole"
  | "clotrimazole"
  | "ketocoanazole"
  | "acyclovir";

export const AntibioticsOptions: OptionType<Antibiotics>[] = [
  { label: "Cefotaxima", value: "cefotaxime" },
  { label: "Ceftriaxona", value: "ceftriaxone" },
  { label: "Ceftazidima", value: "ceftazidime" },
  { label: "Cefepima", value: "cefepime" },
  { label: "Amikacina", value: "amikacin" },
  { label: "Gentamicina", value: "gentamicin" },
  { label: "Meropenem", value: "meropenem" },
  { label: "Imipenem", value: "imipenem" },
  { label: "Piperacilina / tazobactam", value: "piperacillin / tazobactam" },
  { label: "Amoxicilina / sulbactam", value: "amoxicillin / sulbactam" },
  { label: "Ciprofloxacino", value: "ciprofloxacin" },
  { label: "Colistina", value: "colistin" },
  { label: "TMP / SMX", value: "tmp / smx" },
  { label: "Vancomicina", value: "vancomycin" },
  { label: "Linezolid", value: "linezolid" },
  { label: "Dicloxacilina", value: "dicloxacillin" },
  { label: "Clindamicina", value: "clindamycin" },
  { label: "Fluconazol", value: "fluconazole" },
  { label: "Anfotericina B deoxicolato", value: "amphotericin b deoxycholate" },
  { label: "Anfotericina B Com. Labio", value: "amphotericin b com. lip" },
  { label: "Caspofungina", value: "caspofungin" },
  { label: "Voriconazol", value: "voriconazole" },
  { label: "Posaconazol", value: "posaconazole" },
  { label: "Aciclovir", value: "acyclovir" },
  { label: "Ganciclovir", value: "ganciclovir" },
  { label: "Oseltamivir", value: "oseltamivir" },
  { label: "Ampicilina", value: "ampicillin" },
  { label: "Ertapenem", value: "ertapenem" },
  { label: "Metronidazol", value: "metronidazole" },
  { label: "Cefixima", value: "cefixime" },
  { label: "Claritromicina", value: "clarithromycin" },
  { label: "Otro", value: "other" },
];

export type OutcomeField = {
  keyName: string;
  dateName: string;
  label: string;
  furtherOptions?: {
    optionsKey: string;
    options?: OptionType<string>[];
  };
  endDateName?: string;
};
export const InfOutcomeFields: OutcomeField[] = [
  {
    keyName: "hasFeverWithoutFocus",
    dateName: "feverWithoutFocusDate",
    label: "Fiebre sin foco",
  },
  {
    keyName: "isInfectionClinicallyDocumented",
    dateName: "infectionClinicallyDocDate",
    label: "Infección clínicamente documentada",
  },
  {
    keyName: "isInfectionMicrobiologicallyDocumented",
    dateName: "infectionMicrobioDocDate",
    label: "Infección microbiológicamente documentada",
  },
  {
    keyName: "deadWithin30DaysOfInf",
    dateName: "deadWithin30DaysOfInfDate",
    label: "Muerte a los 30 días de la infección",
  },
];

export type SitesOfInf =
  | "does not have"
  | "upper airways"
  | "lower airways"
  | "central nervous system"
  | "urinary tract"
  | "abdominal"
  | "skin"
  | "catheters"
  | "bacteremia with no identified source"
  | "mucous"
  | "other";

export const siteOfInfFields: OptionType<SitesOfInf>[] = [
  {
    value: "does not have",
    label: "No tiene",
  },
  {
    value: "upper airways",
    label: "Vías respiratorias superiores",
  },
  {
    value: "lower airways",
    label: "Vías respiratorias inferiores",
  },
  {
    value: "central nervous system",
    label: "Sistema Nervioso central",
  },
  {
    value: "urinary tract",
    label: "Vías urinarias",
  },
  {
    value: "abdominal",
    label: "Abdominal",
  },
  {
    value: "skin",
    label: "Piel",
  },
  {
    value: "catheters",
    label: "Catéteres",
  },
  {
    value: "bacteremia with no identified source",
    label: "Bacteriemia sin foco identificado",
  },
  {
    value: "mucous",
    label: "Mucosas",
  },
  {
    value: "other",
    label: "Otros",
  }
];

export type CultivationSite =
  | "peripheral blood culture"
  | "central blood culture"
  | "mid-stream urine culture"
  | "catheter urine culture"
  | "collection bag urine culture"
  | "endotracheal aspirate"
  | "expectoration"
  | "puncture-aspiration"
  | "drainage of abscess"
  | "stool culture"
  | "catheter tip"
  | "pleural fluid"
  | "other"
  | string;

export const cultivationSiteOptions: OptionType<CultivationSite>[] = [
  {
    value: "peripheral blood culture",
    label: "Hemocultivos periféricos",
  },
  {
    value: "central blood culture",
    label: "Hemocultivo central",
  },
  {
    value: "mid-stream urine culture",
    label: "Urocultivo por chorro medio",
  },
  {
    value: "catheter urine culture",
    label: "Urocultivo por sonda",
  },
  {
    value: "collection bag urine culture",
    label: "Urocultivo por bolsa colectora",
  },
  {
    value: "endotracheal aspirate",
    label: "Aspirado endotraqueal",
  },
  {
    value: "expectoration",
    label: "Expectoración",
  },
  {
    value: "puncture-aspiration",
    label: "Punción-aspiración",
  },
  {
    value: "drainage of abscess",
    label: "Drenaje de absceso",
  },
  {
    value: "stool culture",
    label: "Coprocultivo",
  },
  {
    value: "catheter tip",
    label: "Punta de catéter",
  },
  {
    value: "pleural fluid",
    label: "Líquido pleura",
  },
  {
    value: "nasopharyngeal swab",
    label: "Hisopo nasofaríngeo",
  },
  {
    value: "oropharyngeal swab",
    label: "Hisopo orofaríngeo",
  },
  {
    value: "respiratory viral panel",
    label: "Panel viral respiratorio",
  },
  {
    value: "respiratory virus isolate",
    label: "Isopado de virus respiratorio",
  },
  {
    value: "other",
    label: "Otro",
  },
];

export type MicroOrgInfo = {
  microOrgName: GramPosMicroorganisms | GramNegMicroorganisms;
  microOrgGram: "positive" | "negative" | "none";
  sensitivity:
    | GramNegAntimicrobialResponse
    | GramPosAntimicrobialResponse
    | GramNoneAntiMicrobialResponse;
};

export const medicines = [
  {
    value: "Beta-lactams",
    label: "Betalactámicos",
    children: [
      { label: "Penicilina", value: "penicillin" },
      { label: "Amoxicilina", value: "amoxicillin" },
      {
        label: "Amoxicilina con ácido clavulánico",
        value: "amoxicillin with clavulanic acid",
      },
      { label: "Ampicilina", value: "ampicillin" },
      { label: "Dicloxacilina", value: "dicloxacillin" },
      { label: "Oxacilina", value: "oxacillin" },
    ],
  },
  {
    value: "Broad-spectrum beta-lactams",
    label: "Betalactámicos de amplio espectro",
    children: [
      { label: "Piperacilina Tazobactam", value: "piperacillin / tazobactam" },
    ],
  },
  {
    value: "Glycopeptides",
    label: "Glucopéptidos",
    children: [{ label: "Vancomicina", value: "vancomycin" }],
  },
  {
    value: "Macrolide",
    label: "Macrólido",
    children: [{ label: "Macrólido", value: "clarithromycin" }],
  },
  {
    value: "Lincosamide",
    label: "Lincosamida",
    children: [{ label: "Clindamicina", value: "clindamycin" }],
  },
  {
    value: "Folate antagonists",
    label: "Antagonistas de folatos",
    children: [
      {
        label: "Trimetoprim con Sulfametoxazol",
        value: "trimethoprim with sulfamethoxazole",
      },
    ],
  },
  {
    value: "Oxazolidinones",
    label: "Oxazolidinonas",
    children: [
      {
        label: "Linezolid",
        value: "linezolid",
      },
    ],
  },
  {
    value: "Polymyxins",
    label: "Polimixinas",
    children: [
      {
        label: "Colistina",
        value: "colistin",
      },
    ],
  },
  {
    value: "First generation cephalosporin",
    label: "Cefalosporina de primera generación",
    children: [
      {
        label: "Cefalexina",
        value: "cephalexin",
      },
      { label: "Cefalotina", value: "cephalothin" },
      { label: "Cefazolina", value: "cefazolin" },
    ],
  },
  {
    value: "Second generation cephalosporins",
    label: "Cefalosporinas de segunda generación",
    children: [
      { label: "Cefuroxima", value: "cefuroxime" },
      { label: "Cefaclor", value: "cefaclor" },
    ],
  },
  {
    value: "Third-generation cephalosporins",
    label: "Cefalosporinas de tercera generación",
    children: [
      { label: "Ceftriaxona", value: "ceftriaxone" },
      { label: "Cefotaxima", value: "cefotaxime" },
      { label: "Cefixima", value: "cefixime" },
      { label: "Ceftazidima", value: "ceftazidime" },
    ],
  },
  {
    value: "Fourth generation cephalosporin",
    label: "Cefalosporina de cuarta generación",
    children: [{ label: "Cefepima", value: "cefepime" }],
  },
  {
    value: "Aminoglycosides",
    label: "Aminoglucosidos",
    children: [
      { label: "Amikacina", value: "amikacin" },
      { label: "Gentamicina", value: "gentamicin" },
    ],
  },
  {
    value: "Carbapenems",
    label: "Carbapenémicos",
    children: [
      { label: "Imipenem", value: "imipenem" },
      { label: "Meropenem", value: "meropenem" },
      { label: "Ertapenem", value: "ertapenem" },
    ],
  },
  {
    value: "Quinolones",
    label: "Quinolonas",
    children: [
      { label: "Ciprofloxaxino", value: "ciprofloxacin" },
      { label: "Ofloxacina", value: "ofloxacin" },
      { label: "Norfloxacina", value: "norfloxacin" },
      { label: "Levofloxacina", value: "levofloxacin" },
      { label: "Gatifloxacina", value: "gatifloxacin" },
      { label: "Moxifloxacina", value: "moxifloxacin" },
    ],
  },
  {
    value: "Antiphymics",
    label: "Antifímicos",
    children: [
      { label: "Rifampicina", value: "rifampicin" },
      { label: "Isoniazida", value: "isoniazid" },
      { label: "Pirazinamida", value: "pyrazinamide" },
      { label: "Estreptomicina", value: "streptomycin" },
      { label: "Etambutol", value: "ethambutol" },
      { label: "Dotbal", value: "dotbal" },
    ],
  },
  {
    value: "Antifungals",
    label: "Antifungicos",
    children: [
      { label: "Nistatina", value: "nystatin" },
      {
        label: "Anfotericina B deoxicolato",
        value: "amphotericin b deoxycholate",
      },
      {
        label: "Anfotericina B Com Lip",
        value: "amphotericin b com. lip",
      },
      {
        label: "Miconazol",
        value: "miconazole",
      },
      {
        label: "Itraconazol",
        value: "itraconazole",
      },
      {
        label: "Clotrimazol",
        value: "clotrimazole",
      },
      {
        label: "Fluconazol",
        value: "fluconazole",
      },
      {
        label: "Ketocoanazol",
        value: "ketocoanazole",
      },
      {
        label: "Voriconazol",
        value: "voriconazole",
      },
      {
        label: "Posaconazol",
        value: "posaconazole",
      },
      {
        label: "Caspofungina",
        value: "caspofungin",
      },
    ],
  },
  {
    value: "Antivirals",
    label: "Antivirales",
    children: [
      { label: "Aciclovir", value: "acyclovir" },
      {
        label: "Ganciclovir",
        value: "ganciclovir",
      },
      { label: "Oseltamivir", value: "oseltamivir" },
    ],
  },
  {
    value: "Nitromidazoles",
    label: "Nitromidazoles",
    children: [{ label: "Metronidazol", value: "metronidazole" }],
  },
  {
    value: "Other",
    label: "Otro",
    children: [{ label: "Otro", value: "other" }],
  },
];

export type Infection = {
  cultures: {
    cropReport: string;
    cultivationSite: CultivationSite;
    dateOfCultureResults: Moment;
    dateOfTakingCulture: Moment;
    sensitivities?: Record<string, string>;
  }[];
  siteOfInf: {
    key: string;
    label: string;
    value: string;
  };
};

export const updateCropReport = (
  form: FormInstance,
  fieldPath: any[],
  value: any,
  gramType: any
) => {
  const infections = form.getFieldsValue()["infections"];
  infections[fieldPath[1]]["cultures"][fieldPath.slice(-1)[0]]["cropReport"] =
    value;
  infections[fieldPath[1]]["cultures"][fieldPath.slice(-1)[0]]["gramType"] =
    gramType;
  form.setFieldsValue({
    infections: infections,
  });
};

export const updateCultivationSite =
  (form: FormInstance, fieldPath: any[]) => (value: any) => {
    const infections = form.getFieldsValue()["infections"];
    infections[fieldPath[1]]["cultures"][fieldPath.slice(-1)[0]][
      "cultivationSite"
    ] = value[1];
    form.setFieldsValue({
      infections: infections,
    });
  };

export const updateSiteOfInf =
  (form: FormInstance, fieldPath: any[]) => (value: any) => {
    const infections = form.getFieldsValue()["infections"];
    infections[fieldPath[1]]["siteOfInf"] = {
      value: value[1],
      label: value[1],
      key: value[1],
    };
    form.setFieldsValue({
      infections: infections,
    })
  };

export const updateMedication =
  (form: FormInstance, fieldPath: any[]) => (value: any) => {
    const medications = form.getFieldsValue()["medications"];
    medications[fieldPath[1]]["medicineFamily"] = value;
    form.setFieldsValue({
      medications: medications,
    });
  };

export const prepInitialValues = async (values: {
  infections: {
    cultures: {
      dateOfCultureResults: Moment | string | null;
      dateOfTakingCulture: Moment | string | null;
    }[];
  }[];
}) => {
  // format dates on the buried culture date fields
  const infectionsCopy = [...values.infections];
  values.infections?.forEach((infection, i) => {
    infection.cultures?.forEach((culture, j) => {
      infectionsCopy[i].cultures[j].dateOfCultureResults = culture.dateOfCultureResults ? moment(
        culture.dateOfCultureResults
      ).utc() : null;
      infectionsCopy[i].cultures[j].dateOfTakingCulture = culture.dateOfTakingCulture ? moment(
        culture.dateOfTakingCulture
      ).utc() : null;
    });
  });
  return infectionsCopy;
};
