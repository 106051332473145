import moment from "moment";
import { OptionField } from "../components/OptionsField";
import { OutcomeField } from "./InfFormUtil";

export const RespOptions: OptionField<string>[] = [
  {
    formName: "respFreq",
    label: "Frecuencia respiratoria",
    options: [
      { label: "Apnea", value: "apnea" },
      { label: "> Percentil 90", value: "gt90Perc" },
      { label: "Normal", value: "normal" },
    ],
  },
  {
    formName: "respSaturation",
    label: "Saturación",
    options: [
      { label: "< 85%", value: "lt85Perc" },
      { label: "85 a 92", value: "85to92Perc" },
      { label: "> 92%", value: "gt92Perc" },
    ],
  },
  {
    formName: "respSilvermanAndersen",
    label: "Silverman Andersen",
    options: [
      { label: "SA 5", value: "SA5" },
      { label: "SA 4 a 3", value: "SA4to3" },
      { label: "SA < 3", value: "SAlt3" },
    ],
  },
  {
    formName: "respAsmaticCrisis",
    label: "Crisis asmática",
    options: [
      { label: "Grave", value: "severe" },
      { label: "Moderada", value: "moderate" },
      { label: "Leve", value: "light" },
    ],
  },
  {
    formName: "respOthers",
    label: "Otros",
    options: [
      { label: "Obstrucción de vía aérea", value: "airwayObstruction" },
      { label: "Cuerpo extraño con disnea", value: "foreignBodyWithDyspnea" },
      {
        label: "Cuerpo extraño sin disnea",
        value: "foreignBodyWithoutDyspnea",
      },
    ],
  },
];

export const HemoOptions: OptionField<string>[] = [
  {
    label: "Frecuencia cardiaca",
    formName: "hemodynCardVascFreq",
    options: [
      { label: "Bradicardia", value: "bradycardia" },
      { label: "> Percentil 90", value: "gt90Perc" },
      { label: "Normal", value: "normal" },
    ],
  },
  {
    label: "Tensión arterial",
    formName: "hemodynArterialTension",
    options: [
      { label: "< Percentil 5", value: "lt5Perc" },
      { label: "< Percentil 50", value: "lt50Perc" },
      { label: "> Percentil 95", value: "gt95Perc" },
      { label: "Percentil 95", value: "95Perc" },
      { label: "Percentil 75-90", value: "75to90Perc" },
    ],
  },
  {
    label: "Llenado capilar",
    formName: "hemodynCapillaryFeeling",
    options: [
      { label: "> 5 segundos", value: "gt5secs" },
      { label: "3 a 5 segundos", value: "3to5secs" },
      { label: "< 2 segundos", value: "lt2secs" },
    ],
  },
  {
    label: "Pulsos periféricos",
    formName: "hemodynPeriphPulse",
    options: [
      { label: "Ausentes", value: "absent" },
      { label: "Débiles", value: "weak" },
      { label: "Normales", value: "normal" },
    ],
  },
  {
    label: "Estado de hidratación",
    formName: "hemodynHydrationStatus",
    options: [
      { label: "Deshidración grave", value: "severeDehyd" },
      { label: "Con deshidración", value: "withDehyd" },
      { label: "Sin deshidración", value: "withoutDehyd" },
    ],
  },
];

export const TemperatureOptions: OptionField<string>[] = [
  {
    label: "Hipotermia",
    formName: "tempHypothermia",
    options: [
      {
        label: "< 3 meses o > 3 meses con <35º",
        value: "lt3MosOrlt3MosWith35C",
      },
      { label: "Normal", value: "normal" },
    ],
  },
  {
    label: "Fiebre",
    formName: "tempFever",
    options: [
      { label: "Cualquier edad", value: "anyAge" },
      { label: "Normal", value: "normal" },
    ],
  },
  {
    label: "Estado general",
    formName: "tempGenStatus",
    options: [
      { label: "Mal", value: "bad" },
      { label: "Bien", value: "good" },
    ],
  },
  {
    label: "Otros",
    formName: "tempOthers",
    options: [
      { label: "Inmunocomprometido", value: "immunocompromised" },
      { label: "No inmunocomprometido", value: "notImmunocompromised" },
    ],
  },
];

export const NeurologicalOptions: OptionField<string>[] = [
  {
    label: "Glasgow",
    formName: "neurologicalGlasgow",
    options: [
      { label: "3 a 9", value: "3to9" },
      { label: "10 a 13", value: "10to13" },
      { label: "14 a 15", value: "14to15" },
    ],
  },
  {
    label: "TCE",
    formName: "neurologicalTCE",
    options: [
      { label: "Grave", value: "severe" },
      { label: "Moderado", value: "moderate" },
      { label: "Leve", value: "slight" },
    ],
  },
  {
    label: "Crisis convulsivas",
    formName: "neurologicalSeizures",
    options: [
      { label: "Estatus epiléptico", value: "epileptic" },
      { label: "Crisis convulsivas", value: "seizures" },
    ],
  },
];

export const PainOptions: OptionField<string>[] = [
  {
    label: "Intensidad",
    formName: "painIntensity",
    options: [
      { label: "8 a 10 (intenso)", value: "intense" },
      { label: "4 a 7 (moderado)", value: "moderate" },
      { label: "1 a 3 (leve)", value: "slight" },
    ],
  },
];

type TriageLevelItem = {
  label: string;
  formKey: string;
};

export const TriageLevel1FormKeys1: TriageLevelItem[] = [
  {
    label: "Apnea",
    formKey: "hasApnea",
  },
  {
    label: "Asma en preparo",
    formKey: "hasAsthmaInPreHeart",
  },
  {
    label: "Choque",
    formKey: "hasShock",
  },
  {
    label: "Deshidración grave",
    formKey: "hasSevereDehydration",
  },
  {
    label: "Dificultad respiratoria grave",
    formKey: "hasSevereBreathingDifficulty",
  },
  {
    label: "Diabetes Mellitus letárgico",
    formKey: "hasLethargicDiabetesMellitus",
  },
  {
    label: "Hemorragia",
    formKey: "hasBleeding",
  },
  {
    label: "AVC con déficit mayor",
    formKey: "hasAVCwithMajorDeficit",
  },
];
export const TriageLevel1FormKeys2: TriageLevelItem[] = [
  {
    label: "Obstrucción de vía aérea",
    formKey: "hasObstructionOfAirway",
  },
  {
    label: "Paro cardiaco o paro respiratorio",
    formKey: "hasCardiacOrRespArrest",
  },
  {
    label: "Quemadura de mas de 25% de SCT o de Via aérea",
    formKey: "hasBurn25CSTorAirway",
  },
  {
    label: "Estatus epiléptico",
    formKey: "hasEpilepsy",
  },
  {
    label: "Trauma mayor de cualquier parte del cuerpo",
    formKey: "hasMajorTrauma",
  },
  {
    label: "Trauma multisistemico",
    formKey: "hasMultiSystemTrauma",
  },
  {
    label: "Dolor 8 a 10",
    formKey: "hasPain8_10",
  },
  {
    label: "Edema en via aérea",
    formKey: "hasAirwayEdema",
  },
];

export const TriageLevel2FormKeys1: TriageLevelItem[] = [
  {
    label: "TCE moderado",
    formKey: "hasTCEModerate",
  },
  {
    label: "Reacción alérgica grave",
    formKey: "hasSevereAllergRxn",
  },
  {
    label: "Intoxicación",
    formKey: "hasIntoxication",
  },
  {
    label: "Dolor abdominal con sintomas viscerales",
    formKey: "hasAbdominalPainWithViscSympts",
  },
  {
    label: "Dolor ocular",
    formKey: "hasEyePain",
  },
  {
    label: "Abdomen agudo",
    formKey: "hasAcuteAbdomen",
  },
  {
    label: "Sangrado Gastrointestinal",
    formKey: "hasGastrointestinalBleeding",
  },
  {
    label: "Cetoacidosis",
    formKey: "hasKetoacidosis",
  },
  {
    label: "Inmunocomprometido",
    formKey: "isImmunocompromised",
  },
  {
    label: "Agresión sexual",
    formKey: "hasSexualAttack",
  },
  {
    label: "Neonato de < 7 dias",
    formKey: "isNewbornLt7Days",
  },
  {
    label: "Quemadura 10% SCT",
    formKey: "hasBurn10SCT",
  },
  {
    label: "Neutropenia y fiebre",
    formKey: "hasFeverAndNeutropenia",
  },
  {
    label: "Epistaxis grave",
    formKey: "hasSevereEpistaxis",
  },
  {
    label: "Letrgia somnolencia",
    formKey: "hasLethargyOrDrowsiness",
  },
  {
    label: "Eritema purpurico",
    formKey: "hasPurpleErythema",
  },
  {
    label: "Hipoglicemia",
    formKey: "hasHypoglycemia",
  },
  {
    label: "Nariz que moquea",
    formKey: "hasRunnyNose",
  }
];
export const TriageLevel2FormKeys2: TriageLevelItem[] = [
  {
    label: "TCE leve",
    formKey: "hasTCEslight",
  },
  {
    label: "Laceración profunda",
    formKey: "hasDeepLaceration",
  },
  {
    label: "Dolor 4 a 7",
    formKey: "hasPain4_7",
  },
  {
    label: "Dolor abdominal moderado",
    formKey: "hasModerateAbdominalPain",
  },
  {
    label: "Vómito y/o diarrea en < o = 2 años",
    formKey: "hasVomitingAndDiarrInLte2Yrs",
  },
  {
    label: "Vómito biliar o persistente",
    formKey: "hasBileOrPersistVomit",
  },
  {
    label: "Problemas de diálisis",
    formKey: "hasDialysisProblems",
  },
  {
    label: "Signos de infección",
    formKey: "hasSignsOfInfection",
  },
  {
    label: "Asma leve-moderada",
    formKey: "hasAsthmaSlightToModerate",
  },
  {
    label: "Disnea leve-moderada",
    formKey: "hasDyspnoeaSlightToModerate",
  },
  {
    label: "Crisis convulsivas",
    formKey: "hasConvulsiveCrisis",
  },
  {
    label: "Deshidración",
    formKey: "hasDehydration",
  },
  {
    label: "Quemadura < 5% SCT",
    formKey: "hasBurn5SCT",
  },
  {
    label: "Cuerpo extraño en nariz/oído",
    formKey: "hasForeignBodyInNoseOrEar",
  },
  {
    label: "Reacción alérgica moderada",
    formKey: "hasModerateAllergRxn",
  },
  {
    label: "Hiperglicemia",
    formKey: "hasHyperglycemia",
  },
  {
    label: "Tos",
    formKey: "hasCough",
  },
  {
    label: "Expectoración",
    formKey: "hasExpectoration"
  }
];

export const TriageLevel3FormKeys1: TriageLevelItem[] = [
  {
    label: "Dolor 1 a 3",
    formKey: "hasPain1_3",
  },
  {
    label: "Dolor de cabeza (no migrana, no subito)",
    formKey: "hasHeadache",
  },
  {
    label: "Dolor abdominal (agudo)",
    formKey: "hasAcuteAbdominalPain",
  },
  {
    label: "Dolor de oído",
    formKey: "hasEarache",
  },
  {
    label: "Epistaxis",
    formKey: "hasEpistaxis",
  },
  {
    label: "Reacción alérgica (menor)",
    formKey: "hasMinorAllergRxn",
  },
  {
    label: "Asma leve",
    formKey: "hasMildAsthma",
  },
  {
    label: "Ingestión accidental de cuerpo extraño sin disnea",
    formKey: "hasAccidentalForeignBodyIngesionWithoutDyspnea",
  },
  {
    label: "Constipación",
    formKey: "hasConspitation",
  },
];

export const TriageLevel3FormKeys2: TriageLevelItem[] = [
  {
    label: "Traumatismo menor no agudo",
    formKey: "hasNonAcuteMinorTrauma",
  },
  {
    label: "Diarrea sin deshidración",
    formKey: "hasDyspneaWithoutDehyd",
  },
  {
    label: "Vómito sin deshidración",
    formKey: "hasVomitWithoutDehyd",
  },
  {
    label: "Dolor abdominal crónico",
    formKey: "hasChronicAbdominalPain",
  },
  {
    label: "Laceración superficial",
    formKey: "hasSuperficialLaceration",
  },
  {
    label: "Eritema localizado",
    formKey: "hasLocalizedErythema",
  },
  {
    label: "Ictericia",
    formKey: "hasJaundice",
  },
  {
    label: "Celulitis",
    formKey: "hasCelluitis",
  },
  {
    label: "Quemadura gastrointestinal",
    formKey: "hasGastrointestinalBurn",
  },
];

export const TriageLevelToFormKeys: Record<number, TriageLevelItem[]> = {
  1: [...TriageLevel1FormKeys1, ...TriageLevel1FormKeys2],
  2: [...TriageLevel2FormKeys1, ...TriageLevel2FormKeys2],
  3: [...TriageLevel3FormKeys1, ...TriageLevel3FormKeys2],
};

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
    date: moment(values.date).utc(),
    timeArrived: moment(values.timeArrived, "HH:mm:ss"),
    triageBegin: moment(values.triageBegin, "HH:mm:ss"),
    triageEnd: moment(values.triageEnd, "HH:mm:ss"),
    septicShockDate: values.septicShockDate
      ? moment(values.septicShockDate).utc()
      : null,
    deathDate: values.deathDate ? moment(values.deathDate).utc() : null,
    cultivationDate: values.cultivationDate ? moment(values.cultivationDate).utc() : null,
    imaging: values.imaging?.replace(/[{}]/g, "").split(","),
    chestXray: values.chestXray?.replace(/[{}]/g, "").split(","),
    abdomenPlate: values.abdomenPlate?.replace(/[{}]/g, "").split(","),
    cropSite: values.cropSite?.replace(/[{}]/g, "").split(","),
    movedToPisoDate: values.movedToPisoDate
      ? moment(values.movedToPisoDate).utc()
      : null,
    movedToUTIPDate: values.movedToUTIPDate
      ? moment(values.movedToUTIPDate).utc()
      : null,
    movedToCovidAreaDate: values.movedToCovidAreaDate
      ? moment(values.movedToCovidAreaDate).utc()
      : null,
  };
  return newValues;
};

export const EmergencyOutcomeFields: OutcomeField[] = [
  {
    keyName: "hasSepticShock",
    dateName: "septicShockDate",
    label: "Choque séptico",
  },
  {
    keyName: "dead",
    dateName: "deathDate",
    label: "Muerte",
  },
];

type BiochemicalIndicatorItem = {
  label: string;
  formKey: string;
};

export const BiochemicalIndicators: BiochemicalIndicatorItem[] = [
  { label: "Hemoglobina (g/dL)", formKey: "hemoglobin" },
  { label: "Hematorcrito (%)", formKey: "hematocrit" },
  { label: "Plaquetas (cel/mm3)", formKey: "platelets" },
  { label: "Leucocitos (cel/mm3)", formKey: "leukocytes" },
  { label: "Linfocitos (cel/mm3)", formKey: "lymphocytes" },
  { label: "Neutrófilos (cel/mm3)", formKey: "neutrophils" },
  { label: "Velocidad de sedimentación globular", formKey: "erythrocyte" },
  { label: "Procalcitonina (ug/mL)", formKey: "procalcitonin" },
  { label: "Proteína C reactiva", formKey: "creactive" },
];

export const CropSiteOptions: OptionField<string>[] = [
  {
    label: "Sitio de cultivo",
    formName: "cropSite",
    options: [
      { value: "one-peripheral", label: "Un periférico" },
      { value: "two-peripherals", label: "Dos periféricos" },
      { value: "catheter-port", label: "Cultivo de catéter puerto" },
      { value: "urine", label: "Urocultivo" },
      { value: "stool", label: "Coprocultivo" },
      {
        value: "tracheostomy-cannula",
        label: "Cultivo de cánula traqueostomía",
      },
      { value: "bronchial-discharge", label: "Cultivo de secreción bronquial" },
      {
        value: "central-periferal-catheter",
        label: "Catéter central y periférico",
      },
    ],
  },
];

export const ImagingOptions: OptionField<string>[] = [
  {
    label: "Radiografía",
    formName: "imaging",
    options: [
      { value: "chestXray", label: "Radiografía de pecho" },
      { value: "abdomenXray", label: "Radiografía de abdomen" },
      { value: "abdomenUltrasound", label: "Ultrasonido de abdomen" },
      { value: "softTissueUltrasound", label: "Ultrasonido de tejidos blandos" },
      { value: "chestTomography", label: "Tomografía de tórax" },
      { value: "abdomenTomography", label: "Tomografía de abdomen" },
      { value: "skullTomography", label: "Tomografía de cráneo" },
      { value: "extremityXray", label: "Radiografía de extremidades" },
      { value: "mri", label: "MRI" },
      { value: "others", label: "Otros" },
      { value: "no-data", label: "Sin datos" },
    ],
  },
];
export const ChestXrayOptions: OptionField<string>[] = [
  {
    label: "Radiografía de tórax",
    formName: "chestXray",
    options: [
      { value: "without-mod", label: "Sin alteraciones" },
      { value: "localized-infiltrators", label: "Infiltrados localizados" },
      { value: "diffuse-infiltrators", label: "Infiltrados difusos" },
      {
        value: "cardiothoracic-over-0.45",
        label: "Índice Cardiotorácico mayor de 0.45",
      },
      { value: "atelactasis", label: "Atelectasia" },
      { value: "air-bronchogram", label: "Broncograma aéreo" },
      { value: "pleural-effusion", label: "Derrame pleural" },
      { value: "others", label: "Otros" },
      { value: "no-data", label: "Sin datos" },
    ],
  },
];

export const AbdomenPlateOptions: OptionField<string>[] = [
  {
    label: "Placa de abdomen",
    formName: "abdomenPlate",
    options: [
      { value: "without-mod", label: "Sin alteraciones" },
      { value: "handle-dilation", label: "Dilatación de asas" },
      { value: "visible-fecal-matter", label: "Materia fecal visible" },
      { value: "increase-of-space", label: "Aumento del espacio interesa" },
      { value: "fresh-air", label: "Aire libre" },
      { value: "gastromegaly", label: "Gastromegalia" },
      { value: "others", label: "Otros" },
      { value: "no-data", label: "Sin datos" },
    ],
  },
];
