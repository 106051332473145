import { formBuilderField } from "./formBuilder";
import moment from "moment";
import { RelationshipToPatientField } from "./MobileGeneralFormUtil";

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
  };
  Object.keys(newValues).forEach(key => {
    // format date
    if (key === "DiagnosedWithDepAnxDate") {
      newValues[key] = newValues[key] ? moment(newValues[key]).utc() : null
    }
    // flatten objects for react
    else if (newValues[key] && typeof newValues[key] === "object") {
      const filtered = Object.keys(newValues[key]).filter(subKey => newValues[key][subKey] == true)
      newValues[key] = filtered
    }
  })
  return newValues;
};

const alertReasons = [
  {label: 'Fiebre, que es temperatura de 38 °C o más.', value: 'Fever, which is a temperature of 38°C or higher.'},
  {label: 'Escalofríos o sudoración.', value: 'Chills or sweating.'},
  {label: 'Dolor de garganta, llagas en la boca o dolor de dientes.', value: 'Sore throat, mouth sores, or toothache.'},
  {label: 'Dolor abdominal.', value: 'Abdominal pain.'},
  {label: 'Dolor cerca del ano.', value: 'Pain near the anus.'},
  {label: 'Dolor y ardor al orinar, orina con mayor frecuencia o se queda con ganas de orinar.', value: 'Pain and burning when urinating, urinating more frequently or having the urge to urinate.'},
  {label: 'Dolor o ardor al orinar o micción frecuente.', value: 'Pain or burning when urinating or frequent urination.'},
  {label: 'Diarrea o llagas alrededor del ano.', value: 'Diarrhea or sores around the anus.'},
  {label: 'Tos o dificultad para respirar.', value: 'Cough or shortness of breath.'}
]

export const MobileSEIFormFields: formBuilderField[] = [
  ...RelationshipToPatientField,
  {
    keyName: "UserRelStrength",
    type: "select",
    label: "¿Su vínculo con el paciente es fuertemente estrecho?",
    options: [
      { label: "Totalmente en desacuerdo", value: "Totally disagree"}, 
      { label: "Bastante en desacuerdo", value: "Quite in disagreement"}, 
      { label: "Ni en acuerdo ni en desacuerdo", value: "Neither in agreement nor disagree"}, 
      { label: "Bastante de acuerdo", value: "Quite agree"},
      { label: "Otalmente de acuerdo", value: "Totally agree"}
    ]
  },
  {
    keyName: "UserRelLength",
    type: "select",
    label: "¿Hace cuánto tiempo mantiene este vínculo con el paciente? (meses)",
    options: [
      { label: "Menos de 3", value: "< 3 months"},
      { label: "3 - 6", value: "3-6 months"},
      { label: "Más de 6", value: "> 6 months"}
    ]
  },
  {
    keyName: "UserRelFrequentContact",
    type: "boolean",
    label: "Mantiene contacto frecuente con el paciente",
  },
  {
    keyName: "UserRelProvidesFinancialSupport",
    type: "boolean",
    label: "Proporciona un importante apoyio financiero",
  },
  {
    keyName: "UserRelAccompanies",
    type: "boolean",
    label: "Es la persona que más frecuentemente acompaña al paciente a citas médicas, participa en consultas y terapia, supervisa la conducta alimentaria del paciente en el hogar, etc",
  },
  {
    keyName: "UserRelOtherPerson",
    type: "string",
    label: "Si no eres tú, ¿quién realiza estas actividades? Nombre / parentezco",
  },
  {
    keyName: "UserRelIsEmergencyContact",
    type: "boolean",
    label: "Es uno de los individuos con los que el equipo médico /de terapia  debe de comunicarse en caso de una emergencia",
  },
  {
    keyName: "PrimaryCare",
    type: "boolean",
    label: "¿Le han explicado los cuidados que debe tener con su hijo/hija después de la quimioterapia?"
  },
  {
    keyName: "MedicalCareClose",
    type: "boolean",
    label: "Si en este momento su hijo o hija presenta una complicación relacionada con la quimioterapia que requiera evaluación médica ¿sabe de alguna unidad médica donde puedan brindarle atención?"
  },
  {
    keyName: "MedicalCareCloseWhich",
    type: "string",
    label: "¿Cuál?"
  },
  {
    keyName: "EmergencyOtherChildrenCare",
    type: "boolean",
    label: "Si su hijo o hija necesita ir a urgencias, ¿cuenta con apoyo para cuidar a sus otros niños o personas mayores que dependen de usted?"
  },
  {
    keyName: "EmergencyEconomicNeeds",
    type: "boolean",
    label: "Si en este momento se presenta una situación de urgencia, usted cuenta con algún tipo de apoyo económico para resolver los gastos ? (medicamentos, transporte, alimentación, hospedaje, etc)"
  },
  {
    keyName: "EconomicSupport",
    type: "string",
    label: "¿Quién le brinda éste apoyo económico? y ¿cómo lo hace?"
  },
  {
    keyName: "ApproxMoIncome",
    type: "select",
    label: "¿Cuál es el ingreso familiar mensual aproximado?",
    options: [
      { label: "Menos de 7500", value: "<7500"},
      { label: "7500-15000", value: "7500-15000"},
      { label: "Más de 15000", value: ">15000"},
    ]
  },
  {
    keyName: "ApproxMoFoodExpense",
    type: "select",
    label: "¿Cuál es el gasto familiar mensual aproximado en alimentos?",
    options: [
      { label: "Menos de 7500", value: "<7500"},
      { label: "7500-15000", value: "7500-15000"},
      { label: "Más de 15000", value: ">15000"},
    ]
  },
  {
    keyName: "ApproxMoMedSupplyExpense",
    type: "select",
    label: "¿Cuál es el gasto familiar mensual aproximado en: alimentos, medicamentos, insumos para la atención del paciente como: pañales, gasa, material de curación, etc",
    options: [
      { label: "Menos de 7500", value: "<7500"},
      { label: "7500-15000", value: "7500-15000"},
      { label: "Más de 15000", value: ">15000"},
    ]
  },
  {
    keyName: "HasWaterDrainageElectricity",
    type: "boolean",
    label: "Desde la última quimioterapia que recibió su hijo/hija  ¿La vivienda que habita con el paciente tiene agua, drenaje y electricidad)?"
  },
  {
    keyName: "HasPhone",
    type: "select",
    label: "¿Cuenta usted con un teléfono celular con las siguientes caracterísitcas?",
    selectMode: "multiple",
    options: [
      { label: "Android", value: "Android"},
      { label: "iOS", value: "iOS"},
      { label: "Tiene accesso a WhatsApp", value: "Has access to WhatsApp"},
      { label: "Tiene accesso a WiFi", value: "Has access to WiFi"},
      { label: "Tiene accesso a Bluetooth", value: "Has access to Bluetooth"},
    ]
  },
  {
    keyName: "HomeHasInternetforApp",
    type: "boolean",
    label: "¿La vivienda actual en la que se encuentra paciente tiene acceso a Internet para el uso continuo de la APP?"
  },
  {
    keyName: "HomeSafety",
    type: "select",
    label: "¿En la vivienda actual en donde está el paciente se dispone de: \n(*se consideran inseguras las hamacas, camas altas o dormir en la parte de arriba de una litera)",
    selectMode: "multiple",
    options: [
      { label: "Cama Propia (segura)", value: "Own (safe) bed"},
      { label: "Acceso a baño con agua y drenaje", value: "Access to bathroom with water and drainage"},
      { label: "Acceso a un espacio con ventilación ( ventana)", value: "Access to a ventilated space (window)"},
      { label: "Protección de ventanas y puertas", value: "Window and door protection"},
      { label: "Cuenta con piso firme", value: "Has a firm floor"},
      { label: "Cuenta con un área para preparar alimentos", value: "Has an area to prepare food"},
      { label: "Cuenta con refrigerador", value: "Has a refrigerator"},
      { label: "Cuenta con estufa", value: "Has a stove"},
    ]
  },
  {
    keyName: "PatientSharesBedWithOthers",
    type: "boolean",
    label: "¿El paciente comparte la cama con algún miembro del hogar?"
  },
  {
    keyName: "NumberOfThoseSharingRoom",
    type: "string",
    label: "¿Con cuántas personas comparte la habitación o dormitorio el paciente?"
  },
  {
    keyName: "AlarmSignal",
    type: "select",
    label: "¿De acuerdo a lo que conoce del padecimiento de la enfermedad del paciente, ¿cuál es la señal de alarma que hace necesario buscar atención médica?",
    selectMode: "multiple",
    options: alertReasons,
  },
  {
    keyName: "MotivationForAttention",
    type: "select",
    label: "De acuerdo a lo que conoce de la enfermedad de su hijo/hija, ¿cuál es la señal de alarma que le motiva a buscar atención médica urgente?",
    options: alertReasons,
  },
  {
    keyName: "CanRecogWarningSigns",
    type: "boolean",
    label: "¿Reconoce con facilidad las señales de alarma de fiebre y neutropenia que requieren atención médica para hija/hijo?", 
  },
  {
    keyName: "AbilityToRecogRating",
    type: "select",
    label: "En una escala de 0 a 10 (en donde el 10 es la calificación más alta), ¿en qué medida reconoce estas señales de alarma en hija/hijo?",
    options: [
      { label: "1", value: "1"},
      { label: "2", value: "2"},
      { label: "3", value: "3"},
      { label: "4", value: "4"},
      { label: "5", value: "5"},
      { label: "6", value: "6"},
      { label: "7", value: "7"},
      { label: "8", value: "8"},
      { label: "9", value: "9"},
      { label: "10", value: "10"},
    ],
  },
  {
    keyName: "BarriersToAttention",
    type: "select",
    label: "Ante la parición de fiebre debe recibir atención pronto. ¿Cuáles son las barreras para poder hacerlo?: ",
    selectMode: "multiple",
    options: [
      { label: "Administrativas", value: "Administrative"},
      { label: "De referencia", value: "Reference"},
      { label: "Económicas", value: "Economic"},
      { label: "De transporte", value: "Of transport"},
      { label: "De manejo del paciente", value: "Patient Management"},
      { label: "Distancia", value: "Distance"},
      { label: "Tráfico", value: "Traffic"},
      { label: "Otra", value: "Other"},
    ]
  },
  {
    keyName: "OtherBarrier",
    type: "string",
    label: "Otro: ¿Cuál?:"
  },
  {
    keyName: "Satisfaction",
    type: "select",
    label: "En general, ¿qué tan satisfecho está con la atención médica que recibe en el Hospital Infantil de México, Federico Gómez?",
    options: [
      { label: "Muy satisfecho", value: "Very satisfied"},
      { label: "Satisfecho", value: "Satisfied"},
      { label: "Ni satisfecho ni insatisfecho", value: "Neither satisfied nor dissatisfied"},
      { label: "Insatisfecho", value: "Dissatisfied"},
      { label: "Muy insatisfecho", value: "Very unsatisfied"},
      { label: "No sabe/No reponde", value: "Does not know/Does not answer"},
    ]
  },
  {
    keyName: "DiagnosedWithDepressionAnxiety",
    type: "boolean",
    label: "¿Ha sido diagnosticado por algún médico en los últimos meses con depresión o ansiedad?"
  },
  {
    keyName: "DiagnosedWithDepAnxDate",
    type: "date",
    label: "Fecha de diagnóstico:"
  },
  {
    keyName: "TreatmentForDepressionAnxiety",
    type: "boolean",
    label: "¿Actualmente está recibiendo tratamiento para depresión o ansiedad?"
  },

];
