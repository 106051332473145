import moment from "moment";
import { useEffect, useState } from "react";
import { ConfigureFormType } from "../pages/ConfigurePage";
import { usePatientStore } from "../state/patientStore";
import { useStaffStore } from "../state/staffStore";
import { useActivityStore } from "../state/activityStore";
// import { IDResponse, login } from "../util/auth";
import { FormResource, Patient, RoleToLabel } from "../util/misc";
import {
  getFormsByPatientId,
  getPatientById,
  getPatients,
} from "../util/patients";
import { getActivity, activityTypeDesc } from "../util/activityLog"
import { configureStaff, fetchStaff } from "../util/staff";

// export const useLogin = (credentials: LoginForm | undefined) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [data, setData] = useState<IDResponse>();
//   useEffect(() => {
//     (async () => {
//       if (!credentials) return;
//       setIsLoading(true);
//       const loginInfo = await login(credentials);
//       setData(loginInfo);
//       setIsLoading(false);
//     })();
//   }, [credentials]);
//   return { isLoading, data };
// };

export const useConfigure = (credentials: ConfigureFormType | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConfigureFormType>();
  useEffect(() => {
    (async () => {
      if (!credentials) return;
      setIsLoading(true);
      const loginInfo = await configureStaff(credentials);
      setData(loginInfo);
      setIsLoading(false);
    })();
  }, [credentials]);
  return { isLoading, data };
};

export const useAllStaff = () => {
  const { staff, setStaff } = useStaffStore();
  const [isLoading, setIsLoading] = useState(false);
  const refreshStaff = async () => {
    setIsLoading(true);
    const staff = (await fetchStaff()).map((staff) => ({
      ...staff,
      role: RoleToLabel[staff.role],
    }));
    setStaff(staff);
    setIsLoading(false);
  };

  useEffect(() => {
    if (staff.length) {
      return;
    }
    refreshStaff();
  }, []);

  return { isLoading, refreshStaff };
};

export const useAllPatients = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { patients, setPatients } = usePatientStore();
  const refreshPatients = async () => {
    setIsLoading(true);
    const patients = (await getPatients()).map((patient) => ({
      ...patient,
      fullName: `${patient.name} ${patient.family_name_father}`,
      dob: moment(patient.dob).utc().format("YYYY-MM-DD"),
    }));
    patients.sort((a,b) => { // initial sort of patients by most recent ER visit
      return new Date(b.most_recent_emergency_form_date).getTime() - new Date(a.most_recent_emergency_form_date).getTime()
    })
    setPatients(patients);
    setIsLoading(false);
  };
  useEffect(() => {
    if (patients.length) {
      return;
    }
    refreshPatients();
  }, []);
  return { isLoading, refreshPatients };
};

export const usePatientById = (id: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState<Patient>();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const patient = await getPatientById(id);
      setPatient(patient);
      setIsLoading(false);
    })();
  }, [id]);
  return { isLoading, patient };
};

export const useFormHistory = (id: string, resource: FormResource) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forms, setForms] = useState<any[]>();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const forms = await getFormsByPatientId(id, resource);
      setForms(forms);
      setIsLoading(false);
    })();
  }, [id, resource]);
  return { isLoading, forms };
};

export const useActivityLog = (filters: {[key: string]: string}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { activities, setActivity } = useActivityStore();
  const { patients } = usePatientStore();
  const { refreshPatients } = useAllPatients();

  const refreshActivities = async (filters: {[key: string]: string}) => {
    setIsLoading(true);
    // make sure we have patients
    refreshPatients();
    if (!patients.length) return
    
    if (!filters.endDate) filters.endDate = new Date().toISOString().slice(0,10) // set end date to today if not specified
    // set end date to midnight of next day
    const nextMidnight = new Date(filters.endDate)
    nextMidnight.setDate(nextMidnight.getDate() + 1)
    filters.endDate = nextMidnight.toISOString().slice(0,10)
    
    // get data
    const data = (await getActivity(filters)).map((activity) => ({
      ...activity,
    }));
    const transformedAndSorted = data.map(a => {
        // store patient name, activity desc, curp, and himfgid within activity data so it's searchable
        const patient = patients.find((patient) => patient.id === a.patientId)
        let activityDesc = activityTypeDesc[a.activityType]

        return {
          ...a,
          activityDesc,
          patientName: patient ? `${patient.name} ${patient.family_name_father}` : "...",
          curp: patient?.curp,
          himfgid: patient?.himfgid,
        }
      }).sort((a,b) => { 
        // initial sort of activity
        return new Date(b.date).getTime() - new Date(a.date).getTime()
      })
    setActivity(transformedAndSorted);
    setIsLoading(false);
  };

  useEffect(() => {
    if (activities.length) return;
    refreshActivities(filters);
  }, []);

  useEffect(() => {
    if (patients.length || activities.length) return
    console.log('useEffect patients')
    refreshActivities(filters);
  }, [patients])

  return { isLoading, refreshActivities };
};