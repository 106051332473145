import { useEffect, useState } from "react";
// import { getRole } from "../util/auth";
import { error_message } from "../util/messages";
import { useMainStore } from "../state/mainStore";

export const useRole = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = useMainStore();
  const { setIsLoggedIn } = useMainStore();
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1500) // slight delay to allow time for tokens to be set and less jarring loading.
      } catch (e: any) {
        error_message(e);
        setIsLoggedIn(false);
        setIsLoading(false);
      }
    })();
  }, [isLoggedIn, setIsLoggedIn]);
  return { isLoading };
};
