import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
} from "../util/misc";

import { Form, Row, } from "antd";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import FormSkeleton from "../components/FormSkeleton";
import {
  prepInitialValues,
  MobileSEIFormFields,
} from "../util/MobileSEIFormUtil";
import FormBuilderFieldComp from "../components/form-builder/formBuilderFields";

const MobileSEIFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [form] = Form.useForm();
  const history = useHistory();
  const formName = "MobileSEIForm"
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data.form) {
        setIsLoading(false);
        return;
      }
      const values = data.form;
      const prevDoctorValues = data.prev_doctor;
      setPrevDoctor(prevDoctorValues);
      const newValues = await prepInitialValues(values);
      setInitialValues(newValues);
      setIsLoading(false);
      form.resetFields(Object.keys(newValues));
    })();
  }, []);

  const onFinish = async (values: any) => {
    if (!values) return;
    if (isSubmitting) return
    else setIsSubmitting(true)
    values = {
      ...values,
    }
    Object.keys(values).forEach(key => {
      // build json fields to match mobile submission format
      if (values[key] && typeof values[key] === "object" && key !== "DiagnosedWithDepAnxDate") {
        console.log('val',values[key])
        const fieldInfo = MobileSEIFormFields.find(field => field.keyName == key)
        const jsonified = {} as any
        //mobile format is a json object that has all options as keys with true/false values
        fieldInfo?.options?.forEach(option => jsonified[option.value] = values[key].includes(option.value))
        values[key] = jsonified
      }
    })
    await submitForm(
      { values: values, patientId: patientId, formId: formId },
      formName
    );
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="data-form"
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="(Aplicación familiar) Indicadores Sociales y Económicos"
        />
        <Row 
          justify="start"
          align="bottom" gutter={16}
          style={{ width: "83%", margin: "auto"}}
        >
          {MobileSEIFormFields.map((formField) => (
            <FormBuilderFieldComp formField={formField} form={form} />
          ))}
        </Row>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  )
}

export default MobileSEIFormPage;