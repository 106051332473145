import { Form, Radio, RadioProps, Select, SelectProps } from "antd";
import { Key } from "antd/lib/table/interface";
import { ReactNode } from "react";

export type OptionType<T> = {
  value: T;
  key?: any;
  label: string;
};

export type OptionField<T> = {
  label: string;
  options: OptionType<T>[];
  formKey?: string;
  formName?: string | (number | string)[];
};

type OptionsFieldProps<T> = {
  formOptions: OptionField<T>[];
  presentation: "radio" | "select" | "multiselect";
  required: boolean;
  initialValue?: any;
};

export const OptionsField = <T extends Key>(
  props: OptionsFieldProps<T> & { children?: ReactNode } & RadioProps &
    SelectProps<any>
) => {
  const { formOptions, presentation, onChange, required, initialValue } = props;
  return (
    <>
      {formOptions.map(({ formKey, formName, label, options }) => (
        <Form.Item
          key={
            formKey
              ? formKey
              : formName
              ? Array.isArray(formName)
                ? formName.join(" ")
                : formName
              : ""
          }
          name={formName}
          label={label}
          rules={[{ required: required }]}
          initialValue={initialValue}
        >
          {presentation === "radio" ? (
            <Radio.Group buttonStyle="solid" onChange={onChange}>
              {options.map((option) => (
                <Radio.Button value={option.value}>{option.label}</Radio.Button>
              ))}
            </Radio.Group>
          ) : presentation === "select" ? (
            <Select options={options} onChange={onChange} />
          ) : presentation === "multiselect" ? (
            <Select
              options={options}
              onChange={onChange}
              mode="multiple"
              allowClear
            />
          ) : null}
        </Form.Item>
      ))}
    </>
  );
};
