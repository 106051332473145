import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Row,
  Select,
  Space,
  Input,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { useEffect, useState } from "react";
import {
  CancerTypes,
  dosageFreqUnitSpanish,
  Dosages,
  getMedByName,
  meansOfAdminSpanish,
  MedicationNameOptions,
} from "../../util/OncologyFormUtil";
import { FullInputNumber } from "../StyledComponents";

const CancerMedDetails: React.FC<{
  field: FormListFieldData;
  selectedCancer: CancerTypes;
  idx: number;
  fieldIdx: number;
  form: FormInstance;
  keepDropdownsClosed: boolean;
}> = ({ field, selectedCancer, idx, fieldIdx, form, keepDropdownsClosed }) => {
  const [medication, setMedication] = useState<any | undefined>(
    form.getFieldValue(["cancerMedications", fieldIdx])?.medication[idx] || ""
  );
  const [presentation, setPresentation] = useState<string | undefined>("");
  const [showPanel, setShowPanel] = useState(keepDropdownsClosed);
  const [disableGivenMedDetails, setDisableGivenMedDetails] = useState(true)

  useEffect(() => {
    if (!medication) {
      setPresentation("");
      return;
    }
    if (!("presentation" in medication)) {
      setPresentation(getMedByName(medication?.medName)?.presentation);
    }
    setPresentation(medication.presentation);
  }, [medication]);

  useEffect(() => {
    // on load, enable givenMedDetails if needed
    const cancerMeds = form.getFieldValue("cancerMedications")
    if (cancerMeds[fieldIdx]?.medication[idx]?.medGiven === "no")
      setDisableGivenMedDetails(false)
  }, [])

  return (
    <Collapse
      activeKey={showPanel ? idx : -1}
      defaultActiveKey={0}
      expandIcon={({ isActive }) => (
        <button
          type="button"
          onClick={() => setShowPanel((prev) => !prev)}
          style={{
            border: "0",
            backgroundColor: "transparent",
            textDecoration: "none",
            boxShadow: "none",
            outline: "none",
            width: "100%",
            display: "block",
            textAlign: "left",
          }}
        >
          {medication ? getMedByName(medication?.medName)?.name || medication.name : " "}{" "}
          <>&nbsp;</>
          {isActive ? <>&#9660;</> : <>&#9655;</>}
        </button>
      )}
    >
      <Collapse.Panel header={""} key={idx} extra={() => {}}>
        <Col>
          <Divider orientation="left">Farmaco</Divider>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item name={[field.name, "medName"]}>
              <Select
                placeholder="Seleccionar medicación"
                options={MedicationNameOptions(selectedCancer)}
                defaultValue=""
                onChange={(value) => {
                  setMedication(getMedByName(value));
                }}
              />
            </Form.Item>
            <Form.Item 
              name={[field.name, "medNameOther"]}
              label="Otros"
            >
                <Input disabled={medication}/>
            </Form.Item>
          </Space>
          <Divider orientation="left">Presentacion</Divider>
          <Space direction="vertical" style={{ width: "100%" }}>
            {presentation}
            <Divider orientation="left">Via de administracion</Divider>
          </Space>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item name={[field.name, "adminMethod"]}>
              <Checkbox.Group
                options={Object.entries(meansOfAdminSpanish).map(
                  ([key, value]) => ({
                    label: value,
                    value: key,
                  })
                )}
              />
            </Form.Item>
            <Divider orientation="left">Dosis</Divider>
          </Space>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Row justify="center" gutter={8}>
                <Col span={12}>
                  <Form.Item name={[field.name, "dosage"]}>
                    <FullInputNumber placeholder="Seleccionar cantidad" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={[field.name, "dosageUnit"]}>
                    <Select
                      placeholder="Seleccionar unidad"
                      options={Dosages.map((d) => ({
                        label: d,
                        value: d,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={[field.name, "dosageFrequency"]}>
                    <Select
                      placeholder="Seleccione frecuencia"
                      options={Object.entries(dosageFreqUnitSpanish).map(
                        ([key, value]) => ({
                          label: value,
                          value: key,
                        })
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={[field.name, "numOfDoses"]}>
                    <FullInputNumber placeholder="Número de dosis" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Divider orientation="left">Fechas</Divider>
            Fecha de aplicación de la...
            <Row justify="center">
              <Space direction="horizontal">
                <Form.Item
                  label="Inicial dosis"
                  name={[field.name, "doseBeginDate"]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label="Ultima dosis"
                  name={[field.name, "doseEndDate"]}
                >
                  <DatePicker />
                </Form.Item>
              </Space>
            </Row>
            {/* Fecha de radioterapia...
            <Row justify="center">
              <Space direction="horizontal">
                <Form.Item
                  label="Inicial"
                  name={[field.name, "radiotherapyBeginDate"]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label="Ultima"
                  name={[field.name, "radiotherapyEndDate"]}
                >
                  <DatePicker />
                </Form.Item>
              </Space>
            </Row> */}
          </Space>
        </Col>
        <Divider orientation="left">Medicamento administrado</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Medicamento dado a la paciente"
              name={[field.name, "medGiven"]}
            >
              <Select
                options={[{label: "Si", value: "yes"}, {label: "No", value: "no"}]}
                onChange={(val) => {setDisableGivenMedDetails(val == "yes")}}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Motivo por el que no se administra la medicación"
              name={[field.name, "medNotGivenDetails"]}
            >
              <Input disabled={disableGivenMedDetails}/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button onClick={() => setShowPanel(false)}>Finalizado</Button>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
export default CancerMedDetails;
