import moment, { Moment, isMoment } from "moment";
import { restApi } from "./api";
import { error_message, success_message, SUCCESS_MSGS } from "./messages";
export type Role =
  | "none"
  | "administrator"
  | "social-worker"
  | "infectiology"
  | "emergency"
  | "oncology"
  | "Administrador"
  | "Emergencia"
  | "Infectología"
  | "Oncología"
  | "Trabajador social";

export type EnglishRole =
  | "none"
  | "administrator"
  | "social-worker"
  | "infectiology"
  | "emergency"
  | "oncology";

// has to be generic string so it can be compared against okta Groups
export const ResearcherRoles: string[] = [
  "social-worker",
  "infectiology",
  "emergency",
  "oncology",
]

export const RoleToLabel: { [key in Role]: Role } = {
  none: "none",
  administrator: "Administrador",
  Administrador: "Administrador",
  emergency: "Emergencia",
  Emergencia: "Emergencia",
  infectiology: "Infectología",
  Infectología: "Infectología",
  oncology: "Oncología",
  Oncología: "Oncología",
  "social-worker": "Trabajador social",
  "Trabajador social": "Trabajador social",
};

export type FormResource =
  | "SWForm"
  | "InfForm"
  | "EmergencyForm"
  | "OncologyForm"
  | "OutcomeForm"
  | "SignsSymptomsForm"
  | "TrackingForm"
  | "SEIForm"
  | "HistoryForm"
  | "MobileSymptomForm"
  | "MobileSEIForm"
  | "MobileDepressionForm"
  | "MobileFamilyForm"
  | "MobileStressForm"
  | "MobileSubstanceForm" ;

export type Entity = "staff" | "patient" | "administrator";

export type Patient = {
  id: string;
  sex: "male" | "female" | "indeterminate";
  curp?: string;
  himfgid?: string;
  name: string;
  family_name_father: string;
  family_name_mother: string;
  dob: string;
  phone_ext?: string;
  phone_num?: string;
  email?: string;
  sei?: number;
  most_recent_outcome_form_date: string;
  most_recent_emergency_form_date: string;
  day_3_follow_up: boolean;
  day_7_follow_up: boolean;
  day_10_follow_up: boolean;
  creation_date: string;
  is_mobile_registered: boolean;
  harmonyid: string;
  username: string;
};

export type Staff = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  role: Role;
  status: string;
  secondEmail: string;
};

export type PrevDoctor = {
  name: string;
  role: Role;
};

export const accountForGMT = (date: string | Moment | undefined) => {
  if (isMoment(date)) {
    return date.add(1, "days");
  } else if (typeof date === "string") {
    return moment(date).add(1, "days");
  }
  return null;
};

export interface FormResponse {
  form: any;
  prev_doctor: {
    name: string;
    role: Role;
  };
}

// *** COMMENTING OUT FOR PILOT PHASE *** //
// export const Permissions: { [key in FormResource]: Set<string> } = {
//   EmergencyForm: new Set(["admin", "emergency"]),
//   InfForm: new Set(["admin", "infectiology"]),
//   OncologyForm: new Set(["admin", "oncology"]),
//   SWForm: new Set(["admin", "social-worker"]),
//   OutcomeForm: new Set([
//     "admin",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   SignsSymptomsForm: new Set([
//     "admin",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   TrackingForm: new Set(["admin", "oncology", "infectiology"]),
// };

// export const Permissions: Record<FormResource, Set<string>> = {
//   EmergencyForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   InfForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   OncologyForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   SWForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   OutcomeForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   SignsSymptomsForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   TrackingForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
//   SEIForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology"
//   ]),
//   HistoryForm: new Set([
//     "administrator",
//     "social-worker, oncology, emergency, infectiology",
//   ]),
// };

export interface FormByIdParams {
  patientId: string;
  formId: string;
}

export interface RegisterPatientParams {
  patientId: string | undefined;
}

export const getFormById = async (formId: string, formType: FormResource) => {
  try {
    const { data } = await restApi().get<FormResponse>(`/${formType}`, {
      params: { formId: formId },
    });
    return data;
  } catch (e: any) {
    error_message(e.response.data.reason);
    Promise.reject(e);
    return;
  }
};

export const submitForm = async (
  info: {
    values: any;
    patientId: string;
    formId?: string;
  },
  formType: FormResource
) => {
  try {
    const { data } = await restApi().post("/" + formType, { ...info });
    success_message(SUCCESS_MSGS.FORM_SUBMITTED_SUCCESS);
    return data;
  } catch (e: any) {
    console.log('submission error',e);
    error_message(e);
  }
};

export type FormHistory = {
  id: string;
  submission_date: string;
};

export const FormLabels: { [key: string]: string } = {
  EmergencyForm: "Emergencia",
  SignsSymptomsForm: "Signos y síntomas",
  TrackingForm: "Seguimiento",
  OutcomeForm: "Desenlaces",
  SWForm: "Trabajo social",
  InfForm: "Infectología",
  OncologyForm: "Oncologia",
  SEIForm: "Indicadores",
  HistoryForm: "Historia",
  MobileSubstanceForm: "Uso de sustancias",
  MobileSymptomForm: "(Aplicación familiar) Síntomas",
  MobileSEIForm: "(Aplicación familiar) Indicadores",
  MobileDepressionForm: "(Aplicación familiar) Depresión",
  MobileFamilyForm: "(Aplicación familiar) Familia",
  MobileStressForm: "(Aplicación familiar) Estrés",
};

export const formatMultiselect = (val: any) => {
  if (typeof val !== "string") 
    return []
  
  let valArray = val
                  .replace(/[{}]/g, '')
                  .split(',')
                  .filter((i) => i !== "NULL")
  return valArray
}