import { OptionField } from "../components/OptionsField";


export const NeurologicalOptions: OptionField<string> = {
  label: "Neurológico",
  formName: "neurological",
  options: [
    { value: "no-alarm-data", label: "Sin datos de alarma" },
    { value: "persistant-headache", label: "Dolor de cabeza persistente" },
    { value: "mengineal-signs", label: "Signos meníngeos" },
    { value: "facial-paralysis", label: "Parálisis facial" },
    { value: "alterations-in-alertness", label: "Alteraciones en el estado de alerta" },
    { value: "hyperreflexia", label: "Hiperreflexia" },
    { value: "other", label: "Otra" },
  ]
}

export const CardiovascularOptions: OptionField<string> = {
  label: "Cardiovascular",
  formName: "cardiovascular",
  options: [
    { value: "without-alteration", label: "Sin alteración" },
    { value: "new-murmur", label: "Nuevo soplo asociado a anemia" },
    { value: "orthopnea", label: "Ortopnea" },
    { value: "tachycardia", label: "Taquicardia" },
    { value: "bradycardia", label: "Bradicardia" },
    { value: "capillary-greater-than-3", label: "Llenado capilar superior a 3 segundos" },
    { value: "capillary-greater-than-4", label: "Llenado capilar superior a 4 segundos" },
    { value: "hypotension", label: "Hipotensión" },
    { value: "other", label: "Otra" },
  ]
}

export const RespiratoryOptions: OptionField<string> = {
  label: "Respiratorio",
  formName: "respiratory",
  options: [
    { value: "without-alteration", label: "Sin alteración" },
    { value: "cough", label: "Tos" },
    { value: "rales", label: "Estertores" },
    { value: "wheezing", label: "Sibilancias" },
    { value: "dyspnoea", label: "Disnea" },
    { value: "saturation-less-than-90", label: "Saturación inferior al 90%" },
    { value: "other", label: "Otra" },
  ]
}

export const DigestiveOptions: OptionField<string> = {
  label: "Digestivo",
  formName: "digestive",
  options: [
    { value: "without-mod", label: "Sin modificación" },
    { value: "watery-diarrhea", label: "Diarrea acuosa" },
    { value: "hematochezia", label: "Hematoquecia" },
    { value: "mane", label: "Mane" },
    { value: "localized-abdominal-pain", label: "Dolor abdominal localizado" },
    { value: "diffuse-abdominal-pain", label: "Dolor abdominal difuso" },
    { value: "anal-fissure", label: "Fisura anal" },
    { value: "constipation", label: "Estreñimiento" },
    { value: "jaundice", label: "Ictericia" },
    { value: "hepatomegaly", label: "Hepatomegalia" },
    { value: "splenomegaly", label: "Esplenomegalia" },
    { value: "other", label: "Otra" },
  ]
}

export const GenitorunaryOptions: OptionField<string> = {
  label: "Genitourinario",
  formName: "genitorunary",
  options: [
    { value: "without-modifications", label: "Sin modificación" },
    { value: "dysuria", label: "Disuria" },
    { value: "hematuria", label: "Hematuria" },
    { value: "oliguria", label: "Oliguria" },
    { value: "anuria", label: "Anuria" },
    { value: "other", label: "Otra" },
  ]
}

export const SkinAndMucusOptions: OptionField<string> = {
  label: "Piel y mucosas",
  formName: "skinAndMucus",
  options: [
    { value: "without-alteration", label: "Sin alteración" },
    { value: "ulcers", label: "Úlceras"},
    { value: "soft-tissue-lesions", label: "Lesiones localizadas de tejidos blandos"},
    { value: "alterations-catheter-site", label: "Alteraciones localizadas en el sitio del catéter"},
    { value: "mild-mucositis", label: "Mucositis leve"},
    { value: "moderate-severe-mucositis", label: "Mucositis moderada/grave"},
    { value: "oral-ulcers", label: "Úlceras orales"},
    { value: "bruising", label: "Moretones"},
    { value: "petechiae", label: "Petequias"},
    { value: "other", label: "Otra" },
  ]
}

/** crop site are chest x-ray identical to emergency form*/