import React, { useMemo, useState } from "react";
import {
  Button,
  Collapse,
  Form,
  FormInstance,
  notification,
  Row,
  Select,
  Space,
  Popover,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { 
  SitesOfInf,
  updateSiteOfInf
} from "../../util/InfFormUtil";
import CultureDetails from "./CultureDetails";
import { OptionType } from "../OptionsField";
import InfPopover from "./InfPopover";

const getSelectedSite = (form: FormInstance, fieldPath: any[]) => {
  const siteOfInfLabel = form.getFieldValue([...fieldPath, "siteOfInf"])?.label;
  if (!siteOfInfLabel) {
    return "";
  }
  return siteOfInfLabel + " cultivos";
};

const MicroOrgForm: React.FC<{
  field: FormListFieldData;
  form: FormInstance;
  siteOfInfFields: OptionType<SitesOfInf>[];
  setSiteOfInfFields: React.Dispatch<React.SetStateAction<any[]>>;
  fieldPath: any[];
  keepDropdownsClosed: boolean;
  idx: number;
}> = ({
  field: fieldy,
  form,
  siteOfInfFields,
  setSiteOfInfFields,
  fieldPath,
  keepDropdownsClosed,
  idx,
}) => {
  const [prevSite, setPrevSite] = useState<any>();
  const [showPanel, setShowPanel] = useState(keepDropdownsClosed);
  const [siteOfInfToggle, setSiteOfInfToggle] = useState(false)

  const site = useMemo(
    () => getSelectedSite(form, fieldPath),
    [form, fieldPath]
  );

  const onChange = (value: OptionType<SitesOfInf>) => {
    if (value.value === "other") setSiteOfInfToggle(true)
    else setSiteOfInfToggle(false)

    if (!!prevSite) {
      setSiteOfInfFields((oldSites) => [...oldSites, prevSite]);
    }
    setSiteOfInfFields((oldSites) =>
      oldSites.filter((site) => !(site.value === value.value && value.value !== "other"))
    );
    const siteWithValue = siteOfInfFields.find(
      (site) => site.value === value.value
    );
    setPrevSite(siteWithValue);
  };

  const removeMicroOrg = (
    culture: any,
    remove: (path: number | number[]) => void
  ) => {
    notification.success({
      message: "Cultivo eliminado con éxito",
      duration: 3,
    });
    remove(culture.name);
  };

  return (
    <>
      <Popover
        placement="bottom"
        title="Otro lugar probably de infección"
        content={
          <InfPopover
            set={updateSiteOfInf(form, fieldPath)}
            toggle={setSiteOfInfToggle}
          />
        }
        visible={siteOfInfToggle}
      />
      <Form.Item
        {...fieldy}
        label="Lugar probable de infección"
        name={[fieldy.name, "siteOfInf"]}
        // fieldKey={[fieldy.fieldKey, "siteOfInf"]}
        required={true}
      >
        <Select
          options={siteOfInfFields.sort((a, b) =>
            a.label.localeCompare(b.label)
          )}
          onChange={onChange}
          labelInValue={true}
        />
      </Form.Item>

      <Collapse
        activeKey={showPanel ? idx : -1}
        defaultActiveKey={0}
        expandIcon={({ isActive }) => (
          <button
            type="button"
            onClick={() => setShowPanel((prev) => !prev)}
            style={{
              border: "0",
              backgroundColor: "transparent",
              textDecoration: "none",
              boxShadow: "none",
              outline: "none",
              width: "100%",
              display: "block",
              textAlign: "left",
            }}
          >
            {site}
            <>&nbsp;</>
            {isActive ? <>&#9660;</> : <>&#9655;</>}
          </button>
        )}
      >
        <Collapse.Panel header={""} key={idx} extra={() => {}}>
          <Form.List name={[fieldy.name, "cultures"]}>
            {(cultures, { add, remove }) => {
              return (
                <>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ width: "100%" }}
                  >
                    {cultures.map((culture, idx) => (
                      <Space
                        direction="vertical"
                        style={{ width: "100%" }}
                        size="middle"
                      >
                        <CultureDetails
                          key={`${[...fieldPath, "cultures", culture.name].join(
                            "-"
                          )}`}
                          field={culture}
                          form={form}
                          fieldPath={[...fieldPath, "cultures", culture.name]}
                        />
                        <Row justify="end">
                          <Button
                            danger
                            type="dashed"
                            onClick={() => removeMicroOrg(culture, remove)}
                          >
                            Quitar el cultivo
                          </Button>
                        </Row>
                      </Space>
                    ))}
                  </Space>
                  <Row justify="center">
                    <Button onClick={() => add()}>Agregar cultivo</Button>
                  </Row>
                </>
              );
            }}
          </Form.List>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default MicroOrgForm;
