import { Button, Input, Radio, Row, Space } from "antd";
import { useState } from "react";

type PopoverProps = {
  set: any;
  toggle: (visible: boolean) => void;
  isGramMenu?: boolean;
  setGram?: {
    setGramPosMicroorgsSet: React.Dispatch<React.SetStateAction<Set<string>>>;
    setGramNegMicroorgsSet: React.Dispatch<React.SetStateAction<Set<string>>>;
  };
};

const InfPopover: React.FC<PopoverProps> = ({
  set,
  toggle,
  isGramMenu,
  setGram,
}) => {
  const [customInput, setCustomInput] = useState("");
  const [gramStatus, setGramStatus] = useState<
    "positive" | "negative" | "none"
  >();

  const submit = async () => {
    set(["none", customInput]);
    // if (!setGram) {
    //   return;
    // }
    if (setGram) {
      const setter =
        gramStatus === "positive"
          ? setGram.setGramPosMicroorgsSet
          : gramStatus === "negative"
          ? setGram.setGramNegMicroorgsSet
          : null;

      // no gram positive/negative
      if (!setter) {
        toggle(false);
        return;
      }

      setter((prev: Set<string>) => {
        const newSet = new Set(prev);
        newSet.add(customInput);
        return newSet;
      });
    }
    // updateValue(customInput);
    toggle(false);
  };

  return (
    <Space direction="vertical">
      <Input onChange={(input) => setCustomInput(input.target.value)} />
      {isGramMenu && setGram ? (
        <Radio.Group>
          <Radio value="pos" onChange={(_) => setGramStatus("positive")}>
            Gram positivo?
          </Radio>
          <Radio value="neg" onChange={(_) => setGramStatus("negative")}>
            Gram negativo?
          </Radio>
          <Radio value="none" onChange={(_) => setGramStatus("none")}>
            Ninguno?
          </Radio>
        </Radio.Group>
      ) : null}
      <Row justify="end">
        <Space direction="horizontal">
          <Button type="primary" onClick={() => submit()}>
            OK
          </Button>
          <Button onClick={() => toggle(false)}>Cancelar</Button>
        </Space>
      </Row>
    </Space>
  );
};
export default InfPopover;
