import { OutcomeField } from "./InfFormUtil";

export const OutcomeOutcomeFields: OutcomeField[] = [
  {
    keyName: "hasEventRecovery",
    dateName: "eventRecoveryDate",
    label: "Recuperación del evento",
    furtherOptions: {
      optionsKey: "eventRecoveryDetails",
      options: [
        {
          label: "Egreso a casa con neutropenia",
          value: "Discharge with neutropenia",
        },
        {
          label: "Egreso a casa sin neutropenia",
          value: "Discharge without neutropenia",
        },
      ],
    },
  },
  {
    keyName: "hasSepsis",
    dateName: "sepsisDate",
    label: "Septicemia",
    furtherOptions: {
      optionsKey: "sepsisState",
      options: [
        {
          label: "Con aislamiento microbiológico",
          value: "With microbiological isolation",
        },
        {
          label: "Sin foco infeccioso aislado",
          value: "No isolated infectious focus",
        },
      ],
    },
  },
  {
    keyName: "hasSepsisHealthcare",
    dateName: "sepsisHealthcareDate",
    label: "Septicemia (asociado con el cuidado de la salud)",
    furtherOptions: {
      optionsKey: "sepsisHealthcareState",
      options: [
        {
          label: "Con aislamiento microbiológico",
          value: "With microbiological isolation",
        },
        {
          label: "Sin foco infeccioso aislado",
          value: "No isolated infectious focus",
        },
      ],
    },
  },
  {
    keyName: "hasSepticShock",
    dateName: "septicShockDate",
    label: "Choque séptico",
    furtherOptions: {
      optionsKey: "septicShockState",
      options: [
        { label: "Respuesta hídrica", value: "Water response" },
        {
          label: "Respuesta a vasopresores",
          value: "Response to vasopressors",
        },
        {
          label: "Respuesta vasopresores/inotrópicos",
          value: "Vasopressor / inotropic response",
        },
      ],
    },
  },
  {
    keyName: "hasBleeding",
    dateName: "bleedingDate",
    label: "Sangrado",
    furtherOptions: {
      optionsKey: "bleedingState",
      options: [
        { label: "Requirió transfusión", value: "Required transfusion" },
        {
          label: "Requirió alguna maniobra quirúrgica",
          value: "Required some surgical maneuver",
        },
      ],
    },
  },
  {
    keyName: "hasPersistentNeutropenia",
    dateName: "persistentNeutropenicDate",
    label: "Neutropenia persistente",
  },
  {
    keyName: "hasNeutropenicEntercolitis",
    dateName: "neutropenicEntercolitisDate",
    label: "Enterocolitis neutropénica",
    furtherOptions: {
      optionsKey: "neutropenicEntercolitisDetails",
      options: [
        {
          label: "Evidencia por tomografía o ultrasonido. Resolución con medidas habituales",
          value: "Evidence of tomography or ultrasound",
        },
        {
          label: "Pasó a quirófano y se realizó exploración.",
          value: "Operating room exam performed",
        },
        {
          label: "Cirugía y resección intestinal.",
          value: "Surgery and intestinal resection",
        },
      ]
    }
  },
  {
    keyName: "cancerTumorSurveillance",
    dateName: "cancerTumorSurveillanceDate",
    label: "Vigilancia de tumores cancerosos",
  },
  {
    keyName: "hasNosocomialInfection",
    dateName: "nosocomialInfectionDate",
    label: "Infección nosocomial",
  },
  {
    keyName: "hasHospiceCare",
    dateName: "hospiceCareDate",
    label: "Cuidados paleativos ",
  },
  {
    keyName: "hasTransplant",
    dateName: "transplantDate",
    label: "Transplante",
  },
  {
    keyName: "isDead",
    dateName: "deathDate",
    label: "Muerte",
  },
  {
    keyName: "notInStudy",
    dateName: "notInStudyDate",
    label: "No desea ingresar en el estudio."
  }
];
