import React, { useState } from "react";
import { Button, Dropdown, Input, Menu, Row, Table } from "antd";
import { useEffect } from "react";
import { ColumnsType } from "antd/lib/table";
import { Patient } from "../util/misc";
import { useMainStore } from "../state/mainStore";
import CenterSpinner from "../components/CenterSpinner";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { useAllPatients } from "../hooks/dataHooks";
import { usePatientStore } from "../state/patientStore";
import { ReactComponent as RefreshSVG } from "../icons/iconmonstr-refresh-1.svg";

const ListPatientsPage: React.FC = () => {
  const { role } = useMainStore();
  const { isLoading, refreshPatients } = useAllPatients();
  const [currPatients, setCurrPatients] = useState<Patient[]>();
  const { patients } = usePatientStore();
  const location = useLocation<Record<"refresh", boolean>>();
  const refresh = location.state ? location.state.refresh : false;

  useEffect(() => {
    if (refresh) {
      refreshPatients();
    }
  }, []);

  useEffect(() => {
    setCurrPatients(patients);
    // console.log(patients);
  }, [patients]);

  const timeToFollowUp = (patient: Patient) => {
    const timeDiff = moment().diff(
      moment(new Date(patient.most_recent_emergency_form_date)),
      "days"
    );

    return (
      ((timeDiff >= 3 && !patient.day_3_follow_up) ||
        (timeDiff >= 7 && !patient.day_7_follow_up) ||
        (timeDiff >= 10 && !patient.day_10_follow_up)) &&
      timeDiff < 90
    ); //arbitrary limit max time allowed to enter these forms
  };

  const columns: ColumnsType<Patient> = [
    {
      title: "Nombre",
      dataIndex: "fullName",
      sorter: (patient1, patient2) =>
        patient1.name.localeCompare(patient2.name),
      width: "20%",
      render: (_, patient) => (
        <Button style={{width: "100%"}}>
          <Link to={`/patientInfo/${patient.id}`}>{`${patient.name} ${patient.family_name_father}`}</Link>
        </Button> 
      ),
    },
    {
      title: "CURP",
      dataIndex: "curp",
      width: "20%",
    },
    {
      title: "HIMFG ID",
      dataIndex: "himfgid",
      width: "20%",
    },
    {
      title: "fecha de nacimiento",
      dataIndex: "dob",
      // render: (dob) => moment(dob).utc().format("YYYY-MM-DD"),
      sorter: (patient1, patient2) => patient1.dob.localeCompare(patient2.dob),
      width: "20%",
    },
    {
      title: "Sexo",
      dataIndex: "sex",
      sorter: (patient1, patient2) => patient1.sex.localeCompare(patient2.sex),
      filters: [
        { text: "Niño", value: "male" },
        { text: "Niña", value: "female" },
      ],
      render: (sex) => {
        switch (sex) {
          case "male":
            return "M";
          case "female":
            return "F";
          default:
            return "I";
        }
      },
      onFilter: (value, patient: Patient) => patient.sex === value,
      width: "10%",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Button onClick={refreshPatients} className="svg">
            <RefreshSVG />
          </Button>
        </div>
      ),
      // render: (_, patient) => formLink(patient),
    },
  ];

  const handleSearch = (e: string) => {
    if (!e) setCurrPatients(patients);
    const filtered = patients!.filter((patient) => {
      return Object.values(patient).some((property) => {
        if (!property) return false;
        return property.toString().toLowerCase().includes(e.toLowerCase());
      });
    });
    setCurrPatients(filtered);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) return;
    setCurrPatients(patients);
  };

  return (
    <>
      <Row justify="space-around" gutter={24} align="top">
        <Input.Search
          style={{ width: "90%", marginBottom: "20px" }}
          placeholder="Buscar..."
          onSearch={handleSearch}
          onChange={handleChange}
          enterButton
        />
      </Row>
      <Row justify="space-around" gutter={24} align="top">
        {!isLoading ? (
          <Table
            columns={columns}
            rowKey={(patient) => patient.id}
            // rowClassName={(patient) => (timeToFollowUp(patient) ? "alert" : "")}
            dataSource={currPatients}
            style={{
              margin: "0 auto",
              width: "90%",
            }}
          ></Table>
        ) : (
          <CenterSpinner />
        )}
      </Row>
    </>
  );
};

export default ListPatientsPage;
