import moment from "moment";
import { Moment } from "moment";
import { OptionField, OptionType } from "../components/OptionsField";
import { restApi } from "./api";
import { error_message, success_message, SUCCESS_MSGS } from "./messages";

export type HouseAmenityType =
  | "kitchen"
  | "livingRoom"
  | "diningRoom"
  | "sanitation"
  | "yard";
export const houseAmenities: OptionType<HouseAmenityType>[] = [
  { label: "Cocina", value: "kitchen" },
  { label: "Sala", value: "livingRoom" },
  { label: "Comedor", value: "diningRoom" },
  { label: "Sanitario", value: "sanitation" },
  { label: "Patio", value: "yard" },
];

export type HomeServiceType =
  | "water"
  | "light"
  | "drainage"
  | "phone"
  | "gas"
  | "cable"
  | "internet";
export const homeServices: OptionType<HomeServiceType>[] = [
  { label: "Agua", value: "water" },
  { label: "Luz", value: "light" },
  { label: "Drenaje", value: "drainage" },
  { label: "Teléfono", value: "phone" },
  { label: "Gas", value: "gas" },
  { label: "Cable", value: "cable" },
  { label: "Internet", value: "internet" },
];

export type PublicServicetype =
  | "waterSupply"
  | "lighting"
  | "sewage"
  | "paving"
  | "sewers"
  | "schools"
  | "phones"
  | "garbageCollection";
export const publicServices: OptionType<PublicServicetype>[] = [
  { label: "Abastecimiento de agua", value: "waterSupply" },
  { label: "Alumbrado", value: "lighting" },
  { label: "Drenaje", value: "sewage" },
  { label: "Pavimentación", value: "paving" },
  { label: "Alcantarillado", value: "sewers" },
  { label: "Escuelas", value: "schools" },
  { label: "Teléfonos", value: "phones" },
  { label: "Recolección de basura", value: "garbageCollection" },
];

export type ExpenseType =
  | "foodExp"
  | "rentExp"
  | "waterExp"
  | "lightExp"
  | "gasExp"
  | "phoneExp"
  | "transportExp"
  | "schoolExp"
  | "healthExp"
  | "domesticExp"
  | "additionalExp";
export const expenses: Record<ExpenseType, number> = {
  foodExp: 0,
  rentExp: 0,
  waterExp: 0,
  lightExp: 0,
  gasExp: 0,
  phoneExp: 0,
  transportExp: 0,
  schoolExp: 0,
  healthExp: 0,
  domesticExp: 0,
  additionalExp: 0,
};

type Finance = {
  name: string;
  relationship: string;
  monthlySalary: number;
};
export const finances: Finance[] = [];

type EducationType =
  | "illiterate"
  | "incomplete-primary"
  | "completed-primary"
  | "high-school"
  | "upper-average"
  | "professional"
  | "under-protection-inst"
  | "no-data";

export const educationOptions: OptionField<EducationType>[] = [
  {
    label: "Escolaridad",
    formName: "education",
    options: [
      { value: "illiterate", label: "Analfabeta" },
      { value: "incomplete-primary", label: "Primaria incompleta" },
      { value: "completed-primary", label: "Primaria completa" },
      { value: "high-school", label: "Secundaria" },
      { value: "upper-average", label: "Media superior" },
      { value: "professional", label: "Profesional" },
      { value: "under-protection-inst", label: "Bajo protección inst" },
      { value: "no-data", label: "Sin datos" },
    ],
  },
];

export const fatherEducationOptions: OptionField<EducationType>[] = [
  {
    label: "Escolaridad del padre",
    formName: "fatherEducation",
    options: [...educationOptions[0].options],
  },
];

export const motherEducationOptions: OptionField<EducationType>[] = [
  {
    label: "Escolaridad del madre",
    formName: "motherEducation",
    options: [...educationOptions[0].options],
  },
];

type MedStartType =
  | "less_than_3_months"
  | "between_3_and_6_months"
  | "more_than_6_months";

export const medStartOptions: OptionField<MedStartType>[] = [
  {
    label:
      "¿Hace cuánto tiempo inició atención médica por este padecimiento? (para clasificación socioeconómica)",
    formName: "timeSinceReceivingMedCare",
    options: [
      { value: "less_than_3_months", label: "Menos de 3 meses" },
      { value: "between_3_and_6_months", label: "De 3 a 6 meses" },
      { value: "more_than_6_months", label: "Más de 6 meses" },
    ],
  },
];

type AdditionalProblemsType = {
  illness: string;
  location: string;
};

type SickFamilyType = "none_sick" | "1_sick" | "2_or_more_sick";
export const sickFamilyOptions: OptionField<SickFamilyType>[] = [
  {
    label:
      "Estado de Salud de los Integrantes de la Familia: (sin incluir al paciente, para clasificación socioeconómica)",
    formName: "famHealth",
    options: [
      { value: "none_sick", label: "Ningún enfermo" },
      { value: "1_sick", label: "Un enfermo" },
      {
        value: "2_or_more_sick",
        label: "Dos o principal proveedor económico enfermo",
      },
    ],
  },
];

type FamilySickness = {
  relationship: string;
  diagnosis: string;
  placeOfTreatment: string;
};

type OccupationCategoryType =
  | "without-occupation"
  | "unskilled-worker"
  | "officers-operators-craftsmen"
  | "service-workers-markets"
  | "operators-installations-machines"
  | "farmers-agriculture-fishermen"
  | "office-employees"
  | "retired"
  | "pensioners"
  | "midlevel-technicians-professionals"
  | "professionals-scientists-intels"
  | "armed-forces"
  | "execs-investors";

export const occupationCategoryOptions: OptionField<OccupationCategoryType>[] =
  [
    {
      formName: "jobCategory",
      label: "Categoría de trabajo (para clasificación socioeconómica)",
      options: [
        { value: "without-occupation", label: "Sin ocupación" },
        { value: "unskilled-worker", label: "Trabajadores no calificados" },
        {
          value: "officers-operators-craftsmen",
          label:
            "Oficiales, operarios y artesanos de artes mecánicas y de otrosoficios",
        },
        {
          value: "service-workers-markets",
          label:
            "Trabajadores de los servicios y vendedores de comercios y mercados",
        },
        {
          value: "operators-installations-machines",
          label: "Operadores de instalaciones, máquinas y montadores",
        },
        {
          value: "farmers-agriculture-fishermen",
          label:
            "Agricultores y trabajadores calificados agropecuarios y pesqueros",
        },
        { value: "office-employees", label: "Empleados de oficina" },
        { value: "retired", label: "Jubilados" },
        { value: "pensioners", label: "Pensionados" },
        {
          value: "midlevel-technicians-professionals",
          label: "Técnicos y profesionales a nivel medio",
        },
        {
          value: "professionals-scientists-intels",
          label: "Profesionales científicos e intelectuales",
        },
        { value: "armed-forces", label: "Fuerzas armadas" },
        { value: "execs-investors", label: "Ejecutivos e inversionistas" },
      ],
    },
  ];

type HousingRightsType =
  | "other-rights"
  | "leased-rented-mortgaged"
  | "loan"
  | "own-paid";

export const housingRightsOptions: OptionField<HousingRightsType>[] = [
  {
    formName: "typeOfHolding",
    label:
      "Derechos Reales (Tipo de tenencia, para clasificación socioeconómica)",
    options: [
      {
        value: "other-rights",
        label:
          "Otro (institucional, albergues, reclusorios, casas de retiro o sin vivienda)",
      },
      {
        value: "leased-rented-mortgaged",
        label: "Arrendada (rentada), hipotecada o con gravamen",
      },
      { value: "loan", label: "Prestada" },
      { value: "own-paid", label: "Propia pagada" },
    ],
  },
];

type HousingType = "group-1" | "group-2" | "group-3" | "group-4" | "group-5";
export const housingTypeOptions: OptionField<HousingType>[] = [
  {
    formName: "typeOfDwelling",
    label: "Tipo de Vivienda (para clasificación socioeconómica)",
    options: [
      {
        value: "group-1",
        label:
          "Grupo 1: Institución de protección social, vivienda móvil, casa rural, refugio, cuarto redondo o sin vivienda; local no construido para habitación.",
      },
      {
        value: "group-2",
        label: "Grupo 2: Vecindad o cuarto de servicio o azotea.",
      },
      {
        value: "group-3",
        label:
          "Grupo 3: Área o casa popular en unidades habitacionales (interés social).",
      },
      {
        value: "group-4",
        label:
          "Grupo 4: Área o casa clase media con financiamiento propio o hipoteca.",
      },
      { value: "group-5", label: "Grupo 5: Área o casa residencial" },
    ],
  },
];

type NumRoomsType = "5_or_more" | "3_or_4" | "1_or_2";
export const numRoomsOptions: OptionField<NumRoomsType>[] = [
  {
    formName: "numBedrooms",
    label: "Número de dormitorios (para clasificación socioeconómica):",
    options: [
      { value: "5_or_more", label: "5 o mas habitaciones" },
      { value: "3_or_4", label: "De 3 a 4 habitaciones" },
      { value: "1_or_2", label: "De 1 a 2 habitaciones" },
    ],
  },
];

type PeoplePerRoomType = "1_or_2_people" | "3_people" | "4_or_more_people";
export const peoplePerRoomOptions: OptionField<PeoplePerRoomType>[] = [
  {
    formName: "numPeoplePerBedroom",
    label: "No. de personas por dormitorio (para clasificación socioeconómica)",
    options: [
      { value: "1_or_2_people", label: "1 a 2 personas" },
      { value: "3_people", label: "3 personas" },
      { value: "4_or_more_people", label: "4 o más personas" },
    ],
  },
];

type HouseMaterialType = "stone" | "mixed" | "cardboard_wood_waste";
export const houseMaterialOptions: OptionField<HouseMaterialType>[] = [
  {
    formName: "constructionMaterial",
    label: "Material de Construcción",
    options: [
      { value: "stone", label: "Mampostería" },
      { value: "mixed", label: "Mixta" },
      {
        value: "cardboard_wood_waste",
        label: "Lámina de cartón, madera, material de desecho o de la región",
      },
    ],
  },
];

type LocationType = "urban" | "suburban" | "rural";
export const locationTypeOptions: OptionField<LocationType>[] = [
  {
    formName: "locationType",
    label: "Zona de ubicación",
    options: [
      { value: "urban", label: "Urbana" },
      { value: "suburban", label: "Suburbana" },
      { value: "rural", label: "Rural" },
    ],
  },
];

export const prepInitialValues = async (values: any) => {
  const startTime = values["startTime"];
  const endTime = values["endTime"];
  const date = values.collectionDate;
  const newValues: SWFormType = {
    ...values,
    startTime: moment(startTime, "HH:mm:ss"),
    endTime: moment(endTime, "HH:mm:ss"),
    collectionDate: moment(date).utc(),
    deficit: values.economicSummary.deficit,
    surplus: values.economicSummary.surplus,
    costIncomePerc: values.economicSummary.balance,
    homeServices: Object.keys(values.homeServices).filter(
      (service) => values.homeServices[service]
    ),
    publicServices: Object.keys(values.publicServices).filter(
      (service) => values.publicServices[service]
    ),
    houseAmenities: Object.keys(values.roomTypes).filter(
      (room_type) => values.roomTypes[room_type]
    ),
    workPhone: values.job.phoneNum,
    workSchedule: values.job.hours,
    workplace: values.job.place,
    workYears: values.job.yearsWorked,
    physicalCogLimits: values.physicalOrCognitiveLimitations.hasLimitations,
    physicalCogLimitsDetails: values.physicalOrCognitiveLimitations.details,
    rightBenDetails: values.rightHolderOrBenficiary.relationship,
    rightHolderOrBeneficiary:
      values.rightHolderOrBenficiary.isRightHolderOrBeneficiary,
    totalMonthlyExpenses: (
      Object.values(values.monthlyExpenses) as number[]
    ).reduce((a, b) => a + b, 0),
    foodExp: values.monthlyExpenses.foodExp,
    gasExp: values.monthlyExpenses.gasExp,
    lightExp: values.monthlyExpenses.lightExp,
    phoneExp: values.monthlyExpenses.phoneExp,
    schoolExp: values.monthlyExpenses.schoolExp,
    domesticExp: values.monthlyExpenses.domesticExp,
    additionalExp: values.monthlyExpenses.additionalExp,
    totalMonthlyIncome:
      // values.familyFinanceForm[values.familyFinanceForm.length - 1][
      //   "totalMonthlySalary"
      // ],
      values.personsRelationshipsSalaries[
        values.personsRelationshipsSalaries.length - 1
      ]["totalMonthlySalary"],
    familyFinanceForm: values.personsRelationshipsSalaries,
    familyHealthStatus: values.familyHealthStatus,
    additionalProblems: values.additionalProblemsForm,
    additionalProblemsForm: values.additionalProblems,
    familySicknessForm: values.famHealthNamesDiagnosesAndPlacesOfTreatment,
    permState: values.permState,
    isCivilReg: values.isCivilReg,
    motherIsCivilReg: values.motherIsCivilReg,
    fatherIsCivilReg: values.fatherIsCivilReg,
  };
  return newValues;
};

export type SWFormType = {
  patientId?: string;
  formId?: string;
  additionalProblemsForm?: AdditionalProblemsType[];
  additionalProblems?: boolean;
  service: string;
  startTime?: Moment | string | null;
  endTime?: Moment | string | null;
  date?: Moment | string | null;
  refInstitution?: string;
  infoSourceName?: string;
  infoSourceRelation?: string;
  deleMuni?: string;
  diagnosis?: string;
  state?: string;
  sex: "male" | "female";
  nationality?: string;
  attendDeliv?: string;
  education?: EducationType;
  isCivilReg?: boolean;
  languages?: string;
  religion?: string;
  fatherName?: string;
  fatherEducation?: EducationType;
  fatherIsCivilReg?: boolean;
  motherName: string;
  motherEducation?: EducationType;
  motherIsCivilReg?: boolean;
  permStreet?: string;
  permNumExtInt?: number;
  permSuburbRural?: string;
  permCp?: string;
  permDeleMuni?: string;
  permState?: string;
  permPhonePersonal?: string;
  permPhoneCell?: string;
  permPhoneMessage?: string;
  accommodatorName?: string;
  accommodatorRelationship?: string;
  diagonsis?: string;
  medStartWhen?: MedStartType;
  familyHealthStatus?: SickFamilyType;
  numEconDependents?: number;
  mainEconOccupation?: string;
  workplace?: string;
  workSchedule?: string;
  workPhone?: string;
  workYears?: string;
  occupationCategory?: OccupationCategoryType;
  houseRights?: HousingRightsType;
  houseType?: HousingType;
  numRooms?: NumRoomsType;
  houseAmenities?: HouseAmenityType[];
  peoplePerRoom?: PeoplePerRoomType;
  numFamilies?: number;
  houseMaterial?: HouseMaterialType;
  walls?: string;
  floor?: string;
  homeServices?: HomeServiceType[];
  publicServices?: PublicServicetype[];
  locationType?: LocationType;
  initialDiagnosis?: string;
  age?: number;
  familyFinanceForm?: Finance[];
  foodExp?: number;
  lightExp?: number;
  gasExp?: number;
  phoneExp?: number;
  schoolExp?: number;
  domesticExp?: number;
  additionalExp?: number;
  familySicknessForm?: FamilySickness[];
  physicalCogLimitsDetails?: string;
  totalMonthlyExpenses?: number;
  totalMonthlyIncome?: number;
  deficit?: number;
  surplus?: number;
  costIncomePerc?: number;
  rightHolderOrBeneficiary?: boolean;
  rightBenDetails?: string;
};

export const submitSWForm = async (values: SWFormType) => {
  try {
    const { data } = await restApi().post("/SWForm", values);
    success_message(SUCCESS_MSGS.FORM_SUBMITTED_SUCCESS);
    return data;
  } catch (e: any) {
    console.log(e);
    error_message(e);
  }
};

export const SexOptions: OptionField<"male" | "female" | "indeterminate">[] = [
  {
    formName: "sex",
    label: "Sexo",
    options: [
      { label: "Niño", value: "male" },
      { label: "Niña", value: "female" },
      { label: "Indeterminado", value: "indeterminate" },
    ],
  },
];
