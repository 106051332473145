import {
  Card,
  Cascader,
  Form,
  FormInstance,
  InputNumber,
  Popover,
  Row,
  Space,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import React, { useEffect, useState } from "react";
import { medicines, updateMedication } from "../../util/InfFormUtil";
import { OptionsField } from "../OptionsField";
import InfPopover from "./InfPopover";

type AntibioticsProps = {
  form: FormInstance;
  field: FormListFieldData;
  fieldPath: any[];
};
const Antibiotics: React.FC<AntibioticsProps> = ({
  form,
  field,
  fieldPath,
}) => {
  const [med, setMed] = useState([""]);
  const [otherMedToggle, setOtherMedToggle] = useState(false);

  useEffect(() => {
    console.log(med);
  }, [med]);
  const onChange = (value: any) => {
    if (value[1] === "other") {
      setOtherMedToggle(true);
    } else {
      setOtherMedToggle(false);
      setMed(value);
    }
  };

  const filter = (inputValue: any, path: any) => {
    return path.some(
      (option: any) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  return (
    <Card>
      <Row justify="space-around">
        <Space direction="horizontal" size="large">
          <OptionsField
            formOptions={[
              {
                formName: [field.name, "administration"],
                label: "Vía administración",
                options: [
                  { label: "Oral", value: "oral" },
                  { label: "Intravenosa", value: "intravenous" },
                ],
              },
            ]}
            presentation="radio"
            required={true}
          />
          <Form.Item name={[field.name, "dosage"]} label="Dosis (kg/peso/día)">
            <InputNumber />
          </Form.Item>
        </Space>
      </Row>
      <Row justify="space-around">
        <Space direction="horizontal" size="large">
          <Form.Item
            name={[field.name, "frequency"]}
            label="Frecuencia (horas)"
            required={true}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name={[field.name, "administrationTime"]}
            label="Tiempo de administración (días)"
            required={true}
          >
            <InputNumber />
          </Form.Item>
        </Space>
      </Row>
      <Popover
        placement="bottom"
        title="Otro sitio del cultivo"
        content={
          <InfPopover
            set={updateMedication(form, fieldPath)}
            toggle={setOtherMedToggle}
          />
        }
        visible={otherMedToggle}
      />
      <Form.Item
        name={[field.name, "medicineFamily"]}
        label="Medicamentos administrados"
        required={true}
      >
        <Cascader
          options={medicines}
          onChange={onChange}
          value={med}
          expandTrigger="hover"
          showSearch={{ filter }}
          placeholder={"Seleccione un medicamento"}
          displayRender={(label, selectedOptions) =>
            typeof label === "string" ? label : label[1]
          }
        />
      </Form.Item>
    </Card>
  );
};

export default Antibiotics;
