import {
  Row,
  Form,
  Col,
  Divider,
  Radio,
  Steps,
  Button,
  RadioChangeEvent,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Moment } from "moment";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import {
  prepInitialValues,
  TriageLevelToFormKeys,
} from "../util/EmergencyFormUtil";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
} from "../util/misc";
import EmergencyFormPart1 from "./EmergencyFormPart2";
import EmergencyFormPart2 from "./EmergencyFormPart1";
import EmergencyFormPart3 from "./EmergencyFormPart3";
import FormSkeleton from "../components/FormSkeleton";

const steps = [
  { title: "Primera página", content: "First-content" },
  { title: "Segunda pagina", content: "Second-content" },
  { title: "Tercera pagina", content: "Third-content" },
];

const EmergencyFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory();
  const [form] = Form.useForm();

  const [familySigned, setFamilySigned] = useState(false);
  const [step, setStep] = useState(0);
  const [triageLevel, setTriageLevel] = useState(1);
  const [abdomenPlateArray, setAbdomenPlateArray]  = useState<string[]>([]);
  const [xrayArray, setXrayArray]  = useState<string[]>([]);
  const formName = "EmergencyForm"

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data["form"]) {
        setIsLoading(false);
        return;
      }
      const values = data["form"];
      const prevDoctorValues = data["prev_doctor"];
      setTriageLevel(values.triageLvlAssigned);
      setFamilySigned(values.relativeSigned);
      setPrevDoctor(prevDoctorValues);
      const newValues = await prepInitialValues(values);
      setInitialValues(newValues);
      setIsLoading(false);
      form.resetFields(Object.keys(newValues));
    })();
  }, []);

  const onFinish = async (values: any) => {
    if (!familySigned) { // must check 'familySigned' checkbox
      console.log('Family must sign.')
      setStep(1)
      const signBox = document.getElementById("familySign")
      signBox?.classList.add("pulse")
      setTimeout(() => {
        signBox?.classList.remove("pulse")
      }, 750)
      return;
    }

    if (isSubmitting) return
    else setIsSubmitting(true)
    setIsLoading(true);

    values = {
      ...values,
      date: (values.date as Moment).format("YYYY-MM-DD"),
      timeArrived: (values.timeArrived as Moment).format("HH:mm"),
      triageBegin: (values.triageBegin as Moment).format("HH:mm"),
      triageEnd: (values.triageEnd as Moment).format("HH:mm"),
      cultivationDate: values.cultivationDate
        ? (values.cultivationDate as Moment).format("YYYY-MM-DD")
        : null,
      movedToUTIPDate: values.movedToUTIPDate
        ? (values.movedToUTIPDate as Moment).format("YYYY-MM-DD")
        : null,
      movedToCovidAreaDate: values.movedToCovidAreaDate
        ? (values.movedToCovidAreaDate as Moment).format("YYYY-MM-DD")
        : null,
    };
    await submitForm({ values: values, patientId: patientId, formId: formId }, formName);
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  const handleTriageChange = (e: RadioChangeEvent) => {
    setTriageLevel(e.target.value);
    TriageLevelToFormKeys[e.target.value].forEach((triageLevelFormKeyObj) => {
      form.setFieldsValue({ [triageLevelFormKeyObj.formKey]: false });
    });
  };

  // special handling for errors on page 2 of form since the 'scroll to first error' won't work there.
  const handleSubmit = () => {
    form.submit()
    setTimeout(() => { // wait a milisecond to check for invalid fields
      const page2 = document.querySelector("#page2")
      const page2error = page2 ? page2.querySelector(".ant-form-item-has-error") : null
      if (page2error) { //if page2error, go to page two and scroll it into view
        setStep(1)
        page2error.scrollIntoView({behavior: "smooth"})
      }
    }, 1)
  }

  return !isLoading ? (
    <>
      <FormSkeleton
        onSubmit={handleSubmit}
        disableCallback={() => step !== 2}
      >
        <Form
          form={form}
          className="data-form"
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          scrollToFirstError={true}
        >
          <GeneralInfo
            patientId={patientId}
            prevDoctor={prevDoctor}
            formTitle="Formulario de Emergencia"
          />

          <div style={{ display: step === 0 ? "block" : "none" }}>
            <Divider orientation="left"></Divider>
            <Row justify="center" gutter={16}>
              <Form.Item
                key="triageLvlAssigned"
                name="triageLvlAssigned"
                label="Nivel de Triage Asignado"
                rules={[{ required: true}]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={1} onChange={handleTriageChange}>
                    1
                  </Radio.Button>
                  <Radio.Button value={2} onChange={handleTriageChange}>
                    2
                  </Radio.Button>
                  <Radio.Button value={3} onChange={handleTriageChange}>
                    3
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Row>
            <EmergencyFormPart2 triageLevel={triageLevel} />
          </div>
          <div
            style={{
              display: step === 1 ? "block" : "none",
              marginTop: "3%",
            }}
          >
            <EmergencyFormPart1 setFamilySigned={setFamilySigned} form={form} patientId={patientId}/>
          </div>
          <div
            style={{
              display: step === 2 || step === 3 ? "block" : "none",
              marginTop: "3%",
            }}
          >
            <EmergencyFormPart3 
              setAbdomenPlateArray={setAbdomenPlateArray}
              abdomenPlateArray = {abdomenPlateArray}
              setXrayArray = {setXrayArray}
              xrayArray = {xrayArray}
            />
          </div>
          <Row justify="center" style={{ marginBottom: "3%", marginTop: "3%" }}>
            <Col span={4}>
              <Button
                disabled={step === 0}
                onClick={() => setStep((prev) => prev - 1)}
              >
                Previo
              </Button>
            </Col>
            <Button
              disabled={step === 2}
              onClick={() => {
                setStep((prev) => prev + 1)
                window.scrollTo({top: 0, behavior: "smooth"})
              }}
            >
              Siguiente
            </Button>
          </Row>
        </Form>
        <Row
          justify="center"
          style={{ margin: "0 auto", marginBottom: "5%" }}
        >
          <Steps current={step}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
            <div
              className="steps-content"
              style={{ marginTop: "16px", backgroundColor: "cyan" }}
            >
              {steps[step].content}
            </div>
          </Steps>
        </Row>
      </FormSkeleton>
    </>
  ) : (
    <CenterSpinner />
  );
};

export default EmergencyFormPage;
