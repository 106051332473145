import React, { useEffect } from "react";
// import { useEffect} from "react";
import { PageHeader } from "antd";
import HeaderMenu from "./HeaderMenu";
import { useMainStore } from "../state/mainStore";
// import { logout } from "../util/auth";
import { LogoutButton } from "./StyledComponents";
import { useHistory } from "react-router-dom";
import { usePatientStore } from "../state/patientStore";
import { useStaffStore } from "../state/staffStore";
import { useOktaAuth } from "@okta/okta-react";

const Header: React.FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const { username, resetStore, setRole, setUsername, setIsLoggedIn } =
    useMainStore();
  const { resetPatients } = usePatientStore();
  const { resetStaff } = useStaffStore();
  const history = useHistory();

  const { authState, oktaAuth } = useOktaAuth();

  const logout = async () => {
    await oktaAuth.signOut();
    handleLogout();
  };

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  }

  const handleLogout = () => {
    // make sure store is clear
    resetStore();
    resetPatients();
    resetStaff();
    setRole(["none"]);
    setIsLoggedIn(false);
    if (!["/", "/privacypolicy"].includes(window.location.pathname)) history.push("/");
  };

  const extra = (
    <>
      <span style={{color: "lightgrey", fontSize: "0.8rem"}}>v1.7.12</span>
      <span>{username}</span>
      {authState?.isAuthenticated ? (
        <LogoutButton onClick={logout}> Log out </LogoutButton>
      ) : (
        <LogoutButton onClick={login}> Log in </LogoutButton>
      )}
    </>
  );

  useEffect(() => {
    const getAndSetUser = async () => {
      // get and store tokens
      await oktaAuth.handleLoginRedirect()
      // get okta user with stored token
      let user
      try {
        user = await oktaAuth.getUser();
      } catch (e) {
        console.log(e)
      }
      if (user) {
        // set user details in store
        const token = JSON.parse(
          window.localStorage.getItem("okta-token-storage") || "no token"
        );
        setUsername(user.name || "");
        setRole(token.accessToken.claims.roles)
        setIsLoggedIn(true); // loggedIn = true triggers changes to available routes
      } else {
        console.log("no user");
        handleLogout();
      }
    };
    getAndSetUser().catch(console.error);
  }, [authState]);

  return (
    <PageHeader title={"HIMFG Datos del Pacientes"} extra={extra}>
      <HeaderMenu />
    </PageHeader>
  );
};

export default Header;
