import { Button, Form, Input, InputNumber, Row } from "antd";
import styled from "styled-components";

export const RoundedInput = styled(Input)`
  border-radius: 25px;
`;

export const RoundedPw = styled(Input.Password)`
  border-radius: 25px;
`;

export const RoundedButton = styled(Button)`
  width: 100%;
  border-radius: 0.25rem;
`;

export const LogoutButton = styled(Button)`
  border-radius: 0.25rem;
`;

export const ButtonItem = styled(Form.Item)`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 0.25rem;
`;

export const FullInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const BackButton = styled(Button)`
  border-radius: 0.25rem;
  width: 12rem;
`;

export const BackButtonRow = styled(Row)`
  // width: 700px;
  margin: 0 auto;
`;

export const DataForm = styled(Form)`
  // width: 700px;
  margin: 0 auto;
`;
