import { Col, Form, Row, Divider, DatePicker } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  TriageLevel1FormKeys1,
  TriageLevel1FormKeys2,
  TriageLevel2FormKeys1,
  TriageLevel2FormKeys2,
  TriageLevel3FormKeys1,
  TriageLevel3FormKeys2,
} from "../util/EmergencyFormUtil";
import React, { useState } from "react";

const TriageLevel1Form = () => {
  return (
    <>
      {TriageLevel1FormKeys1.map((item, idx) => (
        <Row justify="space-around" align="stretch" gutter={8}>
          <Col span={6}>{item.label}</Col>
          <Form.Item
            key={item.formKey}
            name={item.formKey}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Col span={6}>{TriageLevel1FormKeys2[idx].label}</Col>
          <Form.Item
            key={TriageLevel1FormKeys2[idx].formKey}
            name={TriageLevel1FormKeys2[idx].formKey}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Row>
      ))}
    </>
  );
};

const TriageLevel2Form = () => {
  return (
    <>
      {TriageLevel2FormKeys1.map((item, idx) => (
        <Row justify="space-around" align="stretch" gutter={8}>
          <Col span={6}>{item.label}</Col>
          <Form.Item
            key={item.formKey}
            name={item.formKey}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Col span={6}>{TriageLevel2FormKeys2[idx].label}</Col>
          <Form.Item
            key={TriageLevel2FormKeys2[idx].formKey}
            name={TriageLevel2FormKeys2[idx].formKey}
            valuePropName="checked"
            initialValue={false}
          >
            {TriageLevel2FormKeys2[idx].label ? <Checkbox></Checkbox> : null}
          </Form.Item>
        </Row>
      ))}
    </>
  );
};

const TriageLevel3Form = () => {
  return (
    <>
      {TriageLevel3FormKeys1.map((item, idx) => (
        <Row justify="space-around" align="stretch" gutter={8}>
          <Col span={6}>{item.label}</Col>
          <Form.Item
            key={item.formKey}
            name={item.formKey}
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Col span={6}>{TriageLevel3FormKeys2[idx].label}</Col>
          <Form.Item
            key={TriageLevel3FormKeys2[idx].formKey}
            name={TriageLevel3FormKeys2[idx].formKey}
            valuePropName="checked"
            initialValue={false}
          >
            {TriageLevel3FormKeys2[idx].label ? <Checkbox></Checkbox> : null}
          </Form.Item>
        </Row>
      ))}
    </>
  );
};

const EmergencyFormPart2: React.FC<{ triageLevel: number }> = ({
  triageLevel,
}) => {

  const [movedToFloor, setMovedToFloor] = useState(Boolean);
  const [movedToUTIP, setMovedToUTIP] = useState(Boolean);
  const [movedToCovid, setMovedToCovid] = useState(Boolean);

  return (
    <>
      {triageLevel === 1 ? (
        <TriageLevel1Form />
      ) : triageLevel === 2 ? (
        <TriageLevel2Form />
      ) : (
        <TriageLevel3Form />
      )}
      <Divider></Divider>
      <Row justify="space-around" gutter={8}>
        <Col span={9}>
          <Form.Item
            key="movedToPiso"
            name="movedToPiso"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox onChange={(e) => {setMovedToFloor(e.target.checked)}}>
              Movido a piso
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            key="movedToPisoDate"
            name="movedToPisoDate"
            label="Movida a piso Fecha"
            rules={[{ required: movedToFloor }]}
          >
            <DatePicker disabled={!movedToFloor}/>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            key="movedToUTIP"
            name="movedToUTIP"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox onChange={(e) => {setMovedToUTIP(e.target.checked)}}>
              Movido a UTIP
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            key="movedToUTIPDate"
            name="movedToUTIPDate"
            label="Movida a UTIP Fecha"
            rules={[{ required: movedToUTIP }]}
          >
            <DatePicker disabled={!movedToUTIP}/>
          </Form.Item>
        </Col>

        <Col span={9}>
          <Form.Item
            key="movedToCovidArea"
            name="movedToCovidArea"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox onChange={(e) => {setMovedToCovid(e.target.checked)}}>
              Movido a COVID-19
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            key="movedToCovidAreaDate"
            name="movedToCovidAreaDate"
            label="Movida a COVID-19 Fecha"
            rules={[{ required: movedToCovid }]}
          >
            <DatePicker disabled={!movedToCovid}/>
          </Form.Item>
        </Col>
      </Row>
    </>
    
  )
};

export default EmergencyFormPart2;
