import { Divider, Row, Space } from "antd";
import moment from "moment";
import { usePatientById } from "../hooks/dataHooks";

import { calcAge } from "../util/InfFormUtil";
import { PrevDoctor } from "../util/misc";
import CenterSpinner from "./CenterSpinner";

interface GeneralInfoProps {
  patientId: string;
  prevDoctor: PrevDoctor;
  formTitle: string;
  extraInfo?: boolean;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  patientId,
  prevDoctor,
  formTitle,
  extraInfo,
}) => {
  const { isLoading, patient } = usePatientById(patientId);
  return !isLoading && patient ? (
    <>
      <style>
        {`
        .justify-row {
          display: grid;
          width: 100%;
        }
        .justify-row.two {
          grid-template-columns: repeat(2,1fr);
        }
        .justify-row.three {
          grid-template-columns: repeat(3,auto);
        }
        .justify-row.four {
          grid-template-columns: repeat(4,auto);
        }
      `}
      </style>
      <Divider style={{position: "sticky", top: "0", backgroundColor: "white", zIndex: "10"}}>
        <h1>
          {formTitle} 
          <span style={{color: "#1890ff"}}> (para {patient.name} {patient.family_name_father})</span>
        </h1>
      </Divider>
      <Row justify="center" align="middle">
        <Space size={20} className="justify-row four">
          <p><b>Nombre:</b> {patient.name} {patient.family_name_father}</p>
          <p><b>Fecha de nacimiento:</b> {moment(patient.dob).utc()!.format("YYYY-MM-DD")}</p>
          <p><b>Edad (años):</b> {calcAge(moment(patient.dob))}</p>
          <p><b>Sexo:</b> {patient.sex ? patient.sex[0].toUpperCase() : null}</p>
        </Space>
      </Row>
      <Row justify="center" align="middle">
        <Space className="justify-row two">
          <p><b>CURP:</b> {patient.curp}</p>
          <p><b>HIMFG ID:</b> {patient.himfgid}</p>
          <p><b>Harmony ID:</b> {patient.harmonyid}</p>
          <p><b>DB ID:</b> {patient.id}</p>
          {extraInfo ? (
            <>
              <p><b>Fecha de Registro:</b> {moment(patient.creation_date).utc()!.format("YYYY-MM-DD")}</p>
              <p><b>Última visita ER:</b> {patient.most_recent_emergency_form_date ? moment(patient.most_recent_emergency_form_date).utc()!.format("YYYY-MM-DD") : "-"}</p>
              <p><b>Teléfono:</b> {`${patient.phone_ext ? patient.phone_ext : ""}${patient.phone_num ? patient.phone_num : ""}`}</p>
              <p><b>Email:</b> {patient.email}</p>
              <p><b>Se registró en la aplicación familiar:</b> {patient.is_mobile_registered ? "Si" : "No"}</p>
              <p>{patient.is_mobile_registered ? <span><b>Nombre de usuario de la aplicación familiar:</b> {patient.username}</span> : ""}</p>
            </>
          ) : null}
        </Space>
      </Row>
    </>
  ) : !patient ? null : (
    <CenterSpinner />
  );
};
export default GeneralInfo;
