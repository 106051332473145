import { Button, Divider, Row, Table } from "antd";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import CenterSpinner from "../components/CenterSpinner";
import { BackButton } from "../components/StyledComponents";
import { useFormHistory, usePatientById } from "../hooks/dataHooks";
import { FormResource } from "../util/misc";
import { ColumnsType } from "antd/lib/table";
import { FormHistory, FormLabels } from "../util/misc";
import { ReactComponent as ViewSVG } from "../icons/iconmonstr-eye-3.svg";
import { useMainStore } from "../state/mainStore";

interface UrlParams {
  patientId: string;
  formType: FormResource;
}

interface ViewHistoryPageProps {
  formType: FormResource;
}

const ViewHistoryPage: React.FC<ViewHistoryPageProps> = ({ formType }) => {
  const { patientId } = useParams<UrlParams>();
  const { isLoading, forms } = useFormHistory(patientId, formType);
  const history = useHistory();
  const location = useLocation<Record<string, boolean>>();
  const disableCreate = location.state && !!location.state.disableCreate;
  const hasPatient = location.state && location.state.patient;
  const { patient } = usePatientById(patientId);
  const { role } = useMainStore();
  // admins can edit all forms
  // anyone can edit emergency, infectiology, or history forms
  let canEdit = role.includes("administrator") || ["EmergencyForm", "InfForm", "HistoryForm"].includes(formType)

  const mobileForms = [
    'MobileDepressionForm',
    'MobileFamilyForm',
    'MobileStressForm',
  ]

  const formPath = `${mobileForms.includes(formType) ? "/mobile" : ""}/${formType}/${patientId}`

  const columns: ColumnsType<FormHistory> = [
    {
      title: "Fecha",
      dataIndex: "submission_date",
      width: "90%",
    },
    {
      title: "Opciones",
      render: (_, form) => (
        <Button className="svg">
          <Link
            to={{
              pathname: `${formPath}/${form.id}`,
              state: { viewOnly: !canEdit },
            }}
          >
            <ViewSVG />
          </Link>
        </Button>
      ),
    },
  ];

  return !isLoading && forms ? (
    <>
      <Row align="top" className="history nav">
        <BackButton onClick={() => history.push({ pathname: `/patientInfo/${patientId}` })}>
          &#8592; Regresa
        </BackButton>
        <Link
          to={{
            pathname: `${formPath}/new`,
            state: { patient: hasPatient },
          }}
          style={{ display: "grid" }}
        >
          <Button type="primary" disabled={disableCreate}>
            Crear nuevo formulario &#43;
          </Button>
        </Link>
      </Row>
      <Divider className="history-title">
        {FormLabels[formType]}
        <span style={{color: "#1890ff"}}> {patient ? `(para ${patient?.name} ${patient?.family_name_father})` : ""}</span>
      </Divider>
      <Row className="history body">
        <Table
          columns={columns}
          rowKey={(form) => form.id}
          dataSource={forms}
          style={{
            margin: "0 auto",
            width: "100%",
          }}
        ></Table>
      </Row>
    </>
  ) : (
    <CenterSpinner />
  );
};

export default ViewHistoryPage;
