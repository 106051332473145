import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Moment } from "moment";
import moment from "moment";
import { FormByIdParams, getFormById, PrevDoctor, submitForm, } from "../util/misc";

import {
  Col,
  Form,
  Row,
  Radio,
  DatePicker,
  Divider,
  Checkbox,
  Input,
} from "antd";
import { OptionField, OptionsField } from "../components/OptionsField";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import FormSkeleton from "../components/FormSkeleton";
import {
  NeurologicalOptions,
  CardiovascularOptions,
  RespiratoryOptions,
  DigestiveOptions,
  GenitorunaryOptions,
  SkinAndMucusOptions,
} from "../util/TrackingFormUtil";
import {
  BiochemicalIndicators,
  CropSiteOptions,
  ChestXrayOptions,
  AbdomenPlateOptions,
  prepInitialValues,
} from "../util/EmergencyFormUtil";
import { FullInputNumber } from "../components/StyledComponents";

const TrackingFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [movedToUTIP, setMovedToUTIP] = useState(Boolean);
  const [movedToFloor, setMovedToFloor] = useState(Boolean);
  const [movedToCovidArea, setMovedToCovidArea] = useState(Boolean);
  const [followUpDay, setFollowUpDay] = useState(Number);
  const [abdomenPlateArray, setAbdomenPlateArray] = useState<string[]>([]);
  const [xrayArray, setXrayArray] = useState<string[]>([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation<Record<string, any>>();
  const patient = location.state.patient;
  const timeDiff = patient
    ? moment().diff(
        moment(new Date(patient.most_recent_emergency_form_date)),
        "days"
      )
    : 0;
  const formName = "TrackingForm"

  const symptomsFields: {
    options: OptionField<string>, 
    type: "radio" | "select" | "multiselect",
  }[] = [
    {options: NeurologicalOptions, type: "multiselect"},
    {options: CardiovascularOptions, type: "multiselect"},
    {options: RespiratoryOptions, type: "multiselect"},
    {options: DigestiveOptions, type: "multiselect"},
    {options: GenitorunaryOptions, type: "multiselect"},
    {options: SkinAndMucusOptions, type: "multiselect"},
  ]
  // kind of a weird/gross way to handle all of these together but it works
  const [symptomsOthers, setSymptomsOthers] = useState<boolean[]>([
    true, true, true, true, true, true
  ])
  const updateSymptomsOthers = (array:string[], idx:number) => {
    let newOthersArr = [...symptomsOthers]
    newOthersArr[idx] = !array.includes("other")
    setSymptomsOthers(newOthersArr)
  }

  let autoDayChoice = 3;
  let determiningDayChoice = false;

  if (!determiningDayChoice) {
    // this affects the dom, useEffect happens after render so it has to be outside of that.
    determiningDayChoice = true;

    if (patient) {
      // only for new form
      if (timeDiff >= 3 && timeDiff < 7 && !patient.day_3_follow_up) {
        autoDayChoice = 3;
      } else if (
        timeDiff >= 7 &&
        patient.day_3_follow_up &&
        !patient.day_7_follow_up &&
        timeDiff < 10
      ) {
        autoDayChoice = 7;
      } else if (
        timeDiff >= 10 &&
        patient.day_3_follow_up &&
        patient.day_7_follow_up
      ) {
        autoDayChoice = 10;
      } else {
        autoDayChoice = 0;
      }
    } else {
      autoDayChoice = 0;
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data.form) {
        setIsLoading(false);
        return;
      }
      const values = data.form;
      const prevDoctorValues = data.prev_doctor;
      setPrevDoctor(prevDoctorValues);
      const newValues = await prepInitialValues(values);
      setInitialValues(newValues);
      setIsLoading(false);
      form.resetFields(Object.keys(newValues));
    })();

    if (autoDayChoice) {
      //set followUpDay as needed
      form.setFieldsValue({
        followUpDay: autoDayChoice,
      });
    }
  }, []);

  const onFinish = async (values: any) => {
    if (!values) return;
    if (isSubmitting) return
    else setIsSubmitting(true)
    values = {
      ...values,
      date: (values.date as Moment).format("YYYY-MM-DD"),
      cultivationDate: values.cultivationDate ? (values.cultivationDate as Moment).format("YYYY-MM-DD") : null,
    };
    await submitForm(
      { values: values, patientId: patientId, formId: formId },
      "TrackingForm"
    );
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="data-form"
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Formulario de seguimiento"
        />
        <Row justify="center" align="bottom" gutter={16}>
          <Col span={12}>
            <Form.Item
              key="date"
              name="date"
              label="Fecha"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="followUpDay"
              name="followUpDay"
              label="Día de seguimiento"
              rules={[{ required: true }]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => setFollowUpDay(e.target.value)}
              >
                <Radio.Button value={3}>Día 3</Radio.Button>
                <Radio.Button value={7}>Día 7</Radio.Button>
                <Radio.Button value={10}>Día 10</Radio.Button>
                <Radio.Button value={99}>Otro</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}></Col> {/* spacer */}
          <Col span={12}>
            <Form.Item
              key="followUpDayOther"
              name="followUpDayOther"
              label="Especifique otro"
            >
              <Input disabled={followUpDay !== 99} />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Seguimiento</Divider>

        {/* Symptoms Categories */}
        {symptomsFields.map((field, idx) =>
          <Row justify="center" gutter={16}>
            <Col span={12}>
              <OptionsField
                formOptions={[field.options]} //formOptions wants an array
                presentation={field.type}
                required={false}
                onChange={e => updateSymptomsOthers(e, idx)}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                key={`${field.options.formName}Other`}
                name={`${field.options.formName}Other`}
                label="Otro"
              >
                <Input disabled={symptomsOthers[idx]}/>
              </Form.Item>
            </Col>
          </Row>
        )}

        {/* Vitals */}
        <Divider></Divider> 
        <Row justify="center" gutter={16}>
          {BiochemicalIndicators.map((item, idx) => (
            <Col span={12}>
              <Form.Item
                key={item.formKey}
                name={item.formKey}
                label={item.label}
                rules={[{ required: false, message: "Requerido" }]}
              >
                <FullInputNumber />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row justify="center" gutter={16}>
          <Col span={12}>
            <Form.Item
              key="cultivationDate"
              name="cultivationDate"
              label="Fecha del cultivo"
              rules={[{ required: false, message: "Requerido" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <OptionsField
              formOptions={CropSiteOptions}
              presentation="multiselect"
              required={false}
            />
          </Col>
        </Row>
        <Divider></Divider>
        <Row justify="center">
          <Col span={24}>
            <OptionsField
              formOptions={ChestXrayOptions}
              presentation="multiselect"
              required={false}
              onChange={(e) => setXrayArray(e)}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              key="chestXrayOther"
              name="chestXrayOther"
              label="Si es otro, explique"
              rules={[{ required: false }]}
            >
              <Input disabled={!xrayArray.includes("others")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <OptionsField
              formOptions={AbdomenPlateOptions}
              presentation="multiselect"
              required={false}
              onChange={(e) => setAbdomenPlateArray(e)}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              key="abdomenPlateOther"
              name="abdomenPlateOther"
              label="Si es otro, explique"
              rules={[{ required: false }]}
            >
              <Input disabled={!abdomenPlateArray.includes("others")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              key="decision"
              name="decision"
              label="Decisión"
              rules={[{ required: false }]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={"continue-at-home"}>
                  Continuar en casa
                </Radio.Button>
                <Radio.Button value={"hospitalized"}>
                  Esta hospitalizado
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              key="movedToUTIP"
              name="movedToUTIP"
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox
                onChange={(e) => {
                  setMovedToUTIP(e.target.checked);
                }}
              >
                Movido de ER a UTIP
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="movedToUTIPDate"
              name="movedToUTIPDate"
              label="ER &#8594; UCI Fecha"
              rules={[{ required: movedToUTIP }]}
            >
              <DatePicker disabled={!movedToUTIP} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              key="movedToCovidArea"
              name="movedToCovidArea"
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox
                onChange={(e) => {
                  setMovedToCovidArea(e.target.checked);
                }}
              >
                Movido de ER a COVID-19
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="movedToCovidAreaDate"
              name="movedToCovidAreaDate"
              label="ER &#8594; COVID-19 Fecha"
              rules={[{ required: movedToCovidArea }]}
            >
              <DatePicker disabled={!movedToCovidArea} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              key="movedToFloor"
              name="movedToFloor"
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox
                onChange={(e) => {
                  setMovedToFloor(e.target.checked);
                }}
              >
                Movido de UTIP a Piso
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              key="movedToFloorDate"
              name="movedToFloorDate"
              label="UTIP &#8594; Piso Fecha"
              rules={[{ required: movedToFloor}]}
            >
              <DatePicker disabled={!movedToFloor} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  );
};

export default TrackingFormPage;
