import { formatMultiselect } from "./misc";
import { formBuilderField } from "./formBuilder";
import { RelationshipToPatientField } from "./MobileGeneralFormUtil";

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
  };
  return newValues;
};

export const MobileSymptomFormFields: formBuilderField[] = [
  ...RelationshipToPatientField,
  {
    keyName: "HeartRate",
    type: "number",
    label: "¿Cuál es la frecuencia cardíaca del paciente?"
  },
  {
    keyName: "Temperature",
    type: "number",
    label: "¿Cuál es la temperatura del paciente?"
  },
  {
    keyName: "RespRate",
    type: "number",
    label: "¿Qué es la frecuencia respiratoria?"
  },
  {
    keyName: "AttitudeBehavior",
    type: "select",
    label: "¿Cómo es el comportamiento del paciente?",
    options: [
      { label: "Somnolencia (está adormilado y no es posible despertarlo o manternerlo despierto)", value: "drowsy" },
      { label: "Irritable", value: "irritable" },
      { label: "Adormilado", value: "sleepy" },
      { label: "Cansado", value: "tired" },
      { label: "Juega y está alerta", value: "playfulAlert" },
    ]
  },
  {
    keyName: "IsSkinPale",
    type: "boolean",
    label: "¿La piel se ve pálida?"
  },
  {
    keyName: "SkinTemperatureFeel",
    type: "select",
    label: "¿Cómo se siente la temperatura de la piel?",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Fría", value: "cold" },
      { label: "Caliente", value: "hot" },
    ]
  },
  {
    keyName: "CapillaryFill",
    type: "select",
    label: "¿Cómo se llena el capilar?",
    options: [
      { label: "Normal", value: "normal" },
      { label: "Anormal/Lento", value: "abnormal/slow" },
    ]
  },
  {
    keyName: "HasDifficultyBreathing",
    type: "boolean",
    label: "¿El paciente tiene dificultad para respirar?",
    span: 12,
  },
  {
    keyName: "IsSkinColorAbnormal",
    type: "boolean",
    label: "¿El color de la piel del paciente es anormal, como un color violeta o azul?",
    span: 12,
  },
  {
    keyName: "HasUrinatedLast8Hours",
    type: "boolean",
    label: "¿Ha orinado en las últimas 8 horas?",
    span: 12,
  },
  {
    keyName: "HasDiarrhea",
    type: "boolean",
    label: "¿Tiene diarrea?",
    span: 12,
  },
  {
    keyName: "HasVomited",
    type: "boolean",
    label: "¿Tienes vómito?",
    span: 12,
  },
  {
    keyName: "HasBleeding",
    type: "boolean",
    label: "¿Tiene sangrado en alguna parte del cuerpo?",
    span: 12,
  },
  {
    keyName: "HasStoppedDrinkingLast8Hours",
    type: "boolean",
    label: "¿Ha dejado de beber líquidos en las últimas 8 horas?",
    span: 12,
  },
  {
    keyName: "HasStoppedEatingLast8Hours",
    type: "boolean",
    label: "¿Ha dejado de comer en las últimas 8 horas?",
    span: 12,
  },
  {
    keyName: "HasAbdominalPain",
    type: "boolean",
    label: "¿Tiene dolor abdominal?",
    span: 12,
  },
];
