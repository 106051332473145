import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
} from "../util/misc";

import { Form, Row, Divider} from "antd";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import FormSkeleton from "../components/FormSkeleton";
import {
  prepInitialValues,
  setSubstanceTypesActive,
  getAllFieldsValues,
} from "../util/MobileSubstanceFormUtil";
import FormBuilderFieldComp from "../components/form-builder/formBuilderFields";

const MobileSubstanceFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [form] = Form.useForm();
  const history = useHistory();
  const formName = "MobileSubstanceForm"

  const [MobileSubstanceFormFields, setMobileSubstanceFormFields] = useState(setSubstanceTypesActive(form.getFieldsValue()));
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data.form) {
        setIsLoading(false);
        return;
      }
      const values = data.form;      
      const prevDoctorValues = data.prev_doctor;
      setPrevDoctor(prevDoctorValues);
      const newValues = await prepInitialValues(values);
      setInitialValues(newValues);
      setIsLoading(false);
      form.resetFields(Object.keys(newValues));
      setMobileSubstanceFormFields(setSubstanceTypesActive(form.getFieldsValue()));   
    })();
  }, []);

  const onValuesChange = () => {
    setMobileSubstanceFormFields(setSubstanceTypesActive(form.getFieldsValue()));    
  }

  const onFinish = async (values: any) => {
    if (!values) return;
    if (isSubmitting) return
    else setIsSubmitting(true)
    values = {
      ...getAllFieldsValues(form.getFieldsValue()),
    }
    await submitForm(
      { values: values, patientId: patientId, formId: formId },
      formName
    );
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  const displaySection = (section: any, index: number) => {
    return section.active && (
      <React.Fragment key={`section-${index}`}>
        <Divider style={{ whiteSpace: "normal" }}>{section.title}</Divider>
        {section.fields.map((formField: any) => (
          <FormBuilderFieldComp key={formField.keyName} formField={formField} form={form} />
        ))}
      </React.Fragment>
    );
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="data-form"
        onFinish={onFinish}
        scrollToFirstError={true}
        onValuesChange={onValuesChange}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Uso de sustancias"
        />
        <Row 
          justify="start"
          align="bottom" gutter={16}
          style={{ width: "83%", margin: "auto"}}
        >
        {Object.keys(MobileSubstanceFormFields).map((key: string, index: number) => (
          displaySection(MobileSubstanceFormFields[key], index)
        ))}
        </Row>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  )
}

export default MobileSubstanceFormPage;