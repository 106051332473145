import {
  Button,
  Card,
  Form,
  FormInstance,
  notification,
  Row,
  Select,
  Space,
  Col,
  Input,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { useState } from "react";
import { OncCancerTypeOptions, chemoSessionOptions } from "../../util/OncologyFormUtil";
import { CancerTypes } from "../../util/OncologyFormUtil";
import CancerMedDetails from "./CancerMedDetails";
import { FullInputNumber } from "../StyledComponents";

const CancerSelectForm: React.FC<{
  field: FormListFieldData;
  form: FormInstance;
  keepDropdownsClosed: boolean;
}> = ({ field: fieldy, form, keepDropdownsClosed }) => {
  const [selectedCancer, setSelectedCancer] = useState<CancerTypes>(
    form.getFieldValue(["cancerMedications", fieldy.name, "cancer"]) || ""
  );
  const [selectedChemoSessionOption, setSelectedChemoSessionOption] = useState<any>(
    form.getFieldValue(['chemoSessionOption', fieldy.name, "option"])
  )

  return (
    <>
      <Form.Item
        {...fieldy}
        name={[fieldy.name, "cancer"]}
        fieldKey={[fieldy.fieldKey, "cancer"]}
      >
        <Select
          placeholder="Seleccione el tipo de cáncer"
          options={OncCancerTypeOptions}
          onChange={(value: CancerTypes) => setSelectedCancer(value)}
        />
      </Form.Item>
      <Row justify="start" gutter={8}>
        <Col span={12}>
          <Form.Item
            name={[fieldy.name, "phase"]}
            fieldKey={[fieldy.fieldKey, "phase"]}
            label="Fase del tratamiento"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[fieldy.name, "numOfChemo"]}
            fieldKey={[fieldy.fieldKey, "numOfChemo"]}
            label="Número de Quimioterapia "
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[fieldy.name, "chemoSessionDetails"]}
            fieldKey={[fieldy.fieldKey, "chemoSessionDetails"]}
            label="¿Esta sesión de quimioterapia fue?:"
          >
            <Select 
              placeholder=""
              options={chemoSessionOptions}
              onChange={(value: any) => setSelectedChemoSessionOption(value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[fieldy.name, "chemoSessionOther"]}
            fieldKey={[fieldy.fieldKey, "chemoSessionOther"]}
            label="Especifique:"
          >
            <Input 
              disabled={selectedChemoSessionOption !== "delayOther"}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.List name={[fieldy.name, "medication"]}>
        {(medications, { add, remove }) => {
          return (
            <>
              <Space
                direction="vertical"
                size="large"
                style={{ width: "100%" }}
              >
                {medications.map((medication, idx) => (
                  <Card>
                    <Form.Item
                      {...medication}
                      name={[medication.name, "med"]}
                      fieldKey={[medication.fieldKey, "med"]}
                    >
                      <CancerMedDetails
                        selectedCancer={selectedCancer}
                        field={medication}
                        idx={idx}
                        fieldIdx={fieldy.name}
                        form={form}
                        keepDropdownsClosed={keepDropdownsClosed}
                      />
                    </Form.Item>
                    <Row justify="end">
                      <Button
                        danger
                        type="dashed"
                        onClick={() => {
                          notification.success({
                            message: "Medicamento eliminado con éxito",
                            duration: 3,
                          });
                          remove(medication.name);
                        }}
                      >
                        Quitar la medicación
                      </Button>
                    </Row>
                  </Card>
                ))}
                <Row justify="start">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    disabled={selectedCancer === ""}
                  >
                    Agregar medicación
                  </Button>
                </Row>
              </Space>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
export default CancerSelectForm;
