import { OptionType } from "../components/OptionsField";
import moment from "moment";

export type HospitalizationArea = "Emergency" | "Oncology" | "Pediatric floor";

export const HospitalizationAreaOptions: OptionType<HospitalizationArea>[] = [
  { label: "Emergencia", value: "Emergency" },
  { label: "Oncología", value: "Oncology" },
  { label: "Piso pediátrico", value: "Pediatric floor" },
];

const scaleColors = [
  "#0090d4",
  "#0071aa",
  "#49557b",
  "#008360",
  "#00b000",
  "#bddc00",
  "#ffb700",
  "#ff8200",
  "#f84d00",
  "#e70000",
  "#af1b1c",
];

export const getMarks = (): Record<number, any> => {
  const marks: Record<number, any> = {};
  scaleColors.forEach((color, idx) => {
    marks[idx] = {
      style: {
        color: color,
      },
      label: <strong>{idx}</strong>,
    };
  });
  return marks;
};

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
    date_vitals_taken: values.date_vitals_taken
      ? moment(values.date_vitals_taken).utc()
      : null,
    time_vitals_taken: values.time_vitals_taken
      ? moment(values.time_vitals_taken, "HH:mm:ss")
      : null,
  };
  return newValues;
};