import { Form, Table } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { useEffect, useMemo, useState } from "react";
import {
  antiMicrobialResponseOptions,
  getMicroOrgTranslations,
  MicroOrgInfo,
  negResponseKeys,
  posResponseKeys,
} from "../../util/InfFormUtil";
import { OptionsField } from "../OptionsField";

const MicroOrgSensitivities: React.FC<{
  field: FormListFieldData;
  microOrgInfo: MicroOrgInfo;
}> = ({ field, microOrgInfo }) => {
  const translations = useMemo(() => {
    return getMicroOrgTranslations();
  }, []);
  const [microOrgI, setMicroOrgI] = useState(microOrgInfo);

  useEffect(() => {
    let editedMicroOrgI = microOrgInfo
    // edge case check for unexpected data format
    if (editedMicroOrgI?.microOrgName && typeof editedMicroOrgI.microOrgName !== "string") {
      editedMicroOrgI.microOrgName = editedMicroOrgI.microOrgName['cropReportName'][1]
    }
    setMicroOrgI(editedMicroOrgI);
  }, [microOrgI, microOrgInfo]);

  if (!microOrgI) {
    return null;
  }
  const keys =
    microOrgI.microOrgGram === "positive"
      ? posResponseKeys
      : microOrgI.microOrgGram === "negative"
      ? negResponseKeys
      : "none";
  if (keys === "none") {
    return null;
  }

  const sensitivityColumns = keys.map((key) => ({
    title: key,
    width: "40%",
    render: (microOrg: MicroOrgInfo) => (
      <Form.Item name={`${`${microOrg.microOrgName}-${key}`}`}>
        <OptionsField
          formOptions={[
            {
              label: key,
              formName: [field.name, key],
              options: antiMicrobialResponseOptions,
            },
          ]}
          presentation="select"
          required={true}
          initialValue={"not performed"}
        />
      </Form.Item>
    ),
  }));

  const columns = [
    {
      dataIndex: "microOrgName",
      render: (microOrgName: any) => {
        const label =
          microOrgName in translations
            ? translations[microOrgName]
            : microOrgName;
        return <p>{label}</p>;
      },
    },
    ...sensitivityColumns,
  ];

  return (
    <Table
      dataSource={[microOrgI]}
      columns={columns}
      scroll={{ x: "max-content" }}
      pagination={false}
    />
  );
};
export default MicroOrgSensitivities;
