import { formBuilderField } from "./formBuilder";
import moment from "moment";
import { formatMultiselect } from "./misc";

export const prepInitialValues = async (values: any) => {
  const newValues = {
    ...values,
    motherIllnesses: formatMultiselect(values.motherIllnesses),
    fatherIllnesses: formatMultiselect(values.fatherIllnesses),
    siblingIllnesses: formatMultiselect(values.siblingIllnesses),
    maternalGrandmaIllnesses: formatMultiselect(values.maternalGrandmaIllnesses),
    maternalGrandpaIllnesses: formatMultiselect(values.maternalGrandpaIllnesses),
    paternalGrandmaIllnesses: formatMultiselect(values.paternalGrandmaIllnesses),
    paternalGrandpaIllnesses: formatMultiselect(values.paternalGrandpaIllnesses),
    motherAddiction: formatMultiselect(values.motherAddiction),
    fatherAddiction: formatMultiselect(values.fatherAddiction),
    siblingAddiction: formatMultiselect(values.siblingAddiction),
    houseMaterials: formatMultiselect(values.houseMaterials),
    houseServices: formatMultiselect(values.houseServices),
    pets: formatMultiselect(values.pets),
    vaccineBCG: formatMultiselect(values.vaccineBCG),
    vaccineHepatitisB: formatMultiselect(values.vaccineHepatitisB),
    vaccineHexavalent: formatMultiselect(values.vaccineHexavalent),
    vaccineDPT: formatMultiselect(values.vaccineDPT),
    vaccineRotavirus: formatMultiselect(values.vaccineRotavirus),
    vaccinePneumococcalConjugate: formatMultiselect(values.vaccinePneumococcalConjugate),
    vaccineVPH: formatMultiselect(values.vaccineVPH),
    vaccineChickenPox: formatMultiselect(values.vaccineChickenPox),
    vaccineMeningococcus: formatMultiselect(values.vaccineMeningococcus),
    vaccineFlu: formatMultiselect(values.vaccineFlu),
    vaccineSARS: formatMultiselect(values.vaccineSARS),
    vaccinePentavalent: formatMultiselect(values.vaccinePentavalent),
    vaccineHepatitisA: formatMultiselect(values.vaccineHepatitisA),
    transfusionFeatures: formatMultiselect(values.transfusionFeatures),
    
    dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).utc() : null,
    lastTransfusion: values.lastTransfusion ? moment(values.lastTransfusion).utc() : null,
    motherDateOfDeath: values.motherDateOfDeath ? moment(values.motherDateOfDeath).utc() : null,
    fatherDateOfDeath: values.fatherDateOfDeath ? moment(values.fatherDateOfDeath).utc() : null,
    siblingDateOfDeath: values.siblingDateOfDeath ? moment(values.siblingDateOfDeath).utc() : null,
    maternalGrandmaDateOfDeath: values.maternalGrandmaDateOfDeath ? moment(values.maternalGrandmaDateOfDeath).utc() : null,
    maternalGrandpaDateOfDeath: values.maternalGrandpaDateOfDeath ? moment(values.maternalGrandpaDateOfDeath).utc() : null,
    paternalGrandmaDateOfDeath: values.paternalGrandmaDateOfDeath ? moment(values.paternalGrandmaDateOfDeath).utc() : null,
    paternalGrandpaDateOfDeath: values.paternalGrandpaDateOfDeath ? moment(values.paternalGrandpaDateOfDeath).utc() : null,
  };
  return newValues;
};

const PatientInfoFields: formBuilderField[] = [
  {
    keyName: "personalInfo",
    type: "divider",
    label: "Informacion personal"
  },
  {
    keyName: "name",
    type: "string",
    label: "Nombre",
    span: 12,
  },
  {
    keyName: "himfgId",
    type: "string",
    label: "Folio HIMFG",
    span: 12,
  },
  {
    keyName: "age",
    type: "number",
    label: "Edad (años)",
    span: 12,
  },
  {
    keyName: "sex",
    type: "select",
    label: "Sexo",
    span: 12,
    options: [
      { label: "Hombre", value: "male" },
      { label: "Mujer", value: "female" },
    ]
  },
  {
    keyName: 'dateOfBirth',
    type: "date",
    label: "Fecha de nacimiento",
    span: 12,
  },
  {
    keyName: "placeOfBirth",
    type: "string",
    label: "Lugar de nacimiento",
    span: 12,
  },
  {
    keyName: "religion",
    type: "select",
    label: "Religión",
    span: 12,
    options: [
      { label: "Católica", value: "catholic", },
      { label: "Cristina", value: "christian", },
      { label: "Judaísmo", value: "jewish", },
      { label: "Agnosticismo", value: "agnostic", },
      { label: "No creyente", value: "non-believer", },
    ]
  },
  {
    keyName: "education",
    type: "select",
    label: "Escolaridad",
    span: 12,
    options: [
      { label: "Primaria", value: "primary"},
      { label: "Secundaria (incompleta)", value: "secondaryIncomplete"},
      { label: "Secundaria (completa)", value: "secondaryComplete"},
      { label: "Preparatoria (incompleta)", value: "highSchoolIncomplete"},
      { label: "Preparatoria (completa)", value: "highSchoolComplete"},
    ]
  },
  {
    keyName: "placeOfResidence",
    type: "select",
    label: "Lugar de residencia",
    options: [
      { label: "CDMX", value: "cdmx"},
      { label: "EDO MEX", value: "edomex"},
      { label: "Aguascalientes", value: "aguascalientes"},
      { label: "Baja California", value: "bajaCalifornia"},
      { label: "Baja California Sur", value: "bajaCaliforniaSur"},
      { label: "Campeche", value: "campeche"},
      { label: "Coahuila", value: "coahuila"},
      { label: "Colima", value: "colima"},
      { label: "Chiapas", value: "chiapas"},
      { label: "Chihuahua", value: "chihuahua"},
      { label: "Durango", value: "durango"},
      { label: "Guanajuato", value: "Guanajuato"},
      { label: "Guerrero", value: "guerrero"},
      { label: "Hidalgo", value: "hidalgo"},
      { label: "Jalisco", value: "jalisco"},
      { label: "México", value: "mexico"},
      { label: "Michoacán", value: "michoacan"},
      { label: "Morelos", value: "morelos"},
      { label: "Nayarit", value: "nayarit"},
      { label: "Nuevo León", value: "nuevoLeon"},
      { label: "Oaxaca", value: "oaxaca"},
      { label: "Puebla", value: "puebla"},
      { label: "Querétaro", value: "queretaro"},
      { label: "Quintana Roo", value: "quintanaRoo"},
      { label: "San Luis Potosí", value: "sanLuisPotosi"},
      { label: "Sinaloa", value: "sinaloa"},
      { label: "Sonora", value: "sonora"},
      { label: "Tabasco", value: "tabasco"},
      { label: "Tamaulipas", value: "tamaulipas"},
      { label: "Tlaxcala", value: "tlaxcala"},
      { label: "Veracruz", value: "veracruz"},
      { label: "Yucatán", value: "yucatan"},
      { label: "Zacatecas", value: "zacatecas"},
    ]
  },
  {
    keyName: "telephone",
    type: "string",
    label: "Teléfono",
    span: 12,
  },
  {
    keyName: "email",
    type: "string",
    label: "Correo electrónico",
    span: 12,
  },
  {
    keyName: "informant",
    type: "select",
    label: "Informante",
    options: [
      { label: "Madre", value: "mother"},
      { label: "Padre", value: "father"},
      { label: "Hermano (a)", value: "sibling"},
      { label: "Abuelo (a)", value: "grandparent"},
      { label: "Tío (a)", value: "auntOrUncle"},
    ]
  },
  {
    keyName: "motherFatherNames",
    type: "string",
    label: "Nombre de la madre y el padre",
  },
];

const FamilyFields = (familyMember: string) => {
  const spanishLabel: {[key: string]: string} = {
    mother: "Madre",
    father: "Padre",
    sibling: "Hermanos",
    maternalGrandma: "Abuela Materna",
    maternalGrandpa: "Abuelo Materno",
    paternalGrandma: "Abuela Paterna",
    paternalGrandpa: "Abuelo Paterno",
  }

 const allFamilyFields: formBuilderField[] = [
    {
      keyName: `${familyMember}History`,
      type: "divider",
      label: `Antecedentes Heredofamiliares - ${spanishLabel[familyMember]}`
    },
    {
      keyName: `${familyMember}Age`,
      type: "number",
      label: "Edad (años)",
      span: 12,
    },
    {
      keyName: `${familyMember}Education`,
      type: "select",
      label: "Escolaridad",
      span: 12,
      options: [
        { label: "Primaria", value: "primary"},
        { label: "Secundaria (incompleta)", value: "secondaryIncomplete"},
        { label: "Secundaria (completa)", value: "secondaryComplete"},
        { label: "Preparatoria (incompleta)", value: "highSchoolIncomplete"},
        { label: "Preparatoria (completa)", value: "highSchoolComplete"},
        { label: "Licenciatura", value: "bachelorsDegree"},
        { label: "Posgrado", value: "postgraduate"},
        { label: "Ninguno", value: "none"},
      ]
    },
    {
      keyName: `${familyMember}Occupation`,
      type: "select",
      label: "Ocupación",
      options: [
        { label: "Profesionista", value: "professional", },
        { label: "Técnico", value: "technical", },
        { label: "Hogar", value: "home", },
        { label: "Oficios (carpintero, plomero, electricista, albañil, costurera)", value: "trades", },
        { label: "Servicios (taxista, limpieza, vigilancia)", value: "services", },
        { label: "Empleado fábrica o comercio", value: "factoryOrTradeEmployee", },
        { label: "Empleado Oficina", value: "officeEmployee", },
        { label: "Comerciante", value: "merchant", },
        { label: "Jubilado", value: "retired", },
        { label: "Desempleado", value: "unemployed", },
      ]
    },
    {
      keyName: `${familyMember}Illnesses`,
      type: "select",
      label: "Enfermedades",
      selectMode: "multiple",
      span: 12,
      options: [
        { label: "Diabetes", value: "diabetes"},
        { label: "Hipertensión arterial", value: "arterialHyperension"},
        { label: "Dislipidemia", value: "dyslipidemia"},
        { label: "Cáncer", value: "cancer"},
        { label: "Afectación a sistema nervioso", value: "nervousSystemInvolvement"},
        { label: "Enfermedad del corazón", value: "heartDisease"},
        { label: "Enfermedad del riñon", value: "kidneyDisease"},
        { label: "Enfermedad hepática y pulmonar", value: "liverAndLungDisease"},
        { label: "Gastropatias", value: "gastropathies"},
        { label: "Trastornos oculares", value: "eyeDisorders"},
      ]
    },
    {
      keyName: `${familyMember}BloodType`,
      type: "select",
      label: "Grupo y Rh",
      span: 12,
      options: [
        { label: "A+", value: "aPos"},
        { label: "A-", value: "aNeg"},
        { label: "B+", value: "bPos"},
        { label: "B-", value: "bNeg"},
        { label: "AB+", value: "abPos"},
        { label: "AB-", value: "abNeg"},
        { label: "O+", value: "oPos"},
        { label: "O-", value: "oNeg"},
      ]
    },
  ]

  const immediateFamilyFields: formBuilderField[] = [
    {
      keyName: `${familyMember}Addiction`,
      type: "select",
      label: "Toxicomanías",
      selectMode: "multiple",
      options: [
        { label: "Alcoholismo", value: "alcoholism"},
        { label: "Tabaquismo", value: "smoking"},
        { label: "Drogas", value: "drugs"},
        { label: "Intravenosas", value: "intravenous"},
        { label: "Inhaladas", value: "inhaled"},
        { label: "Otras", value: "others"},
      ]
    },
    {
      keyName: `${familyMember}AddictionOther`,
      type: "string",
      label: "Toxicomanías Otro",
    },
  ]

  const parentFields: formBuilderField[] = [
    {
      keyName: `${familyMember}MaritalStatus`,
      type: "select",
      label: "Estado Civil",
      options: [
        { label: "Soltera", value: "single"},
        { label: "Unión libre", value: "freeUnion"},
        { label: "Casada", value: "married"},
        { label: "Divorciada", value: "divorcee"},
        { label: "Viuda", value: "widow"},
      ]
    },
  ]

  const siblingFields: formBuilderField[] = [
    {
      keyName: 'numSiblings',
      type: "number",
      label: "Numero de hermano",
      span: 12,
    },
    {
      keyName: `${familyMember}OccupationPlace`,
      type: "string",
      label: "Lugar que ocupa",
      span: 12,
    }
  ]

  const deathFields: formBuilderField[] =[
    {
      keyName: `${familyMember}IsDeceased`,
      type: "boolean",
      label: "Fallecido",
      span: 12,
    },
    {
      keyName: `${familyMember}DateOfDeath`,
      type: "date",
      label: "Fecha de muerte",
      span: 12,
    },
  ]

  return [
    ...allFamilyFields,
    ...(["mother", "father", "sibling"].includes(familyMember) ? immediateFamilyFields : []),
    ...(["mother", "father"].includes(familyMember) ? parentFields : []),
    ...(["sibling"].includes(familyMember) ? siblingFields : []),
    ...deathFields,
  ]
}

const PersonalHistoryFields: formBuilderField[] = [
  {
    keyName: "personalHistory",
    type: "divider",
    label: "Antecedentes Personales no Patologicós"
  },
  {
    keyName: "houseMaterials",
    type: "select",
    label: "Casa Materiales",
    selectMode: "multiple",
    options: [
      { label: "Asbesto", value: "asbestos", },
      { label: "Ladrillos", value: "bricks", },
      { label: "Lámina", value: "leaf", },
      { label: "Cantera", value: "quarry", },
      { label: "Madera", value: "wood", },
      { label: "Barro", value: "mud", },
      { label: "Tableros de Yeso", value: "gypsumBoard", },
    ]
  },
  {
    keyName: "houseServices",
    type: "select",
    label: "Casa Servicios",
    selectMode: "multiple",
    options: [
      { label: "Agua potable", value: "drinkingWater", },
      { label: "Luz", value: "light", },
      { label: "Gas", value: "gas", },
      { label: "Internet", value: "internet", },
      { label: "Drenaje", value: "sewerSystem", },
      { label: "Recolección de basura", value: "garbageCollection", },
      { label: "Alumbrado público", value: "streetLighting", },
    ]
  },
  {
    keyName: "numInhabitants",
    type: "select",
    label: "Número de personas que habitan",
    span: 12,
    options: [
      { label: "2-4", value: "2-4", },
      { label: "5-6", value: "5-6", },
      { label: "Más de 6", value: "6+", },
    ]
  },
  {
    keyName: "pets",
    type: "select",
    label: "Mascotas",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Perro", value: "dog", },
      { label: "Gato", value: "cat", },
      { label: "Peces", value: "Fish", },
      { label: "Otro", value: "Otros", },
    ]
  },
  {
    keyName: "petsOther",
    type: "string",
    label: "Mascotas Otros",
  },
  {
    keyName: "breastfeeding",
    type: "boolean",
    label: "Lactancia materna",
    span: 12,
  },
  {
    keyName: "breastfeedingTime",
    type: "number",
    label: "Tiempo de lactancia materna (meses)",
    span: 12,
  },
  {
    keyName: "formula",
    type: "boolean",
    label: "Fórmula",
    span: 12,
  },
  {
    keyName: "weaning",
    type: "number",
    label: "Ablactación (meses)",
    span: 12,
  },
  {
    keyName: "incorporationIntoFamilyDiet",
    type: "string",
    label: "Incorporación a dieta familiar",
  },
  {
    keyName: "dentalHygienePerDay",
    type: "number",
    label: "Aseo dental, número de veces al día",
    span: 12,
  },
  {
    keyName: "bathroomPerDay",
    type: "number",
    label: "Baño, número de veces al día",
    span: 12,
  },
  {
    keyName: "changeClothesPerDay",
    type: "number",
    label: "Cambio de ropa, número de veces al día",
    span: 12,
  },
  {
    keyName: "neurodevelopmentHeader",
    type: "divider",
    label: "Neurodesarrollo meses"
  },
  {
    keyName: "gazeFixationMonth",
    type: "number",
    label: "Fijación de la mirada",
    span: 12,
  },
  {
    keyName: "smileMonth",
    type: "number",
    label: "Sonrisa",
    span: 12,
  },
  {
    keyName: "headSupportMonth",
    type: "number",
    label: "Sostén cefálico",
    span: 12,
  },
  {
    keyName: "bearingMonth",
    type: "number",
    label: "Rodamiento",
    span: 12,
  },
  {
    keyName: "crawlingMonth",
    type: "number",
    label: "Gateo",
    span: 12,
  },
  {
    keyName: "sittingUnassistedMonth",
    type: "number",
    label: "Sedestación sin ayuda",
    span: 12,
  },
  {
    keyName: "standingUnassistedMonth",
    type: "number",
    label: "Bipedestación sin ayuda",
    span: 12,
  },
  {
    keyName: "babblingMonth",
    type: "number",
    label: "Balbuceo",
    span: 12,
  },
  {
    keyName: "roughControlMonth",
    type: "number",
    label: "Control de monstruos",
    span: 12,
  },
  {
    keyName: "fineControlMonth",
    type: "number",
    label: "Control fino",
    span: 12,
  },
  {
    keyName: "walkingAssistedMonth",
    type: "number",
    label: "Marcha con ayuda",
    span: 12,
  },
  {
    keyName: "walkingUnassistedMonth",
    type: "number",
    label: "Marcha sin ayuda",
    span: 12,
  },
  {
    keyName: "toiletTrainingMonth",
    type: "number",
    label: "Control de esfínteres",
    span: 12,
  },
  {
    keyName: "monosyllablesMonth",
    type: "number",
    label: "Monosílabos",
    span: 12,
  },
]

const ImmunizationFields: formBuilderField[] = [
  {
    keyName: "immunizationHeader",
    type: "divider",
    label: "Inmunizaciones, marcar si cuenta con ella"
  },
  {
    keyName: "vaccineBCG",
    type: "select",
    label: "BCG",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Única al nacer", value: "uniqueAtBirth", },
    ]
  },
  {
    keyName: "vaccineHepatitisA",
    type: "select",
    label: "Hepatitis A",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Dosis inicial", value: "initialDose", },
      { label: "Al mes y 6 meses", value: "6months" },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineHepatitisB",
    type: "select",
    label: "Hepatitis B",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Única al nacer", value: "uniqueAtBirth", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineHexavalent",
    type: "select",
    label: "Hexavalente",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "2 meses", value: "2months", },
      { label: "4 meses", value: "4months", },
      { label: "6 meses", value: "6months", },
      { label: "18 meses", value: "18months", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineDPT",
    type: "select",
    label: "DPT",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "4 años (refuerzo)", value: "4years", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineRotavirus",
    type: "select",
    label: "Rotavirus",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "2 meses", value: "2months", },
      { label: "4 meses", value: "4months", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccinePneumococcalConjugate",
    type: "select",
    label: "Neumocócica Conjugada",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "2 meses", value: "2months", },
      { label: "4 meses", value: "4months", },
      { label: "1 año (refuerzo)", value: "1year", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineFlu",
    type: "select",
    label: "Influenza",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Refuerzo anual", value: "annual", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineVPH",
    type: "select",
    label: "VPH",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Mujeres, 5to grado o 11 años", value: "girls5thGrade", },
      { label: "6 meses post", value: "6monthsPost", },
      { label: "60 meses después primera dosis", value: "60monthsPost", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineChickenPox",
    type: "select",
    label: "Varicela",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "12-15 meses", value: "12-15months", },
      { label: "4-6 años", value: "4-6years", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineMeningococcus",
    type: "select",
    label: "Meningococo",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Mayores de 11 años", value: "11years", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineSeasonalFlu",
    type: "select",
    label: "Influenza estacional",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "Anual", value: "annual", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineSARS",
    type: "select",
    label: "SARS CoV-2",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "1ra dosis", value: "dose1", },
      { label: "2da dosis", value: "dose2", },
      { label: "Refuerzo", value: "booster", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccinePentavalent",
    type: "select",
    label: "Pentavalente",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "2 meses", value: "2months", },
      { label: "4 meses", value: "4months", },
      { label: "6 meses", value: "6months", },
      { label: "18 meses", value: "18months", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
  {
    keyName: "vaccineSABIN",
    type: "select",
    label: "SABIN",
    selectMode: "multiple",
    span: 12,
    options: [
      { label: "6 meses", value: "6months", },
      { label: "59 meses", value: "59months", },
      { label: "Esquema acelerado", value: "alternativeTime"},
    ]
  },
]

const PersonalPathologicalFields: formBuilderField[] = [
  {
    keyName: "personalPathologicalHeader",
    type: "divider",
    label: "Antecedentes Personales Patológicos"
  },
  {
    keyName: "transfusional",
    type: "boolean",
    label: "Transfusionales",
    span: 12,
  },
  {
    keyName: "lastTransfusion",
    type: "date",
    label: "Última transfusión",
    span: 12,
  },
  {
    keyName: "transfusionFeatures",
    type: "select",
    label: "Características de la transfusión",
    selectMode: "multiple",
    options: [
      { label: "Concentrados eritrocitarios", value: "erythrocyteConcentrates" },
      { label: "Plasma fresco", value: "freshPlasma" },
      { label: "Unitarios plaquetarios", value: "plateletUnit" },
      { label: "Aferesis plaquetaria", value: "plateletApheresis" },
      { label: "Crioprecipitados", value: "cryoprecipitates" },
    ]
  },
  { 
    keyName: "surgical",
    type: "string",
    label: "Quirúrgicos: (en caso de ser varios, nombre cirugía y fecha separados por una coma)",
  },
  { 
    keyName: "traumatic",
    type: "string",
    label: "Traumáticos: (en caso de ser varios, nombre cirugía y fecha separados por una coma)",
  },
  { 
    keyName: "congenital",
    type: "string",
    label: "Congénitos",
  },
  { 
    keyName: "previousHospitalizations",
    type: "string",
    label: "Hospitalizaciones previas: (en caso de ser varios, nombre cirugía y fecha separados por una coma)",
  },
  { 
    keyName: "infectiousContagious",
    type: "string",
    label: "Infectocontagiosas: (en caso de ser varios, nombre cirugía y fecha separados por una coma)",
  },
  { 
    keyName: "rashes",
    type: "string",
    label: "Exantemáticas: (en caso de ser varios, nombre cirugía y fecha separados por una coma)",
  },
  { 
    keyName: "allergies",
    type: "string",
    label: "Alergias",
  },
  {
    keyName: "perinatalHeader",
    type: "divider",
    label: "Antecedentes Perinatales",
  },
  {
    keyName: "numOfPregnancies",
    type: "string",
    label: "Gesta",
  },
  {
    keyName: "perinatalResolution",
    type: "select",
    label: "Resolución",
    span: 12,
    options: [
      {label: "Cesárea", value: "cesarean"},
      {label: "Parto", value: "naturalBirth"},
      {label: "Abortos", value: "abortion"}
    ]
  },
  {
    keyName: "perinatalResolutionReason",
    type: "string",
    label: "Motivo",
    span: 12,
  },
  {
    keyName: "prenatalCareHeader",
    type: "divider",
    label: "Control Prenatal",
  },
  {
    keyName: "prenatalVisits",
    type: "boolean",
    label: "Visitas",
    furtherOptions: {
      optionsKey: "prenatalVisitsNum",
      type: "number",
    },
  },
  {
    keyName: "prenatalUSG",
    type: "boolean",
    label: "USG",
    furtherOptions: {
      optionsKey: "prenatalUSGNum",
      type: "number",
    },
  },
  {
    keyName: "prenatalVDRLHIV",
    type: "boolean",
    label: "VDRL/VIH",
    furtherOptions: {
      optionsKey: "prenatalVDRLHIVNum",
      type: "number",
    },
  },
  {
    keyName: "prenatalInfections",
    type: "string",
    label: "Infecciones",
  },
  {
    keyName: "prenatalComplications",
    type: "string",
    label: "Complicaciones",
  },
  {
    keyName: "neonatalResuscitation",
    type: "boolean",
    label: "Reanimación neonatal",
    span: 12,
  },
  {
    keyName: "perinatalAPGAR",
    type: "string",
    label: "APGAR",
    span: 12,
  },
  {
    keyName: "perinatalSILVERMAN",
    type: "string",
    label: "SILVERMAN",
    span: 12,
  },
  {
    keyName: "weeksOfGestation",
    type: "number",
    label: "Semanas de gestación",
    span: 12,
  },
  {
    keyName: "obstetricianHeader",
    type: "divider",
    label: "Ginecobstetricos",
  },
  {
    keyName: "obstTannerStadium",
    type: "string",
    label: "Estadio de Tanner",
    span: 12,
  },
  {
    keyName: "thelarche",
    type: "string",
    label: "Telarca (edad, años)",
    span: 12,
  },
  {
    keyName: "pubarche",
    type: "string",
    label: "Pubarca (edad, años)",
    span: 12,
  },
  {
    keyName: "menarche",
    type: "string",
    label: "Menarca (edad, años)",
    span: 12,
  },
  {
    keyName: "obstRhythm",
    type: "string",
    label: "Ritmo",
    span: 12,
  },
  {
    keyName: "obstFUM",
    type: "string",
    label: "FUM",
    span: 12,
  },
  {
    keyName: "obstIVSA",
    type: "string",
    label: "IVSA",
    span: 12,
  },
]

const SymptomsFields: formBuilderField[] = [
  {
    keyName: "symptomsHeader",
    type: "divider",
    label: "Interrogatorio por Aparatos y Sistemas"
  },
  {
    keyName: "weightLoss",
    type: "boolean",
    label: "Pérdida de peso",
    span: 12,
  },
  {
    keyName: "asthenia",
    type: "boolean",
    label: "Astenia",
    span: 12,
  },
  {
    keyName: "adynamia",
    type: "boolean",
    label: "Adinamia",
    span: 12,
  },
  {
    keyName: "anorexia",
    type: "boolean",
    label: "Anorexia",
    span: 12,
  },
  {
    keyName: "insomnia",
    type: "boolean",
    label: "Insomnio",
    span: 12,
  },
  {
    keyName: "seizures",
    type: "boolean",
    label: "Convulsiones",
    span: 12,
  },
  {
    keyName: "paresthesias",
    type: "boolean",
    label: "Parestesias",
    span: 12,
  },
  {
    keyName: "headache",
    type: "boolean",
    label: "Cefalea",
    span: 12,
  },
  {
    keyName: "precordialPain",
    type: "boolean",
    label: "Taquicardia",
    span: 12,
  },
  {
    keyName: "dyspnea",
    type: "boolean",
    label: "Disnea",
    span: 12,
  },
  {
    keyName: "respiratoryDistressData",
    type: "boolean",
    label: "Datos de dificultad respiratoria",
    span: 12,
  },
  {
    keyName: "cough",
    type: "boolean",
    label: "Tos",
    span: 12,
  },
  {
    keyName: "expectorations",
    type: "boolean",
    label: "Expectoraciones",
    span: 12,
  },
  {
    keyName: "diarrhea",
    type: "boolean",
    label: "Diarrea",
    span: 12,
  },
  {
    keyName: "vomiting",
    type: "boolean",
    label: "Vómito",
    span: 12,
  },
  {
    keyName: "nausea",
    type: "boolean",
    label: "Náusea",
    span: 12,
  },
  {
    keyName: "painInEpigastrium",
    type: "boolean",
    label: "Dolor en epigastrio",
    span: 12,
  },
  {
    keyName: "polyuria",
    type: "boolean",
    label: "Poliuria",
    span: 12,
  },
  {
    keyName: "nocturia",
    type: "boolean",
    label: "Nicturia",
    span: 12,
  },
  {
    keyName: "dysuria",
    type: "boolean",
    label: "Disuria",
    span: 12,
  },
  {
    keyName: "bladderTenesmus",
    type: "boolean",
    label: "Tenesmo vesical",
    span: 12,
  },
  {
    keyName: "polydipsia",
    type: "boolean",
    label: "Polidipsia",
    span: 12,
  },
  {
    keyName: "polyphagia",
    type: "boolean",
    label: "Polifagia",
    span: 12,
  },
  {
    keyName: "coldIntolerance",
    type: "boolean",
    label: "Intolerancia al frío",
    span: 12,
  },
  {
    keyName: "heatIntolerance",
    type: "boolean",
    label: "Intolerancia al calor",
    span: 12,
  },
  {
    keyName: "paleness",
    type: "boolean",
    label: "Palidez",
    span: 12,
  },
  {
    keyName: "mucosal",
    type: "boolean",
    label: "Mucosas",
    span: 12,
  },
  {
    keyName: "dryness",
    type: "boolean",
    label: "Resequedad",
    span: 12,
  },
  {
    keyName: "athralgia",
    type: "boolean",
    label: "Artralgia",
    span: 12,
  },
  {
    keyName: "myalgias",
    type: "boolean",
    label: "Mialgias",
    span: 12,
  },
  {
    keyName: "petechiae",
    type: "boolean",
    label: "Petequias",
    span: 12,
  },
  {
    keyName: "ecchymosis",
    type: "boolean",
    label: "Equimosis",
    span: 12,
  },
  {
    keyName: "bleeding",
    type: "boolean",
    label: "Hemorragias",
    span: 12,
  },
]

const VitalsAndDiagnosisFields: formBuilderField[] = [
  {
    keyName: "vitalsHeader",
    type: "divider",
    label: "Exploración Física"
  },
  {
    keyName: "vitalsTA",
    type: "string",
    label: "TA",
    span: 12,
  },
  {
    keyName: "vitalsFC",
    type: "string",
    label: "FC",
    span: 12,
  },
  {
    keyName: "vitalsFR",
    type: "string",
    label: "FR",
    span: 12,
  },
  {
    keyName: "vitalsO2",
    type: "string",
    label: "Sat O2",
    span: 12,
  },
  {
    keyName: "vitalsTemp",
    type: "string",
    label: "T°",
    span: 12,
  },
  {
    keyName: "vitalsWeight",
    type: "string",
    label: "Peso",
    span: 12,
  },
  {
    keyName: "vitalsHeight",
    type: "string",
    label: "Talla",
    span: 12,
  },
  {
    keyName: "vitalsIMC",
    type: "string",
    label: "IMC",
    span: 12,
  },
  {
    keyName: "diagnosis",
    type: "string",
    label: "Diagnóstico Histopatológico Final"
  },
]

export const HistoryFields: formBuilderField[] = [
  ...PatientInfoFields,
  ...FamilyFields("mother"),
  ...FamilyFields("father"),
  ...FamilyFields("sibling"),
  ...FamilyFields("maternalGrandma"),
  ...FamilyFields("maternalGrandpa"),
  ...FamilyFields("paternalGrandma"),
  ...FamilyFields("paternalGrandpa"),
  ...PersonalHistoryFields,
  ...ImmunizationFields,
  ...PersonalPathologicalFields,
  ...SymptomsFields,
  ...VitalsAndDiagnosisFields,
]

export const getMarks = (scale:number): { [key in number]: any } => {
  const marks: { [key in number]: any } = {};
  for (let i = 0; i < scale; i++) {
    marks[i] = {
      style: {
        color: "#1890ff",
      },
      label: i+1,
    };
  };
  return marks;
};
