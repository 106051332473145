import { Form, Row, Col } from "antd";
import { usePatientById } from "../hooks/dataHooks";
import { FullInputNumber } from "../components/StyledComponents";
import CenterSpinner from "./CenterSpinner";
import moment from "moment";
import { calcAge } from "../util/InfFormUtil";

interface HeightWeightProps {
  patientId: string;
}

const HeightWeight: React.FC<HeightWeightProps> = ({
  patientId,
}) => {
  const { isLoading, patient } = usePatientById(patientId);
  return !isLoading && patient ? (
    <>
      <Row justify="start" gutter={16}>
        {calcAge(moment(patient.dob)) > 2 
        ? (
          <>
            <Col span={8}>
              <Form.Item
                key="heightM"
                name="heightM"
                label="Talla (m)"
                rules={[{required: true}]}
              >
                <FullInputNumber />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                key="heightCM"
                name="heightCM"
                label="(cm)"
                rules={[{required: true}]}
              >
                <FullInputNumber />
              </Form.Item>
            </Col>
          </>
        ) : ( //children age 2 and younger are measured in only cm
          <Col span={12}>
            <Form.Item
              key="heightCM"
              name="heightCM"
              label="Longitud (cm)"
              rules={[{required: true}]}
            >
              <FullInputNumber />
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            key="weightKG"
            name="weightKG"
            label="Peso (kg)"
            rules={[{required: true}]}
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            key="weightG"
            name="weightG"
            label="(g)"
            rules={[{required: true}]}
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
      </Row>
    </>
  ): !patient ? null : (
    <CenterSpinner />
  );
};
export default HeightWeight;