import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Card,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MicroOrgInfo, siteOfInfFields } from "../util/InfFormUtil";
import GeneralInfo from "../components/GeneralInfo";
import CenterSpinner from "../components/CenterSpinner";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
} from "../util/misc";
import FormSkeleton from "../components/FormSkeleton";
import MicroOrgForm from "../components/infectiology-form/MicroOrgForm";
import { FormListFieldData } from "antd/lib/form/FormList";
import Antibiotics from "../components/infectiology-form/Antibiotics";
import { prepInitialValues } from "../util/InfFormUtil";

export type MicroOrgSetters = Record<
  "positive" | "negative" | "none",
  React.Dispatch<React.SetStateAction<MicroOrgInfo[]>>
>;

const InfectiologyFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const history = useHistory();
  const [form] = Form.useForm();
  const formName = "InfForm"

  const [sitesOfInfFields, setSitesOfInfFields] =
    useState<any[]>(siteOfInfFields);

  const removeInfection = (
    field: FormListFieldData,
    remove: (path: number | number[]) => void
  ) => {
    notification.success({
      message: "Microorganismo eliminado con éxito",
      duration: 4,
    });
    remove(field.name);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data["form"]) {
        setIsLoading(false);
        return;
      }
      let values;
      values = { ...data["form"] };
      const prevDoctorValues = data["prev_doctor"];
      if (values.infections) {
        values.infections = await prepInitialValues(values);
      }
      setPrevDoctor(prevDoctorValues);
      setIsLoading(false);
      form.setFieldsValue(values);
    })();
  }, []);

  const onFinish = async (values: any) => {
    if (isSubmitting) return
    else setIsSubmitting(true)

    const preppedValues = {
      infections: values?.infections,
      medications: values?.medications,
    };
    await submitForm({ values: preppedValues, patientId: patientId, formId: formId }, formName);
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        className="data-form"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Formulario de Infectología"
        />
        <Divider orientation="center">
          <h2>Paciente</h2>
        </Divider>
        <Divider orientation="left">Sitio de infección</Divider>

        <Form.List name="infections">
          {(fields, { add, remove }) => {
            return (
              <Space
                direction="vertical"
                size="middle"
                style={{ width: "100%" }}
              >
                {fields.map((field, idx) => (
                  <Card>
                    <Row
                      key={field.key}
                      style={{
                        display: "flex",
                        marginBottom: 20,
                      }}
                      justify="center"
                    >
                      <Col span={22}>
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                          size="small"
                        >
                          <MicroOrgForm
                            field={field}
                            form={form}
                            siteOfInfFields={sitesOfInfFields}
                            setSiteOfInfFields={setSitesOfInfFields}
                            fieldPath={["infections", field.name]}
                            keepDropdownsClosed={false}
                            idx={idx}
                          />{" "}
                          <Row justify="end">
                            <Button
                              danger
                              onClick={() => removeInfection(field, remove)}
                              type="dashed"
                            >
                              Quitar infección
                            </Button>
                          </Row>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                ))}
                <Row justify="center">
                  <Button type="dashed" onClick={() => add()}>
                    Agregar infección
                  </Button>
                </Row>
              </Space>
            );
          }}
        </Form.List>

        <Divider orientation="left">Medicamentos</Divider>
        <Form.List name="medications">
          {(fields, { add, remove }) => {
            return (
              <Space
                direction="vertical"
                size="middle"
                style={{ width: "100%" }}
              >
                {fields.map((field, idx) => (
                  <Card>
                    <Row
                      key={field.key}
                      style={{ display: "flex", marginBottom: 20 }}
                      justify="center"
                    >
                      <Col span={22}>
                        <Space
                          direction="vertical"
                          size="small"
                          style={{ width: "100%" }}
                        >
                          <Antibiotics
                            field={field}
                            form={form}
                            fieldPath={["medicineFamily", field.name]}
                          />
                        </Space>
                      </Col>
                    </Row>
                    <Row justify="end">
                      <Button
                        danger
                        onClick={() => {
                          notification.success({
                            message: "Medicamento eliminado con éxito",
                            duration: 4,
                          });
                          remove(field.name);
                        }}
                      >
                        Quitar medicamento
                      </Button>
                    </Row>
                  </Card>
                ))}
                <Row justify="center" style={{ marginBottom: "20px" }}>
                  <Button type="dashed" onClick={() => add()}>
                    Agregar medicamento
                  </Button>
                </Row>
              </Space>
            );
          }}
        </Form.List>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  );
};

export default InfectiologyFormPage;
