import { Row, Col, Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import "../App.css";
import React, { useState, useEffect } from "react";
import { BackButton } from "../components/StyledComponents";
import CenterSpinner from "../components/CenterSpinner";
import { Link, useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { RegisterPatientParams, PrevDoctor } from "../util/misc";
import GeneralInfo from "../components/GeneralInfo";
import { useMainStore } from "../state/mainStore";

const PatientInfoPage: React.FC = () => {
  const { role } = useMainStore();
  const { patientId } = useParams<RegisterPatientParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const history = useHistory();
  const location = useLocation<Record<string, boolean>>();
  const hasPatient = location.state && location.state.patient;

  const forms = [
    {key: "EmergencyForm", label: "Emergencia"},
    {key: "SignsSymptomsForm", label: "Signos y síntomas"},
    {key: "TrackingForm", label: "Seguimiento"},
    {key: "OutcomeForm", label: "Desenlaces"},
    {key: "SWForm", label: "Trabajo social"},
    {key: "InfForm", label: "Infectología"},
    {key: "OncologyForm", label: "Oncologia"},
    {key: "SEIForm", label: "Indicadores"},
    {key: "HistoryForm", label: "Historia"},
    {key: "MobileSubstanceForm", label: "Uso de sustancias"},
    {key: "MobileSymptomForm", label: "(Aplicación familiar) Síntomas"},
    {key: "MobileSEIForm", label: "(Aplicación familiar) Indicadores"},
    {key: "MobileDepressionForm", label: "(Aplicación familiar) Depresión"},
    {key: "MobileFamilyForm", label: "(Aplicación familiar) Familia"},
    {key: "MobileStressForm", label: "(Aplicación familiar) Estrés"},
  ]

  const genMobileForms = [
    "MobileDepressionForm",
    "MobileFamilyForm",
    "MobileStressForm"
  ]

  type FormColumn = {
    key: string,
    label: string
  }

  const formColumns: ColumnsType<FormColumn> = [
    {
      title: "Form",
      dataIndex: "label",
      width: "34%",
    },
    {
      title: "",
      dataIndex: "addNew",
      width: "33%",
      render: (_, form) => (
        <Button type="primary" style={{width: "100%"}}>
          <Link to={{
            pathname: `/${genMobileForms.includes(form.key) ? "mobile/" : ""}${form.key}/${patientId}/new`,
            state: { patient: hasPatient }
          }}>Crear nuevo formulario &#43;</Link>
        </Button>
      )
    },
    {
      title: "",
      dataIndex: "viewAll",
      width: "33%",
      render: (_, form) => (
        <Button style={{width: "100%"}}>
          <Link to={`/${form.key}/${patientId}`}>Ver todas las formas</Link>
        </Button>
      )
    }
  ]

  return (patientId) ? (
    <div className="form-container">
      <Row
        justify="start"
        align="top"
        style={{ 
          width: "100%", 
          margin: "0 auto",
          justifyContent: "space-between",
        }}
      >
        <BackButton onClick={() => history.push({ pathname: `/patientList`})}>
          &#8592; Regresa
        </BackButton>
        {//admins get to edit patients
        role.includes("administrator") ?
          <Button style={{width: "auto"}}>
            <Link to={`/editPatient/${patientId}`}>{"Editar paciente"}</Link>
          </Button> 
        : null}
      </Row>
      <Row justify="center" align="middle">
        <Col>
          <GeneralInfo
            patientId={patientId || ""}
            prevDoctor={prevDoctor}
            formTitle="Información del Paciente"
            extraInfo={true}
          />
        </Col>
      </Row>
      <Row>
        <Table
          columns={formColumns}
          rowKey={(form) => form.key}
          dataSource={forms}
          style={{margin: "0 auto", width: "100%"}}
        ></Table>
      </Row>
    </div>
  ) : (
    <CenterSpinner />
  );
};

export default PatientInfoPage;
