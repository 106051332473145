// API request builder. 
// This should always grab the okta storage token and include it in the request header

import axios from "axios";

axios.defaults.withCredentials = true;

export const restApi = () => {
  // ensure there's a token in storage to parse
  const storage_token = localStorage["okta-token-storage"] ? JSON.parse(localStorage["okta-token-storage"]).accessToken : {}

  return axios.create({
    baseURL: "/api",
    headers: {
      accesstoken: storage_token ? storage_token.accessToken : "no token", // get the token itself
    },
  });
}
