import { DatePicker, Form, Input, Row, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import "../App.css";
import { Moment } from "moment";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { ButtonItem, RoundedButton, FullInputNumber } from "../components/StyledComponents";
import { error_message } from "../util/messages";
import { useRegister } from "../hooks/registerHooks";
import CenterSpinner from "../components/CenterSpinner";
import { useHistory, useParams } from "react-router-dom";
import { RegisterPatientParams } from "../util/misc";
import { getPatientById } from "../util/patients";

export interface NewPatientForm {
  name: string;
  family_name_mother: string;
  family_name_father: string;
  dob: Moment | string;
  sex: "male" | "female" | "indeterminate";
  id?: string;
  curp?: string;
  phone_ext?: string;
  phone_num?: string;
  email?: string;
  referenceHospital?: string;
}

const RegisterPatientPage: React.FC<{
  setLoadPatientsOnLoad: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setLoadPatientsOnLoad }) => {
  const { patientId } = useParams<RegisterPatientParams>();
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [values, setValues] = useState<NewPatientForm>();
  const [initialValues, setInitialValues] = useState<any>();
  const [form] = Form.useForm();
  const { isLoading, returnedId } = useRegister("patient", values, patientId || "new");
  const history = useHistory();

  const onFinish = async (values: NewPatientForm) => {
    if (
      (values.phone_ext && !values.phone_num) ||
      (values.phone_num && !values.phone_ext)
    ) {
      error_message("Ingrese un número de teléfono completo");
      return;
    }
    values.dob = (values.dob as Moment).format("YYYY-MM-DD");
    setValues(values);
  };

  useEffect(() => {
    if (values && returnedId) {
      //nav to patientInfo after saving
      history.push({
        pathname: `/patientInfo/${patientId ? patientId : returnedId}`, state: {refresh: true}
      })
    }
  },[values, returnedId])

  useEffect(() => {
    if (!patientId) return
    // get existing patient info
    (async () => {
      const data = await getPatientById(patientId);

      if (!data) return;
      const initVals = {
        ...data,
        dob: moment(data.dob).utc()
      }
      setInitialValues(initVals)
      form.resetFields(Object.keys(initVals));
    })();
  },[]);

  return (!isLoading && !values) ? (
    <Row justify="center" align="middle">
      <Form<NewPatientForm>
        form={form}
        initialValues={initialValues}
        className="data-form"
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        style={{ width: "500px" }}
      >
        <Form.Item
          key="name"
          label="Nombre"
          name="name"
          rules={[
            { required: true, message: "Por favor ingrese paciente de nombre" },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          key="family_name_father"
          label="Primer apellido"
          name="family_name_father"
          rules={[
            {
              required: true,
              message: "Por favor ingrese paciente de primer apellido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          key="family_name_mother"
          label="Segundo apellido"
          name="family_name_mother"
          rules={[
            {
              required: true,
              message: "Por favor ingrese paciente de segundo apellido",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          key="sex"
          label="Sexo"
          name="sex"
          rules={[
            { required: true, message: "Por favor ingrese paciente de sexo" },
          ]}
        >
          <Select style={{ width: "100%" }}>
            <Select.Option value="male">Niño</Select.Option>
            <Select.Option value="female">Niña</Select.Option>
            <Select.Option value="indeterminate">Indeterminado</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Checkbox
            checked={isNewPatient}
            onChange={() => setIsNewPatient((old) => !old)}
          >
            ¿El registro del paciente se hizo en papel?
          </Checkbox>
        </Form.Item>

        {isNewPatient ? (
          <Form.Item
            key="id"
            label="id"
            name="id"
            rules={[
              { required: true, message: "Por favor ingrese paciente de ID" },
            ]}
          >
            <Input />
          </Form.Item>
        ) : null}

        <Form.Item
          key="curp"
          name="curp"
          label="CURP"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          key="harmonyid"
          name="harmonyid"
          label="Harmony ID"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          key="himfgid"
          name="himfgid"
          label="HIMFG ID"
          rules={[
            {
              required: true,
              message: "Por favor ingrese paciente de HIMFG ID",
            },
          ]}
        >
          <FullInputNumber />
        </Form.Item>

        <Form.Item
          key="dob"
          name="dob"
          label="Fecha de Nacimiento"
          rules={[
            {
              required: true,
              message: "Por favor ingrese pacente de fecha nacimiento",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item label="Teléfono (opcional)" style={{ marginBottom: 0 }}>
          <Form.Item
            key="phone_ext"
            name="phone_ext"
            style={{ display: "inline-block", width: "calc(40%)" }}
          >
            <Input style={{ width: "80%" }} placeholder="###" />
          </Form.Item>
          <Form.Item
            key="phone_num"
            name="phone_num"
            style={{
              display: "inline-block",
              width: "calc(60%)",
            }}
          >
            <Input placeholder="#######" />
          </Form.Item>
        </Form.Item>

        <Form.Item key="email" label="Email (opcional)" name="email">
          <Input />
        </Form.Item>
        <ButtonItem>
          <RoundedButton type="primary" htmlType="submit">
            Enviar
          </RoundedButton>
        </ButtonItem>
      </Form>
    </Row>
  ) : (
    <CenterSpinner />
  );
};

export default RegisterPatientPage;
