import { restApi } from "./api";
import { error_message } from "./messages";
import { NewPatientForm } from "../pages/RegisterPatientPage";
import { FormResource, Patient } from "./misc";


export const getActivity = async (
  filters: {[key: string]: string}
): Promise<any[]> => {
  try {
    const { data } = await restApi().get<Record<"activity", any[]>>(
      "/activityByDate",
      { params: {...filters}}
    );
    return data.activity;
  } catch (e: any) {
    error_message(e.response.data.reason);
    Promise.reject(e);
  }
  return [];
};

export const activityTypeDesc: {[key: string]: string} = {
  createdPatient: "Nuevo paciente registrado",
  updatedPatient: "Información actualizada del paciente",
  createdForm: "Envió un nuevo formulario",
  updatedForm: "Formulario actualizado",
}