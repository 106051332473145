import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
  formatMultiselect,
} from "../util/misc";

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  notification,
  Row,
  Space,
  Input,
  Select,
} from "antd";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import CancerSelectForm from "../components/oncology-form/CancerMedForm";
import moment from "moment";
import OutcomeFields from "../components/outcome-form/OutcomeFields";
import FormSkeleton from "../components/FormSkeleton";

const OncologyFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [form] = Form.useForm();
  const [keepDropdownsClosed, setKeepDropdownsClosed] = useState(false);
  const formName = "OncologyForm"
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data.form) {
        setIsLoading(false);
        setKeepDropdownsClosed(true);
        return;
      }
      const values = data["form"];
      const prevDoctorValues = data["prev_doctor"];
      setPrevDoctor(prevDoctorValues);
      if (values["cancerMedications"]) {
        values["cancerMedications"].forEach((value: any, idxI: number) => {
          if (value["medication"]) {
            value["medication"].forEach((medication: any, idxJ: number) => {
              let [doseBeginDate, doseEndDate] = [
                medication.doseBeginDate,
                medication.doseEndDate,
              ];
              // let [radiotherapyBeginDate, radiotherapyEndDate] = [
              //   medication.radiotherapyBeginDate,
              //   medication.radiotherapyEndDate,
              // ];
              doseBeginDate = moment(doseBeginDate).utc();
              doseEndDate = moment(doseEndDate).utc();
              // radiotherapyBeginDate = moment(radiotherapyBeginDate).utc();
              // radiotherapyEndDate = moment(radiotherapyEndDate).utc();
              values["cancerMedications"][idxI]["medication"][idxJ].doseBeginDate =
                doseBeginDate;
              values["cancerMedications"][idxI]["medication"][idxJ].doseEndDate =
                doseEndDate;
              // values["cancerMedications"][idxI]["medication"][
              //   idxJ
              // ].radiotherapyBeginDate = radiotherapyBeginDate;
              // values["cancerMedications"][idxI]["medication"][
              //   idxJ
              // ].radiotherapyEndDate = radiotherapyEndDate;
            });
          }
        });
      }
      values.hasTransfusionDate = values.hasTransfusionDate ? moment(values.hasTransfusionDate).utc() : null
      values.transfusionFeatures = formatMultiselect(values.transfusionFeatures)
      values.hasRadiotherapyDate = values.hasRadiotherapyDate ? moment(values.hasRadiotherapyDate).utc() : null
      values.hasRadiotherapyEndDate = values.hasRadiotherapyEndDate ? moment(values.hasRadiotherapyEndDate).utc() : null
      values.hasGranulocyteColonyDate = values.hasGranulocyteColonyDate ? moment(values.hasGranulocyteColonyDate).utc() : null
      setInitialValues(values);
      setIsLoading(false);
      form.resetFields(Object.keys(values));
    })();
  }, []);

  const onFinish = async (values: any) => {
    if (!values) return;
    if (isSubmitting) return
    else setIsSubmitting(true)
    const cancerMeds = values["cancerMedications"];
    if (cancerMeds && cancerMeds.length) {
      cancerMeds.forEach((cancerMed: any, idxI: number) => {
        if (cancerMed["medication"] && cancerMed["medication"].length) {
          cancerMed["medication"].forEach((medication: any, idxJ: number) => {
            const { med, ...rest } = medication;
            values["cancerMedications"][idxI]["medication"][idxJ] = rest;
          });
        }
      });
    }
    // console.log('values',values)
    await submitForm({ values: values, patientId: patientId, formId: formId  }, formName);
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="data-form"
        onFinish={onFinish}
        scrollToFirstError={true}
        style={{ margin: "0 auto" }}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Formulario de Oncológica"
        />
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <Form.List name="cancerMedications">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Row
                      key={field.key}
                      style={{
                        display: "flex",
                        marginBottom: 20,
                      }}
                      justify="center"
                    >
                      <Col span={22}>
                        <Card>
                          <CancerSelectForm
                            field={field}
                            form={form}
                            keepDropdownsClosed={keepDropdownsClosed}
                          />
                          <Row justify="end">
                            <Button
                              danger
                              onClick={() => {
                                notification.success({
                                  message: "Cáncer eliminado con éxito",
                                  duration: 5,
                                });
                                remove(field.name);
                              }}
                              type="dashed"
                            >
                              Quitar cáncer
                            </Button>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  ))}
                  <Row justify="center">
                    <Button type="dashed" onClick={() => add()}>
                      Agregar cáncer
                    </Button>
                  </Row>
                </>
              );
            }}
          </Form.List>
          <Divider orientation="center"></Divider>
          <OutcomeFields outcomeField={{label: "Transfusionales", keyName: "hasTransfusion", dateName: "hasTransfusionDate"}} form={form}/>
          <Col 
            span={24}
          >
            <Form.Item
              key="transfusionFeatures"
              name="transfusionFeatures"
              label="Características de la transfusión"
            >
              <Select 
                options={[
                  { label: "Concentrados eritrocitarios", value: "erythrocyteConcentrates" },
                  { label: "Plasma fresco", value: "freshPlasma" },
                  { label: "Unitarios plaquetarios", value: "plateletUnit" },
                  { label: "Aferesis plaquetaria", value: "plateletApheresis" },
                  { label: "Crioprecipitados", value: "cryoprecipitates" },
                ]}
                mode="multiple"
              />
            </Form.Item>
          </Col>
          <OutcomeFields outcomeField={{label: "Radioterapia", keyName: "hasRadiotherapy", dateName: "hasRadiotherapyDate", endDateName: "hasRadiotherapyEndDate" }} form={form}/>
          <OutcomeFields outcomeField={{label: "Factor estimulante de colonias de granulocitos", keyName: "hasGranulocyteColony", dateName: "hasGranulocyteColonyDate" }} form={form} />
          <Form.Item 
            key="changeInTumorStatus"
            name="changeInTumorStatus"
            label="Cambio en el estatus del tumor"
          >
            <Input />
          </ Form.Item>
        </Space>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  );
};

export default OncologyFormPage;
