import { Moment } from "moment";
import { OptionType } from "../components/OptionsField";
import { OutcomeField } from "./InfFormUtil";

export type MeanOfAdmin =
  | "intravenous"
  | "intramuscular"
  | "intratecal"
  | "subcutaneous"
  | "oral";
export const meansOfAdminSpanish: Record<MeanOfAdmin, string> = {
  intravenous: "Intravenosa",
  intramuscular: "Intramuscular",
  intratecal: "Intratecal",
  subcutaneous: "Subcutanea",
  oral: "Oral",
};

export type DosageFreqUnit = "hours" | "days" | "weeks" | "onlyOnce";
export const dosageFreqUnitSpanish: Record<DosageFreqUnit, string> = {
  hours: "Horas",
  days: "Dias",
  weeks: "Semanas",
  onlyOnce: "Unica",
};

export const Dosages = ["MG/M2", "MCG/KG/DU", "UI/M2/SC", "G/M2", "kg/día", "mg/kg/día"] as const;

export interface CancerMedication {
  name: string;
  value: string;
  presentation: string;
  meanOfAdmin?: MeanOfAdmin;
  dosageFreqUnit?: DosageFreqUnit;
  dosageUnit?: typeof Dosages[number];
  doseBeginDate?: Moment;
  doseEndDate?: Moment;
  radiotherapyBeginDate?: Moment;
  lastRadiotherapyDate?: Moment;
  immunotherapy?: string;
  notes?: string;
}

// wilms tumor
const Vincristine: CancerMedication = {
  name: "Vincristina",
  value: "vincristine",
  presentation:
    "SOLUCIÓN INYECTABLE Cada frasco ámpula con liofilizado contiene: Sulfato de Vincristina 1 mg. Envase con frasco ámpula y una ampolleta con 10 ml de diluyente",
};
const ActinomycinD: CancerMedication = {
  name: "Actinomicina D",
  value: "actinomycin d",
  presentation: "Caja con un frasco ámpula con liofilizado de 0.5 mg.",
};
const WilmsTumorMedications: CancerMedication[] = [Vincristine, ActinomycinD];

// hepatic tumors
const Cisplatine: CancerMedication = {
  name: "Cisplatino",
  value: "cisplatine",
  presentation:
    "SOLUCIÓN INYECTABLE El frasco ámpula con liofilizado o solución contiene: Cisplatino 10 mg. Envase con un frasco ámpula.",
};
const FiveFluoracilo: CancerMedication = {
  name: "5-Fluoracilo",
  value: "5-fluoracilo",
  presentation:
    "1 Caja,5 Frasco(s) ámpula,250/5 mg/ml, 1 Caja,10 Frasco(s) ámpula,250/5 mg/ml",
};
const AlfaInterferon: CancerMedication = {
  name: "Alfa interferon",
  value: "alfa interferon",
  presentation: "",
};
const Etoposide: CancerMedication = {
  name: "Etoposido",
  value: "etoposide",
  presentation:
    "Cada ampolleta o frasco ámpula contiene: Etopósido 100 mg. Envase con 10 ampolletas o frascos ámpula de 5 ml.",
};
const Adriamycin: CancerMedication = {
  name: "Adriamicina",
  value: "adriamycin",
  presentation: "Caja con un frasco ámpula con 10 mg ó 50 mg.",
};
const HepaticTumorMedications: CancerMedication[] = [
  Cisplatine,
  FiveFluoracilo,
  Vincristine,
  AlfaInterferon,
  Etoposide,
  Adriamycin,
];

// germ cell tumors
const Cyclophosphamide: CancerMedication = {
  name: "Ciclofosfamida",
  value: "cyclophosphamide",
  presentation:
    "SOLUCIÓN INYECTABLE Cada frasco ámpula con liofilizado contiene: Ciclofosfamida monohidratada equivalente a 200 mg de ciclofosfamida. Envase con 5 frascos ámpula; SOLUCIÓN INYECTABLE. Cada frasco ámpula con liofilizado contiene: Ciclofosfamida monohidratada equivalente a 500 mg de ciclofosfamida. Envase con 2 frascos ámpula.",
};
const Isofamide: CancerMedication = {
  name: "Ifosfamida",
  value: "isofamide",
  presentation:
    "SOLUCIÓN INYECTABLE Cada frasco ámpula con polvo o liofilizado contiene: Ifosfamida 1 g. Envase con un frasco ámpula.",
};
const Carboplatine: CancerMedication = {
  name: "Carboplatino",
  value: "carboplatine",
  presentation:
    "SOLUCIÓN INYECTABLE. Cada frasco ámpula con liofilizado contiene: Carboplatino 150 mg. Envase con un frasco ámpula",
};
const Bleomycin: CancerMedication = {
  name: "Bleomicina",
  value: "bleomycin",
  presentation:
    "Cada ampolleta o frasco ámpula con liofilizado contiene: Sulfato de bleomicina equivalente a 15 UI de bleomicina. Envase con una ampolleta o un frasco ámpula y diluyente de 5 ml",
};
const GermCellCancerMedications: CancerMedication[] = [
  Cyclophosphamide,
  Cisplatine,
  Adriamycin,
  Isofamide,
  Carboplatine,
  Etoposide,
  Bleomycin,
];

// ewing's sarcoma and primitive neuroectodermal tumor
const EwingSarcomaMedications: CancerMedication[] = [
  Vincristine,
  ActinomycinD,
  Cyclophosphamide,
  Adriamycin,
];
const PrimitiveNeuroectodermalTumorMedications: CancerMedication[] =
  EwingSarcomaMedications;

// rabdomiosarcoma
const RabdomiosarcomaMedications: CancerMedication[] = [
  Vincristine,
  ActinomycinD,
  Cyclophosphamide,
];

// retinoblastoma
const RetinoblastomaMedications: CancerMedication[] = [
  Carboplatine,
  Etoposide,
  Cyclophosphamide,
  Vincristine,
];

// osteosarcoma
const Doxorubicin: CancerMedication = {
  name: "Doxorrubicina",
  value: "doxorubicin",
  presentation:
    "SUSPENSIÓN INYECTABLE Cada frasco ámpula contiene: Clorhidrato de doxorubicina liposomal pegilada equivalente a 20 mg de doxorubicina (2 mg/ml). Envase con un frasco ámpula con 10 ml (2 mg/ml)",
};
const Metotrexate: CancerMedication = {
  name: "Metotrexate",
  value: "metotrexate",
  presentation:
    "SOLUCIÓN INYECTABLE Cada frasco ámpula con liofilizado contiene: Metotrexato sódico equivalente a 50 mg de metotrexato. Envase con un frasco ámpula",
};
const OsteosarcomaMedications: CancerMedication[] = [
  Cisplatine,
  Doxorubicin,
  Isofamide,
  Etoposide,
  Metotrexate,
  Adriamycin,
];

// neuroblastoma
const NeuroblastomaMedications: CancerMedication[] = [
  Cyclophosphamide,
  Adriamycin,
];

// medulumoblastoma and suppratentorial primitive neuroectodermal tumors
const MedulumoblastomaMedications: CancerMedication[] = [
  Carboplatine,
  Etoposide,
  Cyclophosphamide,
];
const SuppratentorialPrimNeuroectodermalTumorsMedications =
  MedulumoblastomaMedications;

// non-hodgkin lymphoma
const Prednisone: CancerMedication = {
  name: "Prednisona",
  value: "prednisone",
  presentation: "1 Caja, 20 Tabletas, 5 Miligramos",
};
const NonHodgkinLymphomaMedications: CancerMedication[] = [
  Cyclophosphamide,
  Vincristine,
  Metotrexate,
  Prednisone,
  Doxorubicin,
  Etoposide,
];

// anaplasic ependimoma
const AnaplasicEpendimomaMedications: CancerMedication[] = [
  Etoposide,
  Isofamide,
  Carboplatine,
];

// low and high grade astrocytomas
const LowGradeAstrocytoma: CancerMedication[] = [
  Isofamide,
  Etoposide,
  Carboplatine,
  Vincristine,
];
const HighGradeAstrocytoma = LowGradeAstrocytoma;

// langerhans cell hystiocytosis
const Mercaptopurine: CancerMedication = {
  name: "Mercaptopurina",
  value: "mercaptopurine",
  presentation:
    "Tableta. Cada tableta contiene: Mercaptopurina 50 mg. Envase con 20 tabletas. Envase con 25 tabletas",
};
const Vinblastin: CancerMedication = {
  name: "Vinblastina",
  value: "vinblastin",
  presentation:
    "Cada frasco ámpula con liofilizado contiene: Sulfato de Vinblastina 10 mg. Envase con un frasco ámpula y ampolleta con 10 ml de diluyente",
};
const LangerhansHystiocytosisMedications: CancerMedication[] = [
  Prednisone,
  Vinblastin,
  Metotrexate,
  Mercaptopurine,
];

// hodgkin's disease
const Dacarbazine: CancerMedication = {
  name: "Dacarbazina",
  value: "dacarbazine",
  presentation: "Frasco ámpula con polvo contiene: Dacarbazina 200 mg.",
};
const HodgkinsDiseaseMedications: CancerMedication[] = [
  Vincristine,
  Adriamycin,
  Metotrexate,
  Prednisone,
  Bleomycin,
  Vinblastin,
  Dacarbazine,
];

// acute lymphoblastic leukemia
const Dexamentason: CancerMedication = {
  name: "Dexamentason",
  value: "dexamentason",
  presentation: "Dexametasona Kern Pharma 4 mg/ml solución inyectable EFG",
};
const Asparaginase: CancerMedication = {
  name: "Asparaginase",
  value: "asparaginase",
  presentation: "1 Caja, 1 Frasco(s), 10000 U.I.",
};
const Daunorubicin: CancerMedication = {
  name: "Daunorubicin",
  value: "daunorubicin",
  presentation: "20 mg\n50 mg",
};
const Cytarabine: CancerMedication = {
  name: "Cytarabine",
  value: "cytarabine",
  presentation:
    "Citarabina Pfizer 100 mg polvo y disolvente para solución inyectable y para perfusión.\nCitarabina Pfizer 500 mg polvo y disolvente para solución inyectable y para perfusión",
};
const AcuteLymphocyticLeukemiaMedications: CancerMedication[] = [
  Dexamentason,
  Prednisone,
  Vincristine,
  Asparaginase,
  Daunorubicin,
  Etoposide,
  Cytarabine,
  Metotrexate,
  Mercaptopurine,
  Cyclophosphamide,
  Carboplatine,
];

// acute myeloid leukemia
const AraC: CancerMedication = {
  name: "AraC",
  value: "araC",
  presentation:
    "Citarabina Kabi 100 mg/ml solución inyectable o para perfusión",
};
const MyeloidLymphocyticLeukemiaMedications: CancerMedication[] = [
  AraC,
  Mercaptopurine,
  Etoposide,
  Doxorubicin,
];

export type CancerTypes =
  | "wilmsTumor"
  | "hepaticTumors"
  | "germCellTumors"
  | "ewingsSarcoma"
  | "primitiveNeuroectodermalTumor"
  | "rabdomiosarcoma"
  | "retinoblastoma"
  | "osteosarcoma"
  | "neuroblastoma"
  | "medulumoblastoma"
  | "suppratentorialPrimitiveNeuroectodermalTumors"
  | "nonHodgkinLymphoma"
  | "anaplasicEpendimoma"
  | "lowGradeAstrocytomas"
  | "highGradeAstrocytomas"
  | "langerhansCellHystiocytosis"
  | "hodgkinsDisease"
  | "acuteLymphocyticLeukemia"
  | "myeloidLymphocyticLeukemia"
  | "";

export const CancerMedications: Record<CancerTypes, CancerMedication[]> = {
  wilmsTumor: WilmsTumorMedications,
  hepaticTumors: HepaticTumorMedications,
  germCellTumors: GermCellCancerMedications,
  ewingsSarcoma: EwingSarcomaMedications,
  primitiveNeuroectodermalTumor: PrimitiveNeuroectodermalTumorMedications,
  rabdomiosarcoma: RabdomiosarcomaMedications,
  retinoblastoma: RetinoblastomaMedications,
  osteosarcoma: OsteosarcomaMedications,
  neuroblastoma: NeuroblastomaMedications,
  medulumoblastoma: MedulumoblastomaMedications,
  suppratentorialPrimitiveNeuroectodermalTumors:
    SuppratentorialPrimNeuroectodermalTumorsMedications,
  nonHodgkinLymphoma: NonHodgkinLymphomaMedications,
  anaplasicEpendimoma: AnaplasicEpendimomaMedications,
  lowGradeAstrocytomas: LowGradeAstrocytoma,
  highGradeAstrocytomas: HighGradeAstrocytoma,
  langerhansCellHystiocytosis: LangerhansHystiocytosisMedications,
  hodgkinsDisease: HodgkinsDiseaseMedications,
  acuteLymphocyticLeukemia: AcuteLymphocyticLeukemiaMedications,
  myeloidLymphocyticLeukemia: MyeloidLymphocyticLeukemiaMedications,
  "": [],
};

export const OncCancerTypeOptions: OptionType<CancerTypes>[] = [
  { label: "Tumor de Wilms", value: "wilmsTumor" },
  { label: "Tumores Hepaticos", value: "hepaticTumors" },
  { label: "Tumor de Celulas Germinales", value: "germCellTumors" },
  { label: "Sarcoma de Ewing", value: "ewingsSarcoma" },
  {
    label: "Tumor Neuroectodermico Primitivo",
    value: "primitiveNeuroectodermalTumor",
  },
  { label: "Rabdomiosarcoma", value: "rabdomiosarcoma" },
  { label: "Retinoblastoma", value: "retinoblastoma" },
  { label: "Osteosarcoma", value: "osteosarcoma" },
  { label: "Neuroblastoma", value: "neuroblastoma" },
  { label: "Meduloblastoma", value: "medulumoblastoma" },
  {
    label: "Tumores Neuroectodermicos Primitivos Supratentoriales",
    value: "suppratentorialPrimitiveNeuroectodermalTumors",
  },
  { label: "Linfoma no Hodgkin", value: "nonHodgkinLymphoma" },
  { label: "Ependimoma Anaplasico", value: "anaplasicEpendimoma" },
  { label: "Astrocitomas de Bajo Grado", value: "lowGradeAstrocytomas" },
  { label: "Astrocitomas de Alto Grado", value: "highGradeAstrocytomas" },
  {
    label: "Histiocitosis de Celulas de Langerhans",
    value: "langerhansCellHystiocytosis",
  },
  { label: "Enfermedad de Hodgkin", value: "hodgkinsDisease" },
  { label: "Leucemia Linfocítica Aguda", value: "acuteLymphocyticLeukemia" },
  {
    label: "Leucemia Linfocítica Mieloide",
    value: "myeloidLymphocyticLeukemia",
  },
];

export const MedicationNameOptions = (
  cancerName: CancerTypes
): OptionType<string>[] => {
  const options = CancerMedications[cancerName]
    .map((medication) => ({
      label: medication.name,
      value: medication.value,
    }));
  options.push({
    label: "Otro",
    value: "other",
  });
  return options
};

export const getMedByName = (
  name: string | undefined
): CancerMedication | undefined => {
  if (!name || name === "other") return undefined;
  const medSet: Set<CancerMedication> = new Set();
  Object.values(CancerMedications).forEach((cancerMeds) => {
    cancerMeds.forEach((cancerMed) => medSet.add(cancerMed));
  });

  const meds = Array.from(medSet);
  return meds.find((med) => med.value === name);
};

export const OncologyOutcomeFields: OutcomeField[] = [
  { label: "Sangrado", keyName: "hasBleeding", dateName: "hasBleedingDate" },
  {
    label: "Enterocolitis neutropénica",
    keyName: "hasNeutropenicEntercolitis",
    dateName: "hasNeutropenicEntercolitisDate",
  },
];

export const chemoSessionOptions: OptionType<any>[] = [
  { label: "Programada por calendario", value: "scheduled" },
  { label: "Rezago por enfermedad", value: "delayDueToIllness" },
  { label: "Rezago por otra causa", value: "delayOther" },
];