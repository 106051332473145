import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  TimePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { OptionsField } from "../components/OptionsField";
import { FullInputNumber } from "../components/StyledComponents";
import {
  HemoOptions,
  NeurologicalOptions,
  PainOptions,
  RespOptions,
  TemperatureOptions,
} from "../util/EmergencyFormUtil";
import HeightWeight from "../components/HeightWeight";

const EmergencyFormPart1: React.FC<{
  setFamilySigned: React.Dispatch<React.SetStateAction<boolean>>;
  patientId: string;
  form: FormInstance;
}> = ({ setFamilySigned, form, patientId }) => (
  <>
    {" "}
    <div id="page2">
      <Row justify="start" gutter={16}>
        <Col span={12}>
          <Form.Item
            key="numFNEvents"
            name="numFNEvents"
            label="Número de eventos FN"
            rules={[{ required: true, message: "Requerido" }]}
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            key="date"
            name="date"
            label="Fecha"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={24}>
          <HeightWeight
            patientId={patientId}
          />
        </Col>
      </Row>
      <Divider orientation="center">Tiempos de Espera</Divider>
      <Row justify="center" gutter={16}>
        <Form.Item
          key="timeArrived"
          name="timeArrived"
          label="Hora de llegada"
          rules={[{ required: true, message: "Requerido" }]}
        >
          <TimePicker style={{ width: "100%" }} use12Hours format="h:mm A" />
        </Form.Item>
      </Row>
      <Row justify="center" gutter={16}>
        <Col span={12}>
          <Form.Item
            key="triageBegin"
            name="triageBegin"
            label="Inicio de triage"
            rules={[{ required: true, message: "Requerido" }]}
          >
            <TimePicker style={{ width: "100%" }} use12Hours format="h:mm A" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            key="triageEnd"
            name="triageEnd"
            label="Termino de triage"
            rules={[{ required: true, message: "Requerido" }]}
          >
            <TimePicker style={{ width: "100%" }} use12Hours format="h:mm A" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" align="bottom" gutter={16}>
        <Col span={12}>
          <Form.Item
            key="consultingTimeout"
            name="consultingTimeout"
            label="Tiempo de espera para consulta (mins)"
            rules={[{ required: true, message: "Requerido" }]}
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            key="isRealEmergency"
            name="isRealEmergency"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Urgencia Real</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            key="isFeelingEmergency"
            name="isFeelingEmergency"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Urgencia Sentida</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="center">Valoracion Inicial</Divider>
      <p>Signos Vitales</p>
      <Row justify="center" align="middle" gutter={8}>
        <Col span={8}>
          <Form.Item key="fc" name="fc" label="FC" rules={[{ required: false }]}>
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item key="fr" name="fr" label="FR" rules={[{ required: false }]}>
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="TA" style={{ marginBottom: 0 }}>
            <Form.Item
              key="taP1"
              name="taP1"
              rules={[{ required: false }]}
              style={{ display: "inline-block", width: "calc(30%)" }}
            >
              <FullInputNumber />
            </Form.Item>{" "}
            /{" "}
            <Form.Item
              key="taP2"
              name="taP2"
              rules={[{ required: false }]}
              style={{
                display: "inline-block",
                width: "calc(30%)",
              }}
            >
              <FullInputNumber />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col span={4}>
          <Form.Item
            key="satO2"
            name="satO2"
            label="Sat O2"
            rules={[{ required: false, min: 0, type: "number"}]}
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Respiratorio</Divider>
      <Row justify="center">
        <Col span={24}>
          <OptionsField formOptions={RespOptions} presentation="radio" required={false} />
        </Col>
      </Row>
      <Divider orientation="left">Hemodinámico</Divider>
      <Row justify="center">
        <Col span={24}>
          <OptionsField formOptions={HemoOptions} presentation="radio" required={false}/>
        </Col>
      </Row>
      <Divider orientation="left">Temperatura</Divider>
      <Row justify="start">
        <Col span={4}>
          <Form.Item
            key="temp"
            name="temp"
            label="Temp (ºC)"
            rules={[{ required: false }]}
          >
            <FullInputNumber />
          </Form.Item>
        </Col>
        <Col span={24}>
          <OptionsField formOptions={TemperatureOptions} presentation="radio" required={false}/>
        </Col>
      </Row>
      <Divider orientation="left">Neurologico</Divider>
      <Row justify="center">
        <Col span={24}>
          <OptionsField formOptions={NeurologicalOptions} presentation="radio" required={false}/>
        </Col>
      </Row>
      <Divider orientation="left">Dolor</Divider>
      <Row justify="center">
        <Col span={24}>
          <OptionsField formOptions={PainOptions} presentation="radio" required={false}/>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: "5%" }}></Row>
      <Form.Item
        key="attentionClass"
        name="attentionClass"
        label="Nivel de Atención"
        rules={[{ required: false }]}
      >
        <Radio.Group>
          <Radio value="immediateAttention">
            1 ATENCION INMEDIATA EN URGENCIAS (acudir al área de choque ante cualquier situación de RIESGO VITAL)
          </Radio>
          <Radio value="consultIn15to30min">
            2 CONSULTA EN LOS SIGUIENTES, 15 a 30 MINUTOS
          </Radio>
          <Radio value="consultIn20to30min">
            3 CONSULTA EN LOS SIGUIENTES 20 A 30 MINUTOS O BIEN PUEDE SER ENVIADO
            EN 2 NIVEL DE ATENCION (siempre y cuando no cuente con factores de
            riesgo)
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item key="destination" name="destination" label="Destino">
        <Radio.Group defaultValue="shockArea" buttonStyle="solid">
          <Radio.Button value="shockArea">ÁREA DE CHOQUE</Radio.Button>
          <Radio.Button value="observationInER">
            OBSERVACIÓN URGENCIAS
          </Radio.Button>
          <Radio.Button value="consultInER">CONSULTA URGENCIAS</Radio.Button>
          <Radio.Button value="filter">FILTRO</Radio.Button>
          <Radio.Button value="secondLvlAttn">2 NIVEL</Radio.Button>
          <Radio.Button value="firstLvlAttn">1 NIVEL</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        key="observations"
        name="observations"
        label="Observaciones"
        rules={[{ required: false }]}
      >
        <TextArea style={{ height: "20vh" }} />
      </Form.Item>
      <Form.Item
        key="relativeName"
        name="relativeName"
        label="Familiar (Nombre)"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="relativeSigned"
        name="relativeSigned"
        valuePropName="checked"
        initialValue={false}
      >
        <span id="familySign">
          <Checkbox onChange={(value) => setFamilySigned(value.target.checked)}>
            <span style={{ color: "red" }}>* </span>
            Familiar (firma) 
          </Checkbox>
        </span>
      </Form.Item>
    </div>
  </>
);

export default EmergencyFormPart1;
