import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FormByIdParams,
  getFormById,
  PrevDoctor,
  submitForm,
} from "../util/misc";

import { Col, Form, Row, Select, Slider, DatePicker, TimePicker, Checkbox } from "antd";
import CenterSpinner from "../components/CenterSpinner";
import GeneralInfo from "../components/GeneralInfo";
import HeightWeight from "../components/HeightWeight";
import FormSkeleton from "../components/FormSkeleton";
import {
  HospitalizationAreaOptions,
  getMarks,
  prepInitialValues
} from "../util/SignsSymptomsFormUtil";
import { FullInputNumber } from "../components/StyledComponents";
import { useMainStore } from "../state/mainStore";
import { Moment } from "moment";

const SignsSymptomsFormPage: React.FC = () => {
  const { formId, patientId } = useParams<FormByIdParams>();
  const [prevDoctor, setPrevDoctor] = useState<PrevDoctor>({
    name: "",
    role: "none",
  });
  const [initialValues, setInitialValues] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasFecal, setHasFecal] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const marks = getMarks();
  const formName = "SignsSymptomsForm"

  const { role } = useMainStore();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getFormById(formId, formName);
      if (!data || !data.form) {
        setIsLoading(false);
        return;
      }
      const values = data.form;
      const prevDoctorValues = data.prev_doctor;
      setPrevDoctor(prevDoctorValues);
      const newValues = await prepInitialValues(values);
      setInitialValues(newValues);
      setIsLoading(false);
      form.resetFields(Object.keys(newValues));
    })();
  }, []);

  const onFinish = async (values: any) => {
    if (!values) return;
    if (isSubmitting) return
    else setIsSubmitting(true)
    if (values.time_vitals_taken) { // format time field if present
      values.time_vitals_taken = (values.time_vitals_taken as Moment).format("HH:mm")
    }
    await submitForm(
      { values: values, patientId: patientId, formId: formId },
      formName
    );
    setIsLoading(false);
    setIsSubmitting(false)
    history.push({ pathname: `/${formName}/${patientId}` })
  };

  return !isLoading ? (
    <FormSkeleton onSubmit={() => form.submit()}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="data-form"
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <GeneralInfo
          patientId={patientId}
          prevDoctor={prevDoctor}
          formTitle="Signos y síntomas"
        />
        <Row justify="center" gutter={2}>
          <Col span={22}>
            <Row>
              <Col span={12}>
                <Form.Item
                  key="date_vitals_taken"
                  name="date_vitals_taken"
                  label="Fecha vitales tomadas"
                >
                  <DatePicker/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  key="time_vitals_taken"
                  name="time_vitals_taken"
                  label="Hora de Registro"
                >
                  <TimePicker 
                    use12Hours
                    format="h:mm A"
                  />
                </Form.Item>
              </Col>
            </Row>
            <HeightWeight
              patientId={patientId}
            />
            <Form.Item
              key="hospitalization_area"
              name="hospitalization_area"
              label="Área de hospitalización"
              rules={[{ required: true }]}
            >
              <Select options={HospitalizationAreaOptions} />
            </Form.Item>
            <Form.Item
              key="heart_rate"
              name="heart_rate"
              label="Ritmo cardiaco (latidos/min)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Form.Item
              key="respiratory_rate"
              name="respiratory_rate"
              label="Recuencia respiratoria (respiraciones/min)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Form.Item
              key="systolic"
              name="systolic"
              label="Presión sanguínea sistólica (mmHG)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Form.Item
              key="diastolic"
              name="diastolic"
              label="Presión sanguínea diastólica (mmHG)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Form.Item
              key="temperature"
              name="temperature"
              label="Temperatura (ºC)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Form.Item
              key="urinary_volume"
              name="urinary_volume"
              label="Volumen urinario (mL)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item 
                  key="has_fecal_exp"
                  name="has_fecal_exp"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    onChange={(e) => {
                      setHasFecal(e.target.checked);
                    }}
                  >
                    Gasto fecal
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  key="fecal_expenditure"
                  name="fecal_expenditure"
                  label="Gasto fecal (g)"
                  rules={[{ required: hasFecal }]}
                >
                  <FullInputNumber disabled={!hasFecal}/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              key="o2_saturation"
              name="o2_saturation"
              label="Saturación de oxígeno (%)"
              rules={[{ required: true }]}
            >
              <FullInputNumber />
            </Form.Item>
            <Form.Item
              key="pain_scale"
              name="pain_scale"
              label="Escala de dolor"
            >
              <Slider defaultValue={5} min={0} max={10} marks={marks} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormSkeleton>
  ) : (
    <CenterSpinner />
  );
};

export default SignsSymptomsFormPage;
