import create from "zustand";

type ActivityStore = {
  activities: any[];
  resetActivity: () => void;
  setActivity: (patients: any[]) => void;
};

export const useActivityStore = create<ActivityStore>((set) => ({
  activities: [],
  resetActivity: () => set((state) => ({ ...state, activities: [] })),
  setActivity: (activities: any[]) =>
    set((state) => ({ ...state, activities: [...activities] })),
}));
