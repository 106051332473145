import { Form, Input, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useRegister } from "../hooks/registerHooks";
import "../App.css";
import { ButtonItem, RoundedButton } from "../components/StyledComponents";
import CenterSpinner from "../components/CenterSpinner";
import { Role } from "../util/misc";
import { useHistory } from "react-router-dom";

export interface NewStaffForm {
  name: string;
  role: Role;
  email: string;
}

const RegisterStaff: React.FC = () => {
  const [values, setValues] = useState<NewStaffForm>();
  const isLoading = useRegister("staff", values, undefined);
  const history = useHistory();
  const onFinish = async (values: NewStaffForm) => {
    setValues(values);
    console.log(values);
  };
  
  useEffect(() => {
    if (values) {
      setTimeout(() => history.push("/listStaff"), 2000)
    }
  },[values])

  return (!isLoading && !values) ? (
    <Row justify="center" align="middle">
      <Form<NewStaffForm>
        className="data-form"
        layout="vertical"
        onFinish={onFinish}
        style={{ width: "500px" }}
      >
        <Form.Item
          key="name"
          label="Nombre"
          name="name"
          rules={[
            { required: true, message: "Por favor ingrese paciente de nombre" },
          ]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          key="role"
          label="Área"
          name="role"
          initialValue="social-worker"
        >
          <Select style={{ width: "100%" }}>
            <Select.Option value="social-worker">Trabajo social</Select.Option>
            <Select.Option value="infectiology">Infectólogo</Select.Option>
            <Select.Option value="oncology">Oncólogo</Select.Option>
            <Select.Option value="emergency">Emergencia</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          key="email"
          label="Email"
          name="email"
          rules={[{ required: true, message: "Ingrese el correo email" }]}
        >
          <Input />
        </Form.Item>

        <ButtonItem>
          <RoundedButton type="primary" htmlType="submit">
            Enviar
          </RoundedButton>
        </ButtonItem>
      </Form>
    </Row>
  ) : (
    <CenterSpinner />
  );
};

export default RegisterStaff;
