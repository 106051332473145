import create from "zustand";
import { EnglishRole } from "../util/misc";

type MainStoreData = {
  username: string;
  isLoggedIn: boolean;
  role: string[];
};

type MainStoreActions = {
  resetStore: () => void;
  setUsername: (username: string) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setRole: (role: string[]) => void;
};

type MainStore = MainStoreData & MainStoreActions;

export const useMainStore = create<MainStore>((set) => ({
  username: "",
  isLoggedIn: false,
  role: ["none"],
  resetStore: () =>
    set((state) => {
      return {
        ...state,
        username: "",
        role: ["none"],
        isLoggedIn: false,
      };
    }),
  setUsername: (username: string) =>
    set((state) => {
      return {
        ...state,
        username: username,
      };
    }),
  setIsLoggedIn: (isLoggedIn: boolean) =>
    set((state) => {
      return {
        ...state,
        isLoggedIn: isLoggedIn,
      };
    }),
  setRole: (role: string[]) =>
    set((state) => {
      return {
        ...state,
        role: role,
      };
    }),
}));
