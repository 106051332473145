import { OptionType } from "../components/OptionsField";

export type formBuilderField = {
  keyName: string;
  label: string;
  type: "boolean" | "string" | "number" | "scale" | "divider" | "select" | "date";
  scale?: number;
  options?: OptionType<string>[];
  selectMode?: "multiple" | "tags" | undefined;
  span?: number;
  required?: boolean,
  furtherOptions?: {
    optionsKey: string;
    type: "select" | "number";
    selectMode?: "multiple" | "tags" | undefined;
    options?: OptionType<string>[];
  };
};

export const getMarks = (scale:number): { [key in number]: any } => {
  const marks: { [key in number]: any } = {};
  for (let i = 0; i < scale; i++) {
    marks[i] = {
      style: {
        color: "#1890ff",
      },
      label: i+1,
    };
  };
  return marks;
};
