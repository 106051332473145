import create from "zustand";
import { Staff } from "../util/misc";

type StaffStore = {
  staff: Staff[];
  resetStaff: () => void;
  setStaff: (staff: Staff[]) => void;
};

export const useStaffStore = create<StaffStore>((set) => ({
  staff: [],
  resetStaff: () => set((state) => ({ ...state, patients: [] })),
  setStaff: (staff: Staff[]) =>
    set((state) => ({ ...state, staff: [...staff] })),
}));
